<template>
    <v-dialog v-model="bDialogAddOrEditDiscounts" :width="`${screenWidth}%`" persistent>
        <div class="content-card-dialog">
            <v-btn class="content-card-button-close" @click="closeDialog" icon>
                <v-icon color="#000" size="16px"> mdi-close </v-icon>
            </v-btn>
            <p class="content-card-txt-title poppins">{{ bAddOrEdit ? 'Añadir' : 'Editar' }} descuento</p>

            <div class="content-inputs">
                <v-text-field v-model="sDescription" type="text" label="Descripción" placeholder="Descripción" dense
                    color="#2759A2" class="global-inputs poppins " maxlength="85" persistent-placeholder
                    @keypress="soloLettersAndNumbers"></v-text-field>
                <!-- <v-text-field v-model="iImported" type="text" label="Importe" placeholder="Importe" dense
                    color="#2759A2" class="global-inputs poppins mb-9" maxlength="85" persistent-placeholder
                    @keypress="soloLetters"></v-text-field> -->
                <input-currency-global :flagModal="bDialogAddOrEditDiscounts" labelGlobal="Importe"
                    classDynamic="global-inputs poppins" :bOutlined="false" :bImportantData="false"
                    @changeMontoGlobal="changeImported" :modelValue="iImported" sPrefix="$" />
            </div>
            <div class="content-buttons">
                <v-container class="pa-0" fluid>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <div class=" display-flex align-items-center justify-content-flex-start ">
                                <v-btn @click="closeDialog" class="button-secondary poppins" elevation="0" width="100%">
                                    Regresar
                                </v-btn>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="5">
                            <div class="display-flex align-items-center justify-content-flex-end">
                                <v-btn @click="add" :loading="bLoading" :disabled="!validationForm"
                                    class="button-primary poppins" elevation="0" width="100%">
                                    Añadir
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </v-dialog>
</template>

<script>

export default {
    name: "LayoutDiscountsAdd",
    props: {
        bDialogAddOrEditDiscounts: Boolean,
        bAddOrEdit: Boolean,
        oItem: Object
    },
    data() {
        return {
            screenWidth: 0,
            sDescription: "",
            iImported: 0,
            bLoading: false,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    beforeMount() {
    },
    methods: {

        add: function () {
            this.bLoading = true;
            then((resp) => {
                if (this.getPermissionsActionsGlobal()) {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.sToken}`,
                        },
                    },
                        payload = {
                            sEmail: this.correo,
                            sPhoneNumber: this.numeroTelefono,
                            sJobTitle: this.puesto,
                            sName: this.nombre,
                            sLastname: this.apellido,
                            sAreaCallingCode: this.codigoArea,
                            sCountryCallingCode: this.codigoPais,
                            sPhoneExtension: this.extension,
                        };

                    DB.post(`${URI}/auth/administrator`, payload, config)
                        .then((response) => {
                            this.bLoading = false;
                            this.closeDialog();
                            this.mixSuccess(response.data.message);
                            this.$store.commit("refresher", true);
                        })
                        .catch((error) => {
                            this.bLoading = false;
                            this.mixError(error.response.data.message, error.response.status);
                        });
                } else {
                    this.bLoading = false;
                    this.closeDialog();
                    this.mixError(this.$store.state.sMessageErrorAccess, 0);
                    this.$store.commit("refresher", true);

                }
            }).catch((error) => {
                this.bLoading = false;
                this.closeDialog();
                this.mixError(this.$store.state.sMessageErrorAccess, 0);
                this.$store.commit("refresher", true);
            })
        },
        soloLettersAndNumbers: function (evt) {
            var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
            var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        },
        soloNumbers: function (evt) {
            if (
                (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                evt.which > 57
            ) {
                evt.preventDefault();
            }
        },
        handleResize: function () {
            if (window.innerWidth > 960) {
                this.screenWidth = 50;
            } else {
                this.screenWidth = 100;
            }
        },
        closeDialog: function () {
            this.$emit("setDialogAddOrEditDiscounts");
            this.sDescription = "";
            this.iImported = 0;
            this.bLoading = false;
        },
        changeImported(value) {
            if (value !== "") {
                this.iImported = parseFloat(value);
            } else {
                this.iImported = 0;
            }
        },
    },
    computed: {
        validationForm: function () {
            return (
                this.sDescription !== "" &&
                this.iImported > 0
            );
        },
    },
    watch: {
        bDialogAddOrEditDiscounts() {
            if (this.bDialogAddOrEditDiscounts) {
                if (!this.bAddOrEdit) {
                    this.sDescription = this.oItem.sDescription;
                    this.iImported = this.oItem.iImported;
                }
            }
        }
    }
};
</script>

<style scoped>
.content-inputs {
    margin-top: 50px;
}
</style>
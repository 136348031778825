var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-search mt-9 mb-9"},[_c('div',{staticClass:"content-input-search"},[_c('v-text-field',{staticClass:"global-auth-inputs poppins mb-4",attrs:{"type":"text","label":"Buscar por","placeholder":"Buscar por nombre, mensaje","dense":"","color":"#2759A2","maxlength":"85","append-icon":"mdi-magnify","persistent-placeholder":""},on:{"input":_vm.setSearch},model:{value:(_vm.sSearch),callback:function ($$v) {_vm.sSearch=$$v},expression:"sSearch"}}),(false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bMobile),expression:"bMobile"}],staticClass:"\n        display-flex\n        align-items-center\n        justify-content-flex-end\n        position-relative\n      "},[_c('v-menu',{attrs:{"offset-y":"","content-class":"circle-profile-content","close-on-content-click":false,"min-width":"200px","attach":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-secondary ml-9",staticStyle:{"width":"50px","margin-top":"-5px"},attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune-vertical")])],1)]}}],null,false,4089964117)},[_c('div',{staticClass:"circle-content-options"},[_c('div',[_c('div',{staticClass:"content-down-menu"},[_c('div',[_c('p',{staticClass:"txt-title-down-menu poppins mb-0"},[_vm._v("Tipo de usuario ")]),_c('div',_vm._l((_vm.itemsUserType),function(item,index){return _c('v-checkbox',{key:index,staticClass:"mb-1",attrs:{"value":item.value,"label":"","hide-details":"","color":"#2759a2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"txt-description-down-menu poppins mb-0"},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.sUserType),callback:function ($$v) {_vm.sUserType=$$v},expression:"sUserType"}})}),1)]),_c('div',[_c('p',{staticClass:"txt-title-down-menu poppins mb-1"},[_vm._v("Acción registrada")]),_c('div',_vm._l((_vm.itemsRegistredAction),function(item,index){return _c('v-checkbox',{key:index,staticClass:"mb-1",attrs:{"value":item.value,"label":"","hide-details":"","color":"#2759a2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"txt-description-down-menu poppins mb-0"},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.sRegistredAction),callback:function ($$v) {_vm.sRegistredAction=$$v},expression:"sRegistredAction"}})}),1)])])])])])],1):_vm._e()],1),_c('div',{staticClass:"position-relative",on:{"click":function($event){_vm.menu = true}}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.rangoFechas,"transition":"scale-transition","offset-y":"","min-width":"auto","attach":""},on:{"update:returnValue":function($event){_vm.rangoFechas=$event},"update:return-value":function($event){_vm.rangoFechas=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"content-input-date-range"},[_c('v-combobox',_vm._g(_vm._b({staticClass:"global-auth-inputs",attrs:{"label":"Rango de fechas","placeholder":"Seleccionar fechas","persistent-placeholder":"","readonly":"","dense":"","color":"#2759A2","append-icon":"mdi-calendar","chips":"","small-chips":"","multiple":"","clearable":""},model:{value:(_vm.rangoFechas),callback:function ($$v) {_vm.rangoFechas=$$v},expression:"rangoFechas"}},'v-combobox',attrs,false),on))],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":_vm.nowDate,"no-title":"","scrollable":"","multiple":"","range":"","locale":"es"},model:{value:(_vm.rangoFechas),callback:function ($$v) {_vm.rangoFechas=$$v},expression:"rangoFechas"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false;
        _vm.$refs.menu.save((_vm.dates = []));
        _vm.rangoFechas = [];}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.rangoFechas.length < 2},on:{"click":function($event){_vm.$refs.menu.save(_vm.rangoFechas);
        _vm.saveDates();}}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-spacer'),(false)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bMobile),expression:"!bMobile"}],staticClass:"\n      display-flex\n      align-items-center\n      justify-content-flex-end\n      position-relative\n    "},[_c('v-menu',{attrs:{"offset-y":"","content-class":"circle-profile-content","close-on-content-click":false,"min-width":"200px","attach":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-secondary",staticStyle:{"width":"50px","margin-top":"-5px"},attrs:{"icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune-vertical")])],1)]}}],null,false,4259977440)},[_c('div',{staticClass:"circle-content-options"},[_c('div',[_c('div',{staticClass:"content-down-menu"},[_c('p',{staticClass:"txt-title-down-menu poppins mb-0"},[_vm._v("Tipo de usuario")]),_c('div',_vm._l((_vm.itemsUserType),function(item,index){return _c('v-checkbox',{key:index,staticClass:"mb-1",attrs:{"value":item.value,"label":"","hide-details":"","color":"#2759a2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"txt-description-down-menu poppins mb-0"},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.sUserType),callback:function ($$v) {_vm.sUserType=$$v},expression:"sUserType"}})}),1),_c('p',{staticClass:"txt-title-down-menu poppins mb-1"},[_vm._v("Acción registrada")]),_c('div',_vm._l((_vm.itemsRegistredAction),function(item,index){return _c('v-checkbox',{key:index,staticClass:"mb-1",attrs:{"value":item.value,"label":"","hide-details":"","color":"#2759a2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"txt-description-down-menu poppins mb-0"},[_vm._v(" "+_vm._s(item.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.sRegistredAction),callback:function ($$v) {_vm.sRegistredAction=$$v},expression:"sRegistredAction"}})}),1)])])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
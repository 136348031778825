<template>
  <div>
    <layout-sim-cards-header @setDialogAdd="setDialogAdd" />
    <layout-sim-cards-searchbar
      @setSearch="setSearch"
      @setPlanId="setPlanId"
      @setPayload="setPayload"
    />
    <layout-sim-cards-content
      :sSearch="sSearch"
      @setOptions="setOptions"
      :sPlanId="sPlanId"
      :oPayload="oPayload"
      @toTopGlobal="toTopGlobal"
    />
    <layout-sim-cards-add
      :bDialogAdd="bDialogAdd"
      @setDialogAdd="setDialogAdd"
    />
    <!-- general component  -->
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutSimCardsHeader from "@/layouts/SimCards/Header.vue";
import LayoutSimCardsContent from "@/layouts/SimCards/Content.vue";
import LayoutSimCardsSearchbar from "@/layouts/SimCards/Searchbar.vue";
import LayoutSimCardsAdd from "@/layouts/SimCards/Add.vue";

export default {
  name: "SimCardsView",
  data() {
    return {
      sSearch: "",
      bDialogAdd: false,
      oOptions: {},
      sPlanId: undefined,
      oPayload:{}
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setPlanId: function (val) {
      this.sPlanId = val;
    },
    setPayload(oPayload){
      this.oPayload = oPayload
    },
    toTopGlobal(){
      this.$emit("toTopGlobal")
    }
  },
  components: {
    LayoutSimCardsHeader,
    LayoutSimCardsContent,
    LayoutSimCardsSearchbar,
    LayoutSimCardsAdd,
  },
};
</script>
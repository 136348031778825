<template>
  <div class="mt-4 mb-9">
    <!-- Seccion de comentarios adcionales añadida -->
    <div class="content-txt-title-section poppins">
      Información adicional de la solicitud
    </div>

    <div>
      <v-col cols="12" sm="6">
        <div>
          <v-textarea
            v-model="comentarios"
            type="text"
            :label="`Comentarios Adicionales`"
            :placeholder="`Comentarios Adicionales`"
            dense
            color="#2759A2"
            class="global-auth-inputs poppins mt-9"
            maxlength="500"
            persistent-placeholder
            @keyup="listenChanges"
            rows="4"
            :disabled="fCurrentStatus()"
          ></v-textarea>
        </div>
      </v-col>
    </div>
    <div class="border-line mb-5" />

    <!-- termino de seccion de comentarios adicionales -->
    <div class="content-txt-title-section poppins">Información de entrega</div>
    <div class="mt-9">
      <v-container class="pa-0 ml-2" fluid>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <div>
              <v-select
                v-model="tipoEnvio"
                type="text"
                label="Tipo de envío"
                placeholder="Tipo de envío"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                :items="itemsTipoEnvio"
                item-text="text"
                item-value="value"
                @change="changeTipoEnvio"
                no-data-text="No hay datos disponibles"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span> Tipo de envío <span class="color-red">*</span> </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col v-if="tipoEnvio == 2" cols="12" sm="4" md="3">
            <div>
              <v-select
                v-model="courierPreferencia"
                type="text"
                label="Paquetería de preferencia"
                placeholder="Paquetería de preferencia"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                :items="itemsCouriers"
                item-text="sName"
                item-value="sCarrierId"
                @change="listenChanges"
                :disabled="fCurrentStatus()"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span>
                    Paquetería de preferencia <span class="color-red">*</span>
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <p class="txt-title-content-inputs poppins">Datos de ubicación</p>
      <v-container class="pa-0 ml-2" fluid v-if="tipoEnvio !== 3">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <div>
              <v-select
                v-model="paisUbicacion"
                type="text"
                :label="`País de ${nombreEnvio}`"
                :placeholder="`País de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                :items="itemsCountries"
                item-text="sName"
                item-value="sCountryId"
                @change="
                  selectStates();
                  listenChanges();
                "
                :disabled="fCurrentStatus()"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span>
                    País de {{ nombreEnvio }} <span class="color-red">*</span>
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <div>
              <v-select
                v-model="estadoUbicacion"
                type="text"
                :label="`Estado de ${nombreEnvio}`"
                :placeholder="`Estado de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                :items="itemsStates"
                item-text="sName"
                item-value="sStateId"
                @change="listenChanges"
                :disabled="fCurrentStatus()"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span>
                    Estado de {{ nombreEnvio }} <span class="color-red">*</span>
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <div>
              <v-text-field
                v-model="ciudadUbicacion"
                type="text"
                :label="`Ciudad de ${nombreEnvio}`"
                :placeholder="`Ciudad de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                @keyup="listenChanges"
                @keypress="soloLettersAndNumbers"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Ciudad de {{ nombreEnvio }} <span class="color-red">*</span>
                  </span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-text-field
                v-model="codigoPostal"
                :label="`Código postal de ${nombreEnvio}`"
                :placeholder="`Código postal de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                @keypress="soloNumbers"
                maxlength="5"
                persistent-placeholder
                :disabled="fCurrentStatus()"
                @keyup="listenChanges"
              >
                <template slot="label">
                  <span>
                    Código postal de {{ nombreEnvio }}
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <v-textarea
                v-model="direccionUbicacion"
                type="text"
                :label="`Dirección de ${nombreEnvio}`"
                :placeholder="`Dirección de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="500"
                persistent-placeholder
                @keyup="listenChanges"
                rows="4"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Dirección de {{ nombreEnvio }}
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>
              <v-textarea
                v-model="referenciaUbicacion"
                type="text"
                :label="`Referencias adicionales de ${nombreEnvio}`"
                :placeholder="`Referencias adicionales de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="500"
                persistent-placeholder
                @keyup="listenChanges"
                rows="4"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Referencias adicionales de {{ nombreEnvio }}
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pl-0 pr-0" fluid v-else>
        <v-row>
          <v-col>
            <div>
              <h3>Emprenet</h3>
              <h4>SOLUCIONES EN COMUNICACION MOVIL EMPRESARIAL SA DE CV</h4>
              <h5>Lisboa 806, Mirador, 64070 Monterrey, N.L., Mexico</h5>
              <h5>+52 81 2504 1824</h5>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <p class="txt-title-content-inputs poppins">Datos de contacto</p>
      <v-container class="pa-0 ml-2" fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-text-field
                v-model="nombreContacto"
                type="text"
                label="Nombre completo del destinatario"
                placeholder="Nombre completo del destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                @keyup="listenChanges"
                @keypress="soloLetters"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Nombre completo del destinatario
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-text-field
                v-model="correoContacto"
                type="text"
                label="Correo electrónico del destinatario"
                placeholder="Correo electrónico del destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
                @keypress="formatEmail"
                @keyup="listenChanges"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Correo electrónico del destinatario
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-text-field
                v-model="telefonoContacto"
                type="text"
                label="Número telefónico del destinatario"
                placeholder="Número telefónico del destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins mb-9"
                maxlength="10"
                persistent-placeholder
                @keyup="listenChanges"
                @keypress="soloNumbers"
                :disabled="fCurrentStatus()"
              >
                <template slot="label">
                  <span>
                    Número telefónico del destinatario
                    <span class="color-red">*</span>
                  </span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <div>
              <v-textarea v-model="comentarios" type="text" :label="`Comentarios`"
                :placeholder="`Comentarios`" dense color="#2759A2"
                class="global-auth-inputs poppins mb-9" maxlength="500" persistent-placeholder @keyup="listenChanges"
                rows="4" :disabled="fCurrentStatus()"></v-textarea>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
    </div>
    <div v-if="!activeButtons" class="content-buttons">
      <v-btn
        @click="discardChanges"
        class="button-secondary poppins"
        elevation="0"
      >
        Descartar cambios
      </v-btn>
      <v-btn
        @click="edit"
        :loading="bLoading"
        :disabled="!validationForm"
        class="button-primary poppins ml-5"
        elevation="0"
      >
        Guardar cambios
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailSimsDeliveryData",
  props: {
    itemsSims: {
      type: Object,
    },
  },
  data() {
    return {
      itemsCountries: [],
      itemsStates: [],
      itemsCouriers: [],
      itemsTipoEnvio: [
        {
          text: "Entrega a domicilio",
          value: 1,
        },
        {
          text: "Entrega en sucursal",
          value: 2,
        },
        {
          text: "Buscar Emprenet",
          value: 3,
        },
      ],
      tipoEnvio: "",
      courierPreferencia: "",
      paisUbicacion: "",
      estadoUbicacion: "",
      ciudadUbicacion: "",
      codigoPostal: "",
      direccionUbicacion: "",
      referenciaUbicacion: "",
      nombreContacto: "",
      correoContacto: "",
      telefonoContacto: "",
      paisUbicacionOriginal: "",
      estadoUbicacionOriginal: "",
      tipoEnvioOriginal: "",
      nombreEnvio: "",
      activeButtons: true,
      bLoading: false,
      // comentariosadicionales: "",
      comentarios: "",
    };
  },
  beforeMount() {
    this.fillContent();
    this.getCountries();
    this.getStates();
    this.getCouriers();
  },
  methods: {
    fCurrentStatus: function () {
      let status_boolean = false;
      if (
        this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId ==
        "8ee1565e-ee98-462d-a445-b1d7248dbaed"
      ) {
        status_boolean = true;
      } else if (
        this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId ==
        "875c3c08-cf5a-424b-988b-e724d33e4e8b"
      ) {
        status_boolean = true;
      } else if (!this.bAdminSIMCardsSolicitud) {
        status_boolean = true;
      } else {
        status_boolean = false;
      }
      return status_boolean;
    },
    changeTipoEnvio: function () {
      if (this.tipoEnvio == 1 || this.tipoEnvio == "1") {
        this.nombreEnvio = "domicilio";
        this.paisUbicacion = null;
        this.estadoUbicacion = null;
        this.courierPreferencia = null;
        this.ciudadUbicacion = "";
        this.codigoPostal = "";
        this.direccionUbicacion = "";
        this.referenciaUbicacion = "";
      } else if (this.tipoEnvio == 2 || this.tipoEnvio == "1") {
        this.nombreEnvio = "sucursal";
        this.paisUbicacion = null;
        this.estadoUbicacion = null;
        this.courierPreferencia = null;
        this.ciudadUbicacion = "";
        this.codigoPostal = "";
        this.direccionUbicacion = "";
        this.referenciaUbicacion = "";
      } else {
        this.nombreEnvio = "";

        this.paisUbicacion = "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b";
        this.estadoUbicacion = "36030259-16f9-43c8-856d-2854d7f6111b";
        this.courierPreferencia = "8e3eb4b3-210b-4b24-9759-822c65a5c160"; //Temporal
        this.ciudadUbicacion = "Monterrey";
        this.codigoPostal = "64070";
        this.direccionUbicacion = "Lisboa 806, Mirador";
        this.referenciaUbicacion = "Emprenet";
      }
      this.listenChanges();
    },
    edit: function (value) {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            var config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            };
            var payload = {
              // iSmallSIMsQuantityRequired: this.itemsSims.iSmallSIMsQuantityRequired,
              // iSmallSIMsQuantityApproved: this.itemsSims.iSmallSIMsQuantityApproved,
              // iMediumSIMsQuantityRequired: this.itemsSims.iMediumSIMsQuantityRequired,
              // iMediumSIMsQuantityApproved: this.itemsSims.iMediumSIMsQuantityApproved,
              // iLargeSIMsQuantityRequired: this.itemsSims.iLargeSIMsQuantityRequired,
              // iLargeSIMsQuantityApproved: this.itemsSims.iLargeSIMsQuantityApproved,

              sShippingAddressLine1: this.direccionUbicacion,
              sShippingAddressLine2: this.referenciaUbicacion,
              sShippingCity: this.ciudadUbicacion,
              sShippingStateId: this.estadoUbicacion,
              sShippingPostalCode: this.codigoPostal,
              // sTrackingNumber: this.itemsSims.sTrackingNumber,
              tEstimatedDelivery: this.itemsSims.tEstimatedDelivery,
              sCustomPlanId: this.itemsSims.sCustomPlanId,
              sReceiverEmail: this.correoContacto,
              sReceiverPhone: this.telefonoContacto,
              sComments: this.comentarios,
              sReceiverFullName: this.nombreContacto,
              eDeliveryType: this.tipoEnvio,
              sCarrierId: "8e3eb4b3-210b-4b24-9759-822c65a5c160",
              // sAditionalComments: this.comentariosadicionales,
            };

            if (this.tipoEnvio === 2 || this.tipoEnvio === "2") {
              payload.sCarrierId = this.courierPreferencia;
            }

            DB.put(
              `${URI}/sim-requests/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.activeButtons = true;

                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;

                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.activeButtons = true;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.activeButtons = true;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        });
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      var regex = new RegExp("^[0-9]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    fillContent: function () {
      this.itemsOriginal = this.itemsSims;
      this.tipoEnvio = parseInt(this.itemsSims.eDeliveryType);
      this.codigoPostal = this.itemsSims.sShippingPostalCode;
      this.courierPreferencia = this.itemsSims.sCarrierId;
      this.ciudadUbicacion = this.itemsSims.sShippingCity;
      this.direccionUbicacion = this.itemsSims.sShippingAddressLine1;
      this.referenciaUbicacion = this.itemsSims.sShippingAddressLine2;
      this.nombreContacto = this.itemsSims.sReceiverFullName;
      this.correoContacto = this.itemsSims.sReceiverEmail;
      this.telefonoContacto = this.itemsSims.sReceiverPhone;
      this.estadoUbicacion = this.itemsSims.sShippingStateId;
      this.paisUbicacion = this.itemsSims.sCountryId;
      this.estadoUbicacion = this.itemsSims.sStateId;
      this.comentarios = this.itemsSims.sComments;
      this.comentariosadicionales = this.itemsSims.sAditionalComments;
    },
    listenChanges: function () {
      this.activeButtons =
        this.courierPreferencia == this.itemsOriginal.sCarrierId &&
        this.ciudadUbicacion.trim() ==
          this.itemsOriginal.sShippingCity.trim() &&
        this.direccionUbicacion.trim() ==
          this.itemsOriginal.sShippingAddressLine1.trim() &&
        this.referenciaUbicacion.trim() ==
          this.itemsOriginal.sShippingAddressLine2.trim() &&
        this.nombreContacto.trim() ==
          this.itemsOriginal.sReceiverFullName.trim() &&
        this.correoContacto.trim() ==
          this.itemsOriginal.sReceiverEmail.trim() &&
        this.telefonoContacto.trim() ==
          this.itemsOriginal.sReceiverPhone.trim() &&
        this.paisUbicacion == this.itemsOriginal.sCountryId &&
        this.estadoUbicacion == this.itemsOriginal.sStateId &&
        this.tipoEnvio == this.itemsOriginal.eDeliveryType &&
        this.comentarios == this.itemsOriginal.sComments &&
        // this.comentariosadicionales == this.itemsOriginal.sAditionalComments &&
        this.codigoPostal == this.itemsOriginal.sShippingPostalCode;
    },
    discardChanges: function () {
      this.activeButtons = true;
      this.itemsOriginal = this.itemsSims;
      this.tipoEnvio = this.itemsSims.eDeliveryType;
      this.courierPreferencia = this.itemsSims.sCarrierId;
      this.ciudadUbicacion = this.itemsSims.sShippingCity;
      this.direccionUbicacion = this.itemsSims.sShippingAddressLine1;
      this.referenciaUbicacion = this.itemsSims.sShippingAddressLine2;
      this.nombreContacto = this.itemsSims.sReceiverFullName;
      this.correoContacto = this.itemsSims.sReceiverEmail;
      this.telefonoContacto = this.itemsSims.sReceiverPhone;
      this.estadoUbicacion = this.itemsSims.sShippingStateId;
      this.codigoPostal = this.itemsSims.sShippingPostalCode;
      this.comentarios = this.itemsSims.sComments;
      // this.comentariosadicionales = this.itemsSims.sAditionalComments;
    },
    getCouriers: function () {
      DB.get(`${URI}/carriers`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCouriers = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCountries = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectStates: function () {
      this.estadoUbicacion = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.paisUbicacion,
        },
      })
        .then((response) => {
          this.itemsStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    bAdminSIMCardsSolicitud() {
      return this.$store.state.bAdminSIMCardsSolicitud;
    },
    validationForm: function () {
      return (
        this.estadoUbicacion !== "" &&
        this.ciudadUbicacion !== "" &&
        this.direccionUbicacion !== "" &&
        this.referenciaUbicacion !== "" &&
        this.nombreContacto !== "" &&
        this.correoContacto !== "" &&
        this.telefonoContacto !== ""
      );
    },
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

.txt-title-content-inputs {
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  font-weight: 600;
}
.border-line {
  border-top: 1px solid #a1acc3;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .content-buttons button:nth-child(1) {
    width: 100%;
  }

  .content-buttons button:nth-child(2) {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }
}
</style>

<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      <div
        v-if="!activeCard"
        style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px"
      >
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item_header, index_header) in headers"
                  :key="index_header"
                  :class="item_header.align"
                  class="table-header-text poppins"
                >
                  {{ item_header.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="detailItem(item.sAdvanceInvoiceId)"
                >
                  {{ item.sFolio }}
                </td>
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="detailItem(item.sAdvanceInvoiceId)"
                >
                  {{ item.sClient }}
                </td>
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="detailItem(item.sAdvanceInvoiceId)"
                >
                  {{ item.sPeriod }}
                </td>
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="detailItem(item.sAdvanceInvoiceId)"
                >
                  ${{ formatMoneyGlobal(item.iTotalAmount) }}MXN
                </td>
                <td
                  class="txt-item-table text-center global-col-body-accion poppins"
                >
                  <div
                    class="display-flex align-items-center justify-content-center"
                  >
                    <v-btn @click="detailItem(item.sAdvanceInvoiceId)" icon>
                      <v-icon color="#000" size="16px">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="getPermissionsActionsGlobal()"
                      :loading="item.bLoadingPDF"
                      @click="downloadItem(item)"
                      icon
                    >
                      <v-icon color="#000" size="16px"> mdi-download </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="display-flex align-items-center"
                >
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item
                      v-for="(item_pages, index_pages) in pages"
                      :key="index_pages"
                      @click="changeNumPages(item_pages)"
                      link
                    >
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col
                v-for="(item, index) in items"
                :key="index"
                cols="12"
                sm="6"
              >
                <div
                  :class="
                    true
                      ? 'content-card-responsive card-active position-relative'
                      : 'content-card-responsive card-inactive position-relative'
                  "
                >
                  <div class="height-100">
                    <div>
                      <div class="content-first-row-card">
                        <div class="content-text-title">
                          <p class="txt-item-table-card-title poppins mb-0">
                            {{ item.sClient }}
                          </p>
                          <p
                            class="poppins mb-2"
                            :class="
                              item.sFolio
                                ? 'txt-item-table-card-item'
                                : 'txt-item-table-card-item-empty'
                            "
                          >
                            {{ item.sFolio ? item.sFolio : "Sin folio" }}
                          </p>
                        </div>
                        <div class="content-menu-actions">
                          <v-menu
                            offset-y
                            content-class="content-menu-options"
                            attach
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div class="content-actions">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  class="content-menu-options-dots"
                                >
                                  <v-icon color="#000" size="20px">
                                    mdi-dots-vertical
                                  </v-icon>
                                </div>
                              </div>
                            </template>
                            <div class="circle-content-options">
                              <div>
                                <v-list dense>
                                  <v-list-item
                                    link
                                    @click="detailItem(item.sAdvanceInvoiceId)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon color="#000" size="14px">
                                        mdi-eye-outline
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="poppins">
                                      Detalle
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="getPermissionsActionsGlobal()"
                                    link
                                    @click="downloadItem(item)"
                                    :disbaled="true"
                                  >
                                    <v-list-item-icon>
                                      <v-icon size="14px">
                                        mdi-download
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content
                                      class="text-delete-card poppins"
                                    >
                                      Descargar
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>
                            </div>
                          </v-menu>
                        </div>
                      </div>

                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Cliente</p>
                        <p class="px-txt-card-item mb-0">{{ item.sClient }}</p>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Periodo</p>
                        <p class="px-txt-card-item mb-0">{{ item.sPeriod }}</p>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Total</p>
                        <p class="px-txt-card-item mb-0">
                          ${{ formatMoneyGlobal(item.iTotalAmount) }} MXN
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div
          class="content-pagination mt-4"
          style="background-color: transparent"
        >
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="display-flex align-items-center"
                >
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item
                      v-for="(item_pages, index_pages) in pages"
                      :key="index_pages"
                      @click="changeNumPages(item_pages)"
                      link
                    >
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <empty-content-component
      v-else
      text="Aún no hay información para mostrar."
      height="450"
    />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import lodash from "lodash";
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutClientsContent",
  props: {
    sSearch: String,
    sDate: String,
    sPromotionsType: String,
    sCreatedAt: String,
  },
  data() {
    return {
      bLoading: false,
      items: [],
      headers: [
        {
          title: "Folio",
          align: "text-left",
        },
        {
          title: "Cliente",
          align: "text-left",
        },
        {
          title: "Periodo",
          align: "text-left",
        },
        {
          title: "Total",
          align: "text-left",
        },
        {
          title: "Acciones",
          align: "text-center global-col-header-accion",
        },
      ],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
      emptyData: "Sin información.",
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    detailItem: function (id) {
      this.$router.push({
        name: "detailPrebilling",
        params: {
          id: id,
        },
      });
    },
    downloadItem: function (item) {
      this.bLoading = true;
      item.bLoadingPDF = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            DB.get(`${URI}/advance-invoices/${item.sAdvanceInvoiceId}/pdf`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {},
            })
              .then((response) => {
                if (response.data.results) {
                  let LinkPDF = response.data.results;
                  let popUp = window.open(LinkPDF, "_blank");
                  if (popUp === null || typeof popUp === "undefined") {
                    this.bLoading = false;

                    this.mixError("Activar ventana emergente del navegador", 0);
                  } else {
                    this.bLoading = false;
                    popUp.focus();
                  }
                } else {
                  this.mixError("Prefactura no disponible aún.", 0);
                }
                item.bLoadingPDF = false;
              })
              .catch((error) => {
                this.bLoading = false;
                item.bLoadingPDF = false;
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            item.bLoadingPDF = false;
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          item.bLoadingPDF = false;
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        });
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    getData: function () {
      this.bLoading = true;

      DB.get(`${URI}/advance-invoices`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          sPeriod: this.sDate,
          // tStart: this.sDate[0],
          // tEnd: this.sDate[1],
          sType: this.sPromotionsType,
          sSortStatus: this.sCreatedAt,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.items = response.data.results.map((e) => {
            return {
              ...e,
              sClient: e.oEnterprise.sName,
              sPeriod: e.oPeriod.sName,
              bLoadingPDF: false,
              // iTotalAmount: 20300.34
            };
          });
          this.numPages = response.data.iNumPages;
          this.iPageNumber = response.data.iNumPages;
          this.totalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getData();
    },
    sCreatedAt: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    sPromotionsType() {
      this.getData();
    },
    sDate: function () {
      this.getData();
    },
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.card-inactive {
  border: 1px solid #e33f3f;
}

.card-active {
  border: 1px solid #2759a2;
}

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  overflow-wrap: break-word !important;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.text-delete-card {
  /* color: #e33f3f; */
}

.content-text-title {
  width: 90%;
  max-width: 90%;
  min-width: auto;
}

.content-actions {
  display: flex;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  margin-left: 10px;
}

.content-first-row-card {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.content-status-mobile-card {
  width: 70px;
  display: flex;
  /* position: absolute; */
  right: 30px;
  top: 10px;
}

.content-menu-options-dots {
  /* position: absolute; */
  /* top: 10px; */
  /* right: 10px; */
}

.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}
</style>

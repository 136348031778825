<template>
  <div>
    <div v-if="items.length > 0" class="mt-4">
      <!-- table content -->
      <div v-if="!activeCard">
        <div style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-if="getPermissionsActionsGlobal()"></th>
                  <th v-for="(item_header, index_header) in headers" :key="index_header" :class="item_header.align"
                    class="table-header-text poppins" v-html="item_header.title" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index" >
                  <td v-if="getPermissionsActionsGlobal()" >
                    <div class="d-flex justify-center align-center">
                      <span class="mr-2">
                        {{item.sIndex}}
                      </span>
                      <v-checkbox v-model="item.bSimsSelected" dense color="#3B678D" hide-details class="mt-n1"
                        @click="selectItem(item.sICC)"></v-checkbox>
                    </div>
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer" >
                    {{ item.sICC }}
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer" >
                    {{ item.sIMEINumber }}
                  </td>
                  <td class="txt-item-table text-center poppins cursor-pointer" >
                    <v-progress-linear :color="getColor(item.iValue, item.iLimit)"
                      :value="getPercent(item.iValue, item.iLimit)" rounded height="8px"></v-progress-linear>
                    <span class="text-center">{{ item.iValue }}MB/{{ item.iLimit }}MB</span>
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer" >
                    {{ item.sDataPlan }}
                  </td>
                  <td class="
                      txt-item-table
                      text-left
                      d-flex
                      justify-center
                      align-center
                      poppins
                      cursor-pointer
                    " >
                    <v-chip small class="ma-2" text-color="black">
                      {{ item.oStatus.sName }}
                    </v-chip>
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer"  >
                    {{ item.oGPRSStatus.iStatus }}
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer"  >
                    {{ item.sAlias }}
                  </td>
                  <td class="txt-item-table text-left poppins cursor-pointer"  >
                    {{ item.sId }}
                  </td>
                  <td class="
                      txt-item-table
                      text-center
                      poppins
                      fixed-column-right
                      global-col-body-accion
                      cursor-pointer
                    "
                    >
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                      ">
                      <v-btn icon @click="viewItem(item)">
                        <v-icon color="#000" size="16px">
                          mdi-eye-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- pagination -->
          <div class="content-pagination">
            <div class="display-flex align-items-center justify-content-flex-end">
              <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
              <v-menu offset-y content-class="txt-number-pages-table">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                    <div class="txt-pages-table poppins">
                      {{ iItemsPerPage }}
                    </div>
                    <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                  </div>
                </template>
                <div>
                  <div>
                    <v-list dense>
                      <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                        @click="changeNumPages(item_pages)" link>
                        <v-list-item-content class="poppins">
                          {{ item_pages }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </v-menu>
              <p class="txt-current-page-table poppins ml-2 mb-0">
                {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
              </p>
              <v-btn @click="prev" icon :disabled="blockButton">
                <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn @click="next" icon :disabled="blockButton">
                <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <!-- <div class="mt-5">
          <v-checkbox
            v-model="allSelected"
            dense
            color="#3B678D"
            hide-details
            class="px-card-test-select-all"
            @click="selectAll"
            label="Seleccionar todo"
          ></v-checkbox>
        </div> -->
        <div class="mt-2">
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                <div class="content-card-responsive">
                  <div class="display-flex align-items-center">
                    <span class="mr-2">
                        {{item.sIndex}}
                      </span>
                    <v-checkbox v-model="item.bSimsSelected" dense color="#3B678D" hide-details class="mt-n1"
                      @click="selectItem(item.sICC)"></v-checkbox>
                    <v-spacer />
                    <v-chip small class="ma-2" text-color="black">
                      {{
                      item.oStatus.sName
                      }}
                    </v-chip>
                    <!-- <div class="etiqueta-status">{{ item.oStatus.sName }}</div> -->
                    <div>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                            <v-icon size="18px" color="#000">
                              mdi-dots-vertical
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item-group v-model="selectedItemAction" color="primary">
                            <v-list-item v-for="(itemAction, index) in actions" :key="index" @click="viewItem(item)">
                              <v-list-item-icon class="mr-1">
                                <v-icon color="#000" size="14px">{{ itemAction.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="itemAction.title"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="flex-wrap display-flex align-items-center">
                      <p class="px-txt-card-title mb-0">Folio:</p>
                      <p class="px-txt-card-item mb-0">{{ item.sICC }}</p>
                    </div>
                    <div class="flex-wrap display-flex align-items-center">
                      <p class="px-txt-card-title mb-0">Plan de datos:</p>
                      <p class="px-txt-card-item mb-0">{{ item.sDataPlan }}</p>
                    </div>
                  </div>
                  <div class="mt-2 full-width text-right">
                    <v-progress-linear :color="getColor(item.iValue, item.iLimit)"
                      :value="getPercent(item.iValue, item.iLimit)" rounded height="8px"></v-progress-linear>
                    <span class="px-card-text-mb">{{ item.iValue }}MB/{{ item.iLimit }}MB</span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div class="content-pagination mt-4" style="background-color: transparent">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <layout-sims-cards-options-desktop v-if="getPermissionsActionsGlobal()" :selectedItems="selectedItems" />
    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
    <!-- envio de sms -->
    <send-sms-component :selectedItems="selectedItems" />
    <!-- activar tarjetas sim  -->
    <activated-sims-component :selectedItems="selectedItems" />
    <!-- desaactivar tarjetas sim  -->
    <desactivated-sims-component :selectedItems="selectedItems" />
    <!-- reset tarjetas sim  -->
    <reset-sims-component :selectedItems="selectedItems" />
    <!-- launch test sim  -->
    <launch-test-component :selectedItems="selectedItems" />
  </div>
</template>

<script>
import lodash from "lodash";
import LayoutSimsCardsOptionsDesktop from "@/layouts/SimCards/OptionsDesktop";
import SendSmsComponent from "@/components/SendSms.vue";
import ActivatedSimsComponent from "@/components/ActivatedSims.vue";
import DesactivatedSimsComponent from "@/components/DesactivatedSims.vue";
import ResetSimsComponent from "@/components/ResetSims.vue";
import LaunchTestComponent from "@/components/LaunchTest.vue";
import VueSimpleSpinner from "vue-simple-spinner";

export default {
  name: "LayoutSimCardsContent",
  props: {
    sSearch: String,
    sPlanId: String,
    oPayload: Object,
  },
  data() {
    return {
      bLoading: false,
      selectedItems: [],
      allSelected: false,
      items: [],
      test: "",
      headers: [
        {
          title: "ICC",
          align: "text-left",
        },
        {
          title: "Número IMEI",
          align: "text-left",
        },
        {
          title: "Consumo MB",
          align: "text-left",
        },
        {
          title: "Plan de datos",
          align: "text-left",
        },
        {
          title: "Estado",
          align: "text-center",
        },
        {
          title: "GPRS",
          align: "text-left",
        },
        {
          title: "Alias",
          align: "text-left",
        },
        {
          title: "ID",
          align: "text-left",
        },
        {
          title: "Acciones",
          align: "text-center global-col-header-accion",
        },
      ],
      //pagination items
      pages: ["10", "15", "20", "25"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 10,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
      bSpinner: true,
      blockButton: false,
      actions: [{ title: "Detalle", icon: "mdi-eye-outline", action: 1 }],
      selectedItemAction: 1,
      scTimer: 0,
      scY: 0,
    };
  },
  beforeMount() {
    this.iCurrentPage = this.iCurrentPageGlobal;
    this.getDataKite();
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll () {

      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    getColor: function (iValue, iLimit) {
      let dPorcent = this.getPercent(iValue, iLimit);
      let color = "gray";
      if (dPorcent < 24.99) {
        color = "green";
      } else if (dPorcent >= 25 && dPorcent < 49.99) {
        color = "yellow";
      } else if (dPorcent >= 50 && dPorcent < 74.99) {
        color = "orange";
      } else {
        color = "red";
      }
      return color;
    },
    selectItem: function () {
      this.selectedItems = this.items
        .filter((e) => e.bSimsSelected === true)
        .map(({ sICC }) => sICC);
    },
    getPercent(iValue, iLimit) {
      let res = 0;

      res = (iValue / iLimit) * 100;
      return res === Infinity ? 100 : isNaN(res) ? 0 : res;
    },
    selectAll: function () {
      this.selectedItems = [];
      if (this.allSelected) {
        for (let item in this.items) {
          this.selectedItems.push(this.items[item].sICC);
        }
      }
    },
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/sim-requests/${id}`,
        title: "Eliminar solicitud",
        description:
          "¿Estás seguro de eliminar esta solicitud? <br/> Al realizar esta <br/> acción no podrá revertirse",
      };
      this.$emit("setOptions", options);
    },
    viewItem: function (item) {
      this.$router.push({
        name: "detailSimCards",
        params: {
          id: item.sICC,
        },
      });
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getDataKite();
      this.$store.commit("setGoToTop",!this.bGoToTop)

    },
    formatDate: function (date) {
      return MOMENT(date).locale("es").format("DD/MMM/YYYY");
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
        this.$store.commit("setCurrentPageGlobal", this.iCurrentPage)
        this.$store.commit("setGoToTop",!this.bGoToTop)

      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
        this.$store.commit("setCurrentPageGlobal", this.iCurrentPage)
        this.$store.commit("setGoToTop",!this.bGoToTop)


      }
    },
    getDataKite: function () {
      this.bLoading = true;
      this.bSpinner = true;
      this.blockButton = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const oParams = {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };

          const payload = Object.assign(oParams, this.setValidatePayable() ? this.setFilterSim() : this.oPayload),
            config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            };
          DB.get(`${URL_KITE}/`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: payload,
          })
            .then((response) => {
              this.items = response.data.results.map((e, i) => {
                return {
                  ...e,
                  bSimsSelected: false,
                  bLiteEnabled: e.bLteEnabled,
                  iLimit: e.oConsumptionMonthly.iLimit,
                  iValue: e.oConsumptionMonthly.iValue,
                };
              });


              this.numPages = response.data.iNumPages;
              this.iPageNumber = response.data.iNumPages;
              this.totalItems = response.data.iTotal;
              this.bLoading = false;
              this.bSpinner = false;
              this.blockButton = false;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoading = false;
              this.$toast.warning("El servidor no ha respondido, por favor inténtalo de nuevo haciendo clic en el botón Actualizar.", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    setFilterSim() {
      switch (this.sFilterTypeGlobal) {
        case 1:
          return {
            sICC: this.sSearchGlobal.trim(),
          };
          break;
        case 2:
          return {
            sIMEI: this.sSearchGlobal.toString().replaceAll("-", "").trim(),
          };
          break;
        case 3:
          return {
            sDataPlan: this.sSearchGlobal.trim(),
          };
          break;
        case 4:
          return {
            sId: this.sSearchGlobal.trim(),
          };
          break;
        case 5:
          return {
            sLifeCycleStatus: this.sSearchGlobal.trim(),
          };
          break;

        default:
          break;
      }
      this.$emit("setPayload", this.oPayload);
    },
    setValidatePayable() {
      if (this.sFilterTypeGlobal !== null && this.sSearchGlobal !== "") {
        return true;
      } else {
        return false;
      }
    },

  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
    iCurrentPageGlobal() {
      return this.$store.state.iCurrentPageGlobal;
    },
    sFilterTypeGlobal() {
      return this.$store.state.sFilterTypeGlobal;
    },
    sSearchGlobal() {
      return this.$store.state.sSearchGlobal;
    },
    bGoToTop(){
      return this.$store.state.bGoToTop;
    }
  },
  watch: {
    refresh: function () {
      this.getDataKite();
    },
    iCurrentPage: function () {
      this.getDataKite();
    },
    sSearch: lodash.debounce(function () {
      this.getDataKite();
    }, 500),
    sPlanId: function () {
      this.iCurrentPage = 1;
      this.getDataKite();
    },
    oPayload() {
      this.iCurrentPage = 1;
      this.getDataKite();
    },
  },
  components: {
    LayoutSimsCardsOptionsDesktop,
    SendSmsComponent,
    ActivatedSimsComponent,
    DesactivatedSimsComponent,
    ResetSimsComponent,
    LaunchTestComponent,
    VueSimpleSpinner,
  },
};
</script>


<style scoped>
.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}

.etiqueta-status {
  background: #ddebff;
  padding: 5px 10px;
  font-size: 10px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  border-radius: 20px;
  height: 25px;
  width: fit-content;
  display: flex;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
}

.table-header-text {
  white-space: pre;
}

/* 
.fixed-column-left {
  position: sticky !important;
  left: 0;
  top: auto !important;
  z-index: 3 !important;
  background: #fff !important;
}

.fixed-column-right {
  position: sticky !important;
  right: 0;
  top: auto !important;
  z-index: 3 !important;
  background: #fff !important;
} */

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  opacity: 1;
}
</style>
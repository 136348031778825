<template>
  <div class="content-login">
    <div class="display-flex align-items-center justify-content-center mb-9">
      <div class="content-image">
        <img src="@/assets/images/logo-color.svg" alt="" />
      </div>
    </div>
    <div class="display-flex align-items-center justify-content-center">
      <p class="txt-title poppins">Inicio de sesión</p>
    </div>
    <div class="content">
      <v-text-field v-model="email" type="email" label="Correo electrónico" placeholder="Correo electrónico" dense
        color="#2759A2" class="global-inputs poppins mb-9" persistent-placeholder autocomplete="" maxlength="85"
        @keypress="preLogin"></v-text-field>
      <v-text-field v-model="password" label="Contraseña" placeholder="Contraseña" dense
        :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (bShowPassword = !bShowPassword)"
        :type="bShowPassword ? 'text' : 'password'" color="#2759A2" class="global-inputs poppins" persistent-placeholder
        autocomplete="new-password" maxlength="12" @keypress="preLogin"></v-text-field>
    </div>
    <div class="mt-9">
      <v-btn @click="login" :disabled="!emptyValidation" :loading="bLoading" elevation="0"
        class="button-primary poppins width-100">
        Iniciar sesión
      </v-btn>
    </div>
    <div class="mt-5">
      <p @click="forgotPassword" class="txt-link mb-0">
        ¿Problemas para iniciar sesión?
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutLoginForm",
  data() {
    return {
      email: "",
      password: "",
      bShowPassword: false,
      bLoading: false,
    };
  },
  methods: {
    preLogin: function ($event) {
      if ($event.code == "Enter") {
        if (this.emptyValidation) {
          this.login();
        } else {
          this.mixError(
            "Favor de validar la información. Los campos no pueden estar vacíos."
          );
        }
      }
    },
    forgotPassword: function () {
      this.$router
        .push({
          name: "forgotPassword",
        })
        .catch(() => { });
    },
    // login: function () {
    //   this.bLoading = true;

    //   const payload = {
    //     sEmail: this.email,
    //     sPassword: this.password,
    //   };

    //   DB.post(`${URI}/auth/administrators`, payload)
    //     .then((response) => {
    //       this.bLoading = false;
    //       this.$store.commit("setToken", response.data.sToken);
    //       this.$store.commit("setUserId", response.data.sUserId);
    //       this.$store.commit("setFullName", { sName: response.data.sName, sLastname: response.data.sLastname });
    //       this.$store.commit("setStatus", response.data.status);
    //       this.$store.commit("setManager", response.data.bManager);
    //       this.mixInfo(response.data.sMessage);
    //       this.$router
    //         .push({
    //           name: "inicio",
    //         })
    //         .catch(() => { });
    //     })
    //     .catch((error) => {
    //       this.bLoading = false;
    //       this.mixError(error.response.data.message, error.response.status);
    //     });
    // },
    login: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };

      DB.post(`${URI}/auth/administrators`, payload)
        .then((response) => {
          this.$store
            .dispatch(
              "setPermissionsBtnGlobal",
              response.data.aPermissions
            )
            .then((resp) => {
              this.bLoading = false;
              let aPermissions = response.data.aPermissions

              this.$store.commit("setPermissions", {
                aUserPermissions: aPermissions,
              });

              // this.$store.commit("setToken", response.data.sToken);
              // this.$store.commit("setTypeUser", response.data.sTypeUser);
              // this.$store.commit("setUserId", response.data.sUserId);
              // this.$store.commit("setStatus", response.data.status);
              // this.$store.commit("setManager", response.data.bManager);
              // this.$store.commit("setName", response.data.sName);
              // this.$store.commit("setFullName", { sName: response.data.sName, sLastname: response.data.sLastname });
              // this.$store.commit("setEnterpriseId", response.data.sEnterpriseId);

              this.$store.commit("setToken", response.data.sToken);
              this.$store.commit("setUserId", response.data.sUserId);
              this.$store.commit("setFullName", { sName: response.data.sName, sLastname: response.data.sLastname });
              this.$store.commit("setStatus", response.data.status);
              this.$store.commit("setManager", response.data.bManager);
              this.mixInfo(response.data.sMessage);
              this.$store.dispatch("getPermissionsByUserGlobal");
              setTimeout(() => {
                this.$router.go();
              }, 1000);
            })
            .catch((error) => {
              this.mixError(error.message, error.code);
            });
        })

        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message);
        });

    },
  },
  computed: {
    emptyValidation: function () {
      return this.email !== "" && this.password !== "";
    },
  },
};
</script>

<style scoped>
.txt-title {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  margin-bottom: 50px;
}

.content-login {
  width: 80%;
}

.content-image {
  width: 300px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>
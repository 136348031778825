<template>
  <div>
    <div v-if="Object.keys(itemsSims).length > 0">
      <layout-detail-sims-header :itemsSims="itemsSims" />
      <div class="border-line mt-5 mb-9" />
      <layout-detail-sims-content :itemsSims="itemsSims" />
    </div>
    <v-overlay class="content-overlay-global" :value="bLoadingSims">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutDetailSimsHeader from "@/layouts/DetailSims/Header.vue";
import LayoutDetailSimsContent from "@/layouts/DetailSims/Content.vue";

export default {
  name: "DetailSimsView",
  data() {
    return {
      itemsSims: {},
      bLoadingSims: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData: function () {
      this.bLoadingSims = true;
      this.itemsSims = {};

      DB.get(`${URI}/sim-requests/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.bLoadingSims = false;
          this.itemsSims = response.data.results;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingSims = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
  },
  components: {
    LayoutDetailSimsHeader,
    LayoutDetailSimsContent,
  },
};
</script>
<style scoped>
.border-line {
  border-top: 1px solid #a1acc3;
}
</style>
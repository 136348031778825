<template>
  <div class="mt-9">
    <v-container
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col
          cols="9"
          sm="9"
          md="6"
          lg="5"
        >
          <div>
            <v-text-field
              v-model="sSearch"
              type="text"
              label="Buscar por"
              placeholder="Buscar por administrador, cargo laboral, correo electrónico o teléfono"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins mb-9"
              maxlength="85"
              append-icon="mdi-magnify"
              persistent-placeholder
              @input="setSearch"
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer />
        <v-col
          cols="3"
          sm="3"
          md="3"
          lg="3"
        >
          <div class="display-flex align-items-center justify-content-flex-end position-relative">
            <v-menu
              offset-y
              content-class="circle-profile-content"
              :close-on-content-click="false"
              min-width="200px"
              attach
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="button-secondary"
                  style="width: 50px; margin-top: -5px;"
                  elevation="0"
                >
                  <v-icon>mdi-tune-vertical</v-icon>
                </v-btn>
              </template>
              <div class="circle-content-options">
                <div>
                  <div class="content-down-menu">
                    <p class="txt-title-down-menu poppins mb-0">Estado</p>
                    <div>
                      <v-checkbox
                        v-for="(item, index) in itemsEstatus"
                        :key="index"
                        v-model="resultEstatus"
                        :value="item.value"
                        label=""
                        hide-details=""
                        color="#2759a2"
                        class="mb-1"
                      >
                        <template v-slot:label>
                          <p class="txt-description-down-menu poppins mb-0">{{ item.text }}</p>
                        </template>
                      </v-checkbox>
                    </div>
                    <p class="txt-title-down-menu poppins mb-1">Orden alfabético</p>
                    <div>
                      <v-checkbox
                        v-for="(item, index) in itemsAlphabetic"
                        :key="index"
                        v-model="resultAlfabetico"
                        :value="item.value"
                        label=""
                        hide-details=""
                        color="#2759a2"
                        class="mb-1"
                      >
                        <template v-slot:label>
                          <p class="txt-description-down-menu poppins mb-0">{{ item.text }}</p>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LayoutAdministratorsSearchbar",
  data() {
    return {
      resultAlfabetico: undefined,
      resultEstatus: undefined,
      sSearch: "",
      itemsEstatus: [
        {
          text: "Activos",
          value: "true",
        },
        {
          text: "Inactivos",
          value: "false",
        },
      ],
      itemsAlphabetic: [
        {
          text: "De la A a la Z",
          value: "asc",
        },
        {
          text: "De la Z a la A",
          value: "desc",
        },
      ],
    };
  },
  methods: {
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
  },
  watch: {
    resultEstatus: function () {
      this.$emit("setPlatformAccess", this.resultEstatus);
    },
    resultAlfabetico: function () {
      this.$emit("setNameOrder", this.resultAlfabetico);
    },
  },
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}
</style>
<template>
    <div >
        <!--#region INFROMACION GENERAL DE LA PREFACTURA -->
        <v-row>
            <v-col cols="12" class="ml-0 mr-0 mt-3">
                <div class="information-title">
                    Información general
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Folio
                </p>
                <p class="txt-value-global">
                    {{ oGeneralInformation.sFolio }}
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Cliente
                </p>
                <p class="txt-value-global">
                    {{ oGeneralInformation.sClient }}
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="2" xl="2" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Estado
                </p>
                <p class="txt-value-global">
                    {{ oGeneralInformation.sStatus }}
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Periodo
                </p>
                <p class="txt-value-description-global">
                    {{ oGeneralInformation.sPeriod }}
                </p>
            </v-col>

        </v-row>
        <!--#endregion INFROMACION GENERAL DE LA PREFACTURA -->

    </div>
</template>

<script>
export default {
    props: {
        oGeneralInformation: Object
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 170px;
}
</style>
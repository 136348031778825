<template>
    <div>
        <div v-if="Object.keys(items).length > 0">
            <layout-detail-prebilling-header :pdf="pdf" :items="items" />
            <layout-detail-prebilling-content :oPayments="oPayments" :aProducts="aProducts" :aConsumption="aConsumption"
                :aDiscounts="aDiscounts" :items="items" @setTab="setTab" :bRefreshFill="bRefreshFill" />

        </div>

        <!-- loader -->
        <v-overlay class="content-overlay-global" :value="bLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            <span>Obteniendo información, por favor espere...</span>
        </v-overlay>
    </div>
</template>

<script>
import LayoutDetailPrebillingHeader from "@/layouts/DetailPrebillings/Header.vue";
import LayoutDetailPrebillingContent from "@/layouts/DetailPrebillings/Tabs.vue";
import lodash from 'lodash';

export default {
    name: "DetailPrebillingView",
    data() {
        return {
            pdf: "",
            bLoading: false,
            items: {},
            oExpense: {},
            activeDialog: false,
            objDialog: {},
            activeDialogEdit: false,
            objDialogEdit: {},
            oOptions: {},
            iTab: 0,
            aProducts: [],
            aConsumption: [],
            aDiscounts: [],
            oPayments: {},
            bRefreshFill: false
        };
    },
    beforeMount() {
        this.getPdf();
        this.getData();

    },
    methods: {
        setOptions: function (val) {
            this.oOptions = val;
        },
        getPdf() {
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}/pdf`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
            })
                .then((response) => {
                    this.bLoading = false;
                    this.pdf = response.data.results;
                    this.$store.commit("refresher", false);
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        getData() {
            this.items = {};
            this.bLoading = true;
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
            })
                .then((response) => {
                    this.items = response.data.results;
                    this.getConsumptions();

                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        getConsumptions() {
            this.aConsumption = [];
            // this.bLoading = true;
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}/items`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
            })
                .then((response) => {
                    this.aConsumption = response.data.results;
                    this.getProducts();

                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        getProducts() {
            this.aProducts = [];
            // this.bLoading = true;
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}/products`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
            })
                .then((response) => {
                    this.aProducts = response.data.results.map((e) => {
                        return {
                            ...e,
                            bEdit: false
                        }
                    });
                    this.getDiscounts();
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        getDiscounts() {
            this.aDiscounts = [];
            // this.bLoading = true;
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}/discounts`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
            })
                .then((response) => {

                    this.aDiscounts = response.data.results.map((e) => {
                        return {
                            ...e,
                            bEdit: false
                        }
                    });;
                    this.getPayments();
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        getPayments() {
            this.oPayments = {}
            // this.$route.params.id = "e9ede20c-07ec-4a62-a6fd-fa033056d83e"
            DB.get(`${URI}/advance-invoices/${this.$route.params.id}/payments`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                    tPeriodDate: this.arrDates
                },
            })
                .then((response) => {
                    this.oPayments = response.data.result;
                    this.bRefreshFill = !this.bRefreshFill

                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        setTab(iTab) {
            this.iTab = iTab;
        }

    },
    computed: {
        refresh: function () {
            return this.$store.state.refresh;
        },
    },
    watch: {
        refresh: function () {
            this.getPdf();
            this.getData();
        },
    },
    components: {
        LayoutDetailPrebillingHeader,
        LayoutDetailPrebillingContent
    },
};
</script>
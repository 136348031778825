<template>
  <div class="content-search mt-9 mb-9">
    <div class="content-input-search">
      <v-text-field v-model="sSearch" clearable type="text" label="Buscar por" placeholder="Buscar por Folio o Nombre" dense
        color="#2759A2" class="global-auth-inputs poppins mb-4" maxlength="85" append-icon="mdi-magnify"
        persistent-placeholder @input="setSearch"></v-text-field>
      <div v-show="bMobile" class="
          display-flex
          align-items-center
          justify-content-flex-end
          position-relative
        ">
        <v-menu offset-y content-class="circle-profile-content" :close-on-content-click="false" min-width="200px" attach
          left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="button-secondary ml-9" style="width: 50px; margin-top: -5px"
              elevation="0">
              <v-icon>mdi-tune-vertical</v-icon>
            </v-btn>
          </template>
          <div class="circle-content-options">
            <div>
              <div class="content-down-menu">
                <p class="txt-title-down-menu poppins mb-0">Fecha de creación</p>
                <div>
                  <v-checkbox v-for="(item, index) in itemsCreatedAt" :key="index" v-model="sCreatedAt"
                    :value="item.value" label="" hide-details="" color="#2759a2" class="mb-1">
                    <template v-slot:label>
                      <p class="txt-description-down-menu poppins mb-0">
                        {{ item.text }}
                      </p>
                    </template>
                  </v-checkbox>
                </div>
                <p class="txt-title-down-menu poppins mb-1">Último cambio de estado</p>
                <div>
                  <v-checkbox v-for="(item, index) in itemsLastChangeStatus" :key="index" v-model="sLastChangeStatus"
                    :value="item.value" label="" hide-details="" color="#2759a2" class="mb-1">
                    <template v-slot:label>
                      <p class="txt-description-down-menu poppins mb-0">
                        {{ item.text }}
                      </p>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </v-menu>
      </div>
    </div>

    <div class="content-select-type-promotion">
      <v-select v-model="sPromotionsType" label="Tipo de promoción" placeholder="Tipo de promoción" dense
        color="#2759A2" class="global-auth-inputs poppins" persistent-placeholder :items="aPromotionsType"
        item-value="sPromotionTypeId" item-text="sName" clearable></v-select>
    </div>
    <v-spacer />
    <div v-show="!bMobile" class="
        display-flex
        align-items-center
        justify-content-flex-end
        position-relative
      ">
      <v-menu offset-y content-class="circle-profile-content" :close-on-content-click="false" min-width="200px" attach
        left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="button-secondary" style="width: 50px; margin-top: -5px"
            elevation="0">
            <v-icon>mdi-tune-vertical</v-icon>
          </v-btn>
        </template>
        <div class="circle-content-options">
          <div>
            <div class="content-down-menu">
              <p class="txt-title-down-menu poppins mb-0">Fecha de creación</p>
              <div>
                <v-checkbox v-for="(item, index) in itemsCreatedAt" :key="index" v-model="sCreatedAt"
                  :value="item.value" label="" hide-details="" color="#2759a2" class="mb-1">
                  <template v-slot:label>
                    <p class="txt-description-down-menu poppins mb-0">
                      {{ item.text }}
                    </p>
                  </template>
                </v-checkbox>
              </div>
              <p class="txt-title-down-menu poppins mb-1">Último cambio de estado</p>
              <div>
                <v-checkbox v-for="(item, index) in itemsLastChangeStatus" :key="index" v-model="sLastChangeStatus"
                  :value="item.value" label="" hide-details="" color="#2759a2" class="mb-1">
                  <template v-slot:label>
                    <p class="txt-description-down-menu poppins mb-0">
                      {{ item.text }}
                    </p>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutClientsSearchbar",
  data() {
    return {
      sLastChangeStatus: undefined,
      sCreatedAt: undefined,
      sSearch: "",
      menu: false,
      itemsCreatedAt: [
        {
          text: "Ascendente",
          value: "asc",
        },
        {
          text: "Decendente",
          value: "desc",
        },
      ],
      itemsLastChangeStatus: [
        {
          text: "Ascendente",
          value: "asc",
        },
        {
          text: "Decendente",
          value: "desc",
        },
      ],
      landscape: false,
      reactive: false,
      bMobile: false,
      sPromotionsType: "",
      aPromotionsType: []
    };
  },
  beforeMount() {
    this.getPromotionsType();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 959.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    saveDates: function () {
      this.$emit("setDates", this.rangoFechas);
    },
    getPromotionsType() {
      DB.get(`${URI}/promotions/types/`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
        },
      })
        .then((response) => {
          this.aPromotionsType = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    getEndDate() {
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        10
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  watch: {
    rangoFechas: function () {
      if (this.rangoFechas.length == 2) {
        if (this.rangoFechas[0] > this.rangoFechas[1]) {
          let fecha_inicial = this.rangoFechas[1];
          this.rangoFechas = [];
          this.rangoFechas.push(fecha_inicial);
        }
      }

      if (this.rangoFechas.length == 0) {
        this.$emit("setDates", []);
      }
    },
    sCreatedAt: function () {
      this.$emit("setCreatedAt", this.sCreatedAt);
    },
    sLastChangeStatus: function () {
      this.$emit("setLastChangeStatus", this.sLastChangeStatus);
    },
    sPromotionsType() {
      this.$emit("setPromotionsType", this.sPromotionsType)
    }
  },
};
</script>

<style scoped>
.content-search {
  display: flex;
  width: 100%;
}

.content-input-search {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}

.content-select-type-promotion {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  margin-left: 25px;
}

.content-input-date-range {
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  margin-left: 25px;
}

.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search {
    display: block;
    width: 100%;
  }

  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .content-select-type-promotion {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;

  }

  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-search {
    display: block;
    width: 100%;
  }

  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .content-select-type-promotion {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }

  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
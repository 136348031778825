<template>
  <v-dialog v-model="active" width="500px" persistent>
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="$emit('closeChangeStatus')" icon>
        <v-icon color="#000" size="16px">
          mdi-close
        </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins mb-0">{{ title }}</p>
      <!-- <div v-if="oChangeStatusOptions.bInputs && !oChangeStatusOptions.action" class="content-inputs">
        <v-select label="Desactivación" placeholder="Desactivación" dense color="#2759A2"
          class="global-auth-inputs poppins mb-8" persistent-placeholder :items="aDesactived" item-text="sName"
          item-value="sValue" @change="selectStates()" no-data-text="No hay datos disponibles"></v-select>

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="tDate"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="tDate" label="Seleccionar fecha" placeholder="seleccionar fecha" dense class="global-inputs poppins" readonly
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="tDate" no-title scrollable locale="es">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(tDate)">
              Confirmar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div> -->
      <!-- v-else -->
      <p class="content-card-txt-description poppins mt-2" v-html="description" />
      <div class="content-buttons">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-start">
                <v-btn class="button-secondary poppins" elevation="0" width="100%" @click="$emit('closeChangeStatus')">
                  Cancelar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn @click="send" :loading="bLoading" class="button-primary poppins" elevation="0" width="100%">
                  {{ sTextBtn }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    oChangeStatusOptions: Object,
  },
  data() {
    return {
      bLoading: false,
      active: false,
      title: "",
      description: "",
      sTextBtn: "",
      aDesactived: [{ sName: "Desactivar ahora", sValue: 1 }, { sName: "Programar fecha", sValue: 2 }],
      menu: false,
      tDate: ""
    };
  },
  methods: {
    send: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            },
              payload = this.oChangeStatusOptions.payload;
            switch (this.oChangeStatusOptions.iMethod) {
              case 1:
                DB.post(`${this.oChangeStatusOptions.api}`, payload, config)
                  .then((response) => {
                    this.bLoading = false;
                    this.$emit("closeChangeStatus");
                    this.mixSuccess(response.data.message);
                    this.$store.commit("refresher", true);
                  })
                  .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message);
                  });
                break;

              default:
                DB.patch(`${this.oChangeStatusOptions.api}`, payload, config)
                  .then((response) => {
                    this.bLoading = false;
                    this.$emit("closeChangeStatus");
                    this.mixSuccess(response.data.message);
                    this.$store.commit("refresher", true);
                  })
                  .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message);
                  });
                break;
            }
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$emit("closeChangeStatus");
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.$emit("closeChangeStatus");
          this.$store.commit("refresher", true);
        })
    },
  },
  watch: {
    oChangeStatusOptions: function () {
      this.active = this.oChangeStatusOptions.active;
      this.title = this.oChangeStatusOptions.title;
      this.description = this.oChangeStatusOptions.description;
      this.sTextBtn = this.oChangeStatusOptions.sTextBtn
    },
  },
};
</script>

<style >
.content-inputs {
  padding: 30px 10px 30px 10px !important;
}
</style>
<template>
  <div class="mt-4 content-permission mb-9">
    <div class="content-txt-title-section poppins">
      Permisos administrativos
    </div>
    <div class="content-card mt-4">
      <v-container
        class="pa-0"
        fluid
      >
        <!-- HTML HEADER  -->
        <v-row>
          <v-col
            class="pb-0"
            md="6"
          >
            <div>
              <p class="txt-table poppins mb-0">Nombre</p>
            </div>
          </v-col>
          <v-col
            class="pb-0"
            md="3"
          >
            <div>
              <p class="txt-table poppins mb-0">Visualizar</p>
            </div>
          </v-col>
          <v-col
            class="pb-0"
            md="3"
          >
            <div>
              <p class="txt-table poppins mb-0">Gestionar</p>
            </div>
          </v-col>
        </v-row>
        <!-- HTML LINE SEPARATOR -->
        <v-row>
          <v-col>
            <div class="border-line"></div>
          </v-col>
        </v-row>
        <!-- HTML PLANES  -->
        <v-row>
          <v-col
            class="pt-0"
            md="6"
          >
            <div class="display-flex align-items-center height-100">
              <p class="txt-table-item poppins mb-0">Planes</p>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="verPlanes"
                color="#2759a2"
                hide-details
              ></v-switch>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="administrarPlanes"
                @change="(administrarPlanes == true || verPlanes == true) ? verPlanes = true : verPlanes = false"
                color="#2759a2"
                hide-details
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <!-- HTML SOLICITUDES DE TARJETAS SIMS  -->
        <v-row>
          <v-col
            class="pt-0"
            md="6"
          >
            <div class="display-flex align-items-center height-100">
              <p class="txt-table-item poppins mb-0">Solicitudes de tarjetas SIM</p>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="verSolicitudes"
                color="#2759a2"
                hide-details
              ></v-switch>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="administrarSolicitudes"
                color="#2759a2"
                hide-details
                @change="(administrarSolicitudes == true || verSolicitudes == true) ? verSolicitudes = true : verSolicitudes = false"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <!-- HTML TARJETAS SIMS  -->
        <v-row>
          <v-col
            class="pt-0"
            md="6"
          >
            <div class="display-flex align-items-center height-100">
              <p class="txt-table-item poppins mb-0">Tarjetas SIM</p>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="verTarjetas"
                color="#2759a2"
                hide-details
              ></v-switch>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="administrarTarjetas"
                color="#2759a2"
                hide-details
                @change="(administrarTarjetas == true || verTarjetas == true) ? verTarjetas = true : verTarjetas = false"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <!-- HTML USUARIOS  -->
        <v-row>
          <v-col
            class="pt-0"
            md="6"
          >
            <div class="display-flex align-items-center height-100">
              <p class="txt-table-item poppins mb-0">Usuarios</p>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="verUsuarios"
                color="#2759a2"
                hide-details
              ></v-switch>
            </div>
          </v-col>
          <v-col
            class="pt-0"
            md="3"
          >
            <div style="width: fit-content;">
              <v-switch
                v-model="administrarUsuarios"
                color="#2759a2"
                hide-details
                @change="(administrarUsuarios == true || verUsuarios == true) ? verUsuarios = true : verUsuarios = false"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="!activeButton"
      class="content-buttons"
    >
      <v-btn
        elevation="0"
        class="button-secondary"
        @click="discardChanges"
      >
        Descartar cambios
      </v-btn>
      <v-btn
        elevation="0"
        class="button-primary"
        @click="setItems"
      >
        Guardar cambios
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailAdministratorsPermision",
  data() {
    return {
      items: [],
      aAdministratorPermissions: [],
      verPlanes: false,
      administrarPlanes: false,
      verSolicitudes: false,
      administrarSolicitudes: false,
      verTarjetas: false,
      administrarTarjetas: false,
      verUsuarios: false,
      administrarUsuarios: false,
      activeButton: true,
    };
  },
  beforeMount() {
    this.getPermissions();
  },
  methods: {
    discardChanges: function () {
      this.verPlanes = this.items[0].aPermissions[0].bBoolean;
      this.administrarPlanes = this.items[0].aPermissions[1].bBoolean;
      this.verSolicitudes = this.items[1].aPermissions[0].bBoolean;
      this.administrarSolicitudes = this.items[1].aPermissions[1].bBoolean;
      this.verTarjetas = this.items[2].aPermissions[0].bBoolean;
      this.administrarTarjetas = this.items[2].aPermissions[1].bBoolean;
      this.verUsuarios = this.items[3].aPermissions[0].bBoolean;
      this.administrarUsuarios = this.items[3].aPermissions[1].bBoolean;
    },
    validateChange: function () {
      this.activeButton =
        this.verPlanes == this.items[0].aPermissions[0].bBoolean &&
        this.administrarPlanes == this.items[0].aPermissions[1].bBoolean &&
        this.verSolicitudes == this.items[1].aPermissions[0].bBoolean &&
        this.administrarSolicitudes == this.items[1].aPermissions[1].bBoolean &&
        this.verTarjetas == this.items[2].aPermissions[0].bBoolean &&
        this.administrarTarjetas == this.items[2].aPermissions[1].bBoolean &&
        this.verUsuarios == this.items[3].aPermissions[0].bBoolean &&
        this.administrarUsuarios == this.items[3].aPermissions[1].bBoolean;
    },
    setItems: function () {
      this.aAdministratorPermissions = [
        {
          sAdministratorModuleId: "8b1aafee-c473-4faf-ace5-39611e067e6a",
          aPermissions: [
            {
              sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
              bBoolean: this.verPlanes,
            },
            {
              sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
              bBoolean: this.administrarPlanes,
            },
          ],
        },
        {
          sAdministratorModuleId: "83aab3ca-ea88-458a-9397-0ef3ef4733c4",
          aPermissions: [
            {
              sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
              bBoolean: this.verSolicitudes,
            },
            {
              sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
              bBoolean: this.administrarSolicitudes,
            },
          ],
        },
        {
          sAdministratorModuleId: "875d2935-3c13-453a-9aa9-2853a1cf98be",
          aPermissions: [
            {
              sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
              bBoolean: this.verTarjetas,
            },
            {
              sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
              bBoolean: this.administrarTarjetas,
            },
          ],
        },
        {
          sAdministratorModuleId: "e2881b27-5049-4750-9e16-8da2f3995222",
          aPermissions: [
            {
              sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
              bBoolean: this.verUsuarios,
            },
            {
              sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
              bBoolean: this.administrarUsuarios,
            },
          ],
        },
      ];
    },
    getPermissions: function () {
      DB.get(`${URI}/permissions/administrator/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.items = response.data.results;
          this.verPlanes = this.items[0].aPermissions[0].bBoolean;
          this.administrarPlanes = this.items[0].aPermissions[1].bBoolean;
          this.verSolicitudes = this.items[1].aPermissions[0].bBoolean;
          this.administrarSolicitudes = this.items[1].aPermissions[1].bBoolean;
          this.verTarjetas = this.items[2].aPermissions[0].bBoolean;
          this.administrarTarjetas = this.items[2].aPermissions[1].bBoolean;
          this.verUsuarios = this.items[3].aPermissions[0].bBoolean;
          this.administrarUsuarios = this.items[3].aPermissions[1].bBoolean;
        })
        .catch((error) => {
          this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  watch: {
    verPlanes: function () {
      this.validateChange();
    },
    administrarPlanes: function () {
      this.validateChange();
    },
    verSolicitudes: function () {
      this.validateChange();
    },
    administrarSolicitudes: function () {
      this.validateChange();
    },
    verTarjetas: function () {
      this.validateChange();
    },
    administrarTarjetas: function () {
      this.validateChange();
    },
    verUsuarios: function () {
      this.validateChange();
    },
    administrarUsuarios: function () {
      this.validateChange();
    },
  },
};
</script>

<style scoped>
.content-buttons {
  margin: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-buttons .button-secondary {
  margin-right: 10px;
}

.content-permission .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.border-line {
  border-bottom: 1px solid #a1acc3;
}

.txt-table {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.txt-table-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #3a79bd19;
  padding: 20px;
  border-radius: 10px;
}

.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .button-secondary,
  .button-primary {
    width: 100%;
  }

  .button-primary {
    margin: 20px 0px;
  }
}
</style>
<template>
  <div
    @click="$router.push({ name: 'promotions' })"
    class="content-header"
  >

    <p class="txt-title poppins mb-0">
      <v-icon
        class="icon"
        color="#283c4d"
      >
        mdi-arrow-left-thick
      </v-icon>
      <span class="txt-title-blue">{{ alias }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailPromotionsHeader",
  props: {
    items: {
      type: Object,
    },
  },
  beforeMount() {
    this.alias = this.items.sTitle;
  },
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-title-blue {
  color: #2759a2;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 500px) {
  .txt-title,
  .icon {
    font-size: 25px !important;
  }
}
</style>
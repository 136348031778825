<template>
  <div class="display-flex align-items-center">
    <p class="txt-title poppins mb-0">Promociones</p>
    <v-spacer />
    <v-btn
     v-if="getPermissionsActionsGlobal()"
      @click="addPromotions"
      class="button-primary poppins"
      elevation="0"
    >
      <span v-if="!responsive">+ Añadir promoción</span>
      <v-icon
        v-else
        color="#fff"
        size="18px"
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutClientsHeader",
  data() {
    return {
      responsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.responsive = false;
      } else {
        this.responsive = true;
      }
    },
    addPromotions: function () {
      this.$router.push({
        name: "addPromotions",
      });
    },
  },
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template>
    <div class="content-subtotal">
        <div class="information-title">
            Subtotales
        </div>
        <div class="card-subtotal">
            <!-- <div :class="item.sName !== 'Total' ? 'content-all-subtotal' : 'content-total'"
                v-for="(item, i) in oSubtotal" :key="i">
                <div :class="item.sName !== 'Total' ? 'content-left' : 'content-left-total'">
                    <span>{{ item.sName }}</span>
                </div>
                <v-spacer />
                <div :class="item.sName !== 'Total' ? 'content-right' : 'content-right-total'">
                    <span>${{ formatMoneyGlobal(item.iAmount) }} MXN</span>
                </div>
            </div> -->
            <div class="content-all-subtotal">
                <div class="content-left">
                    <span>Consumo</span>
                </div>
                <v-spacer />
                <div class="content-right">
                    <!-- <span>${{ formatMoneyGlobal(item.iAmount) }} MXN</span> -->
                    <span>${{ formatMoneyGlobal(oSubtotal.iItemsTotal) }} MXN</span>
                </div>
            </div>
            <div class="content-all-subtotal">
                <div class="content-left">
                    <span>Productos adicionales</span>
                </div>
                <v-spacer />
                <div class="content-right">
                    <!-- <span>${{ formatMoneyGlobal(item.iAmount) }} MXN</span> -->
                    <span>${{ formatMoneyGlobal(oSubtotal.iProductsTotal) }} MXN</span>
                </div>
            </div>
            <div class="content-all-subtotal">
                <div class="content-left">
                    <span>Descuentos</span>
                </div>
                <v-spacer />
                <div class="content-right">
                    <!-- <span>${{ formatMoneyGlobal(item.iAmount) }} MXN</span> -->
                    <span>${{ formatMoneyGlobal(oSubtotal.iDiscountsTotal) }} MXN</span>
                </div>
            </div>
            <div class="content-total">
                <div class="content-left-total">
                    <span>TOTAL</span>
                </div>
                <v-spacer />
                <div class="content-right-total">
                    <!-- <span>${{ formatMoneyGlobal(item.iAmount) }} MXN</span> -->
                    <span>${{ formatMoneyGlobal(oSubtotal.iTotalAmount) }} MXN</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        oSubtotal: Object
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 170px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.card-subtotal {
    height: 250px;
    width: 400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.content-all-subtotal {
    display: flex;
    padding: 18px 25px 18px 25px;
}

.content-total {
    display: flex;
    padding: 20px 20px 20px 20px;
    margin: 5px;
    border-radius: 15px;
    background-color: #D8E5FF;

}

.content-left {
    font-size: 13px;
    font-weight: bold;
}

.content-right {
    font-size: 13px;
}

.content-left-total {
    font-size: 15px;
    font-weight: bold;
}

.content-right-total {
    font-size: 15px;

}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .card-subtotal {
        height: 300px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }


    .card-subtotal {
        height: 300px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
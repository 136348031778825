<template>
  <div>
    <layout-promotions-header @setDialogAdd="setDialogAdd" />
    <layout-promotions-searchbar @setSearch="setSearch" @setCreatedAt="setCreatedAt"
      @setLastChangeStatus="setLastChangeStatus" @setPromotionsType="setPromotionsType" />
    <layout-promotions-content :sSearch="sSearch" :sCreatedAt="sCreatedAt" @setOptions="setOptions"
      :sLastChangeStatus="sLastChangeStatus" :sPromotionsType="sPromotionsType" />
    <delete-component :oOptions="oOptions" @setOptions="setOptions" />
  </div>
</template>

<script>
import LayoutPromotionsContent from "@/layouts/Promotions/Content.vue";
import LayoutPromotionsSearchbar from "@/layouts/Promotions/Searchbar.vue";
import LayoutPromotionsHeader from "@/layouts/Promotions/Header.vue";

export default {
  name: "PromotionsView",
  data() {
    return {
      sSearch: "",
      sCreatedAt: undefined,
      bDialogAdd: false,
      oOptions: {},
      clientId: "",
      sLastChangeStatus: undefined,
      sPromotionsType: ""
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setCreatedAt: function (val) {
      this.sCreatedAt = val;
    },
    setLastChangeStatus: function (val) {
      this.sLastChangeStatus = val;
    },
    setPromotionsType(val) {
      this.sPromotionsType = val;
    }
  },
  components: {
    LayoutPromotionsContent,
    LayoutPromotionsSearchbar,
    LayoutPromotionsHeader,
  },
};
</script>
<template>
  <div>
    <div v-if="Object.keys(items).length > 0">
      <layout-detail-administrators-header :items="items" />
      <layout-detail-administrators-form :oPermissions="oPermissions" :items="items" @setnombre="setnombre"
        @setapellido="setapellido" @setcorreo="setcorreo" @setpuesto="setpuesto" @setcodigoPais="setcodigoPais"
        @setcodigoArea="setcodigoArea" @setnumeroTelefono="setnumeroTelefono" @setextension="setextension"
        @setActiveButtons="setActiveButtons" />
      <layout-detail-administrators-access  :items="items" />
      <permissions-component-global @setModifyPermissions="setModifyPermissions"
        @setObjectPermissions="setObjectPermissions" :items="items" :bGetPermissions="false" />
      <layout-detail-administrators-buttons @setOptions="setOptions"  :oPermissions="oPermissions" :itemsOriginal="items" :nombre="nombre"
        :apellido="apellido" :correo="correo" :puesto="puesto" :codigoPais="codigoPais" :codigoArea="codigoArea"
        :numeroTelefono="numeroTelefono" :extension="extension" :activeButtons="activeButtons"
        :bModifyPermissions="bModifyPermissions" />
      <delete-component :oOptions="oOptions" @setOptions="setOptions" />
      <!-- <layout-detail-administrators-permission /> -->
    </div>
    <!-- 
    <layout-detail-client-tabs :items="items" /> -->
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoadingAdministrators">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutDetailAdministratorsHeader from "@/layouts/DetailAdministrators/Header.vue";
import LayoutDetailAdministratorsForm from "@/layouts/DetailAdministrators/Form.vue";
import LayoutDetailAdministratorsAccess from "@/layouts/DetailAdministrators/Access.vue";
import LayoutDetailAdministratorsPermission from "@/layouts/DetailAdministrators/Permission.vue";
import LayoutDetailAdministratorsButtons from "../layouts/DetailAdministrators/Buttons.vue";

export default {
  name: "DetailAdministratorsView",
  data() {
    return {
      bLoadingAdministrators: false,
      bModifyPermissions: false,
      aliasName: "",
      items: {},
      oOptions: {},
      activeButtons: false,
      oPermissions: {},
      nombre: "",
      apellido: "",
      correo: "",
      puesto: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      oParams: {}, 
    };
  },
  beforeMount() {
    this.administratorId = this.$route.params.id;
    this.getData();
    this.setOptions();
  },
  methods: {
    setnombre: function (nombre) {
      this.nombre = nombre
    },
    setapellido: function (apellido) {
      this.apellido = apellido
    },
    setcorreo: function (correo) {
      this.correo = correo
    },
    setpuesto: function (puesto) {
      this.puesto = puesto
    },
    setcodigoPais: function (codigoPais) {
      this.codigoPais = codigoPais
    },
    setcodigoArea: function (codigoArea) {
      this.codigoArea = codigoArea
    },
    setnumeroTelefono: function (numeroTelefono) {
      this.numeroTelefono = numeroTelefono
    },
    setextension: function (extension) {
      this.extension = extension
    },
    setObjectPermissions: function (oPermissions) {
      this.oPermissions = oPermissions
    },
    setActiveButtons: function (activeButtons) {
      this.activeButtons = activeButtons
    },
    setModifyPermissions(bModifyPermissions) {
      this.bModifyPermissions = bModifyPermissions
    },
    getData: function () {
      this.items = {};
      this.bLoadingAdministrators = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/administrators/${this.administratorId}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              this.bLoadingAdministrators = false;
              this.items = response.data.results;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingAdministrators = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
    bShowAdministrator() {
      return this.$store.state.bShowAdministrator;
    },
    bAdminAdministrator() {
      return this.$store.state.bAdminAdministrator;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
  },
  components: {
    LayoutDetailAdministratorsHeader,
    LayoutDetailAdministratorsForm,
    LayoutDetailAdministratorsAccess,
    LayoutDetailAdministratorsPermission,
    LayoutDetailAdministratorsButtons
  },
};
</script>
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sToken: localStorage.getItem("sToken")
      ? localStorage.getItem("sToken")
      : "", // Global alerts
    sUserId: "",
    sStatus: "",
    sFullName: "",
    sName: "",
    sLastname: "",
    bManager: false,
    aPermissions: null,

    aMenuGlobal: [
      {
        title: "Principal",
        link: "inicio",
        icon: "home",
        bManager: false,
        sAdministratorModuleId: "1",
      },
      {
        title: "Solicitudes de tarjetas SIM",
        link: "sims",
        icon: "add_circle_outline",
        bManager: false,
        sAdministratorModuleId: "83aab3ca-ea88-458a-9397-0ef3ef4733c4",
      },
      {
        title: "Administradores",
        link: "administrators",
        icon: "shield",
        bManager: false,
        sAdministratorModuleId: "e2881b27-5049-4750-9e16-8da2f3995222",
      },
      {
        title: "Clientes",
        link: "clients",
        icon: "people_outline",
        bManager: false,
        sAdministratorModuleId: "042c5820-1d6d-469e-9b9e-c2ecc3aeee92",
      },
      {
        title: "Tarjetas SIM",
        link: "sim-cards",
        icon: "sim_card",
        bManager: false,
        sAdministratorModuleId: "875d2935-3c13-453a-9aa9-2853a1cf98be",
      },
      {
        title: "Promociones",
        link: "promotions",
        icon: "discount",
        bManager: false,
        sAdministratorModuleId: "75ac765f-be48-4727-a11a-76d180d5d7c1",
      },
      {
        title: "Auditorías",
        link: "audit",
        icon: "fact_check",
        bManager: false,
        sAdministratorModuleId: "11e4a801-d1fc-4d4b-89e8-b4cf136bb9c8",
      },
      {
        title: "Prefacturación",
        link: "prebilling",
        icon: "receipt",
        bManager: false,
        sAdministratorModuleId: "4f33196a-791c-4f0b-b54a-bf70e8002bc1",
      },
    ],

    bShowSIMCardsSolicitud: false,
    bAdminSIMCardsSolicitud: false,

    bShowPlan: false,
    bAdminPlan: false,

    bShowAdministrators: false,
    bAdminAdministrators: false,

    bShowCustomers: false,
    bAdminCustomers: false,

    bShowSIMCards: false,
    bAdminSIMCards: false,

    bShowPromotions: false,
    bAdminPromotions: false,

    bShowAudit: false,
    bAdminAudit: false,

    bShowPrebilling: false,
    bAdminPrebilling: false,

    bShowDashboard: false,
    bAdminDashboard: false,

    sMessageErrorAccess: "Acesso denegado",

    bMenu: false,
    bMenuResponsive: false,
    refresh: false,
    bDialogSendSms: false,
    bDialogActivatedSims: false,
    bDialogDesactivatedSims: false,
    bDialogResetSims: false,
    bDialogLaunchTest: false,

    iCurrentPageGlobal: 1,
    sFilterTypeGlobal: null,
    sSearchGlobal: "",
    sTextEmpty: "Sin información",
    aNotificationGlobal: [],

    bGoToTop: false,
  },
  mutations: {
    setToken(state, value) {
      state.sToken = value;
    },
    setUserId(state, value) {
      state.sUserId = value;
    },
    setFullName(state, value) {
      // state.sFullName = value;
      state.sName = value.sName;
      state.sLastname = value.sLastname;
    },
    setStatus(state, value) {
      state.sStatus = value;
    },
    setManager(state, value) {
      state.bManager = value;
    },
    setMenu(state, value) {
      state.bMenu = value;
    },
    setMenuResponsive(state, value) {
      state.bMenuResponsive = value;
    },
    refresher(state, item) {
      state.refresh = item;
    },
    setDialogSendSms(state, value) {
      state.bDialogSendSms = value;
    },
    setDialogActivatedSims(state, value) {
      state.bDialogActivatedSims = value;
    },
    setDialogDesactivatedSims(state, value) {
      state.bDialogDesactivatedSims = value;
    },
    setDialogResetSims(state, value) {
      state.bDialogResetSims = value;
    },
    setDialogLaunchTest(state, value) {
      state.bDialogLaunchTest = value;
    },
    setCurrentPageGlobal(state, value) {
      state.iCurrentPageGlobal = value;
    },
    setFilterTypeGlobal(state, value) {
      state.sFilterTypeGlobal = value;
    },
    setSearchGlobal(state, value) {
      state.sSearchGlobal = value;
    },
    setNotificationGlobal(state, value) {
      state.aNotificationGlobal = value;
    },

    setGoToTop(state, value) {
      state.bGoToTop = value;
    },

    // SET PERMISSIONS IN MENU
    setPermissions(state, item) {
      state.aPermissions = item.aUserPermissions;
    },
    setPermissionSIMCardsSolicitud(state, item) {
      state.bShowSIMCardsSolicitud = item.bShow;
      state.bAdminSIMCardsSolicitud = item.bAdmin;
    },
    setPermissionPlan(state, item) {
      state.bShowPlan = item.bShow;
      state.bAdminPlan = item.bAdmin;
    },
    setPermissionSIMCards(state, item) {
      state.bShowSIMCards = item.bShow;
      state.bAdminSIMCards = item.bAdmin;
    },
    setPermissionAdministrators(state, item) {
      state.bShowAdministrators = item.bShow;
      state.bAdminAdministrators = item.bAdmin;
    },
    setPermissionCustomers(state, item) {
      state.bShowCustomers = item.bShow;
      state.bAdminCustomers = item.bAdmin;
    },
    setPermissionPromotions(state, item) {
      state.bShowPromotions = item.bShow;
      state.bAdminPromotions = item.bAdmin;
    },
    setPermissionAudit(state, item) {
      state.bShowAudit = item.bShow;
      state.bAdminAudit = item.bAdmin;
    },
    setPermissionPrebilling(state, item) {
      state.bShowPrebilling = item.bShow;
      state.bAdminPrebilling = item.bAdmin;
    },
    setPermissionDashboard(state, item) {
      state.bShowDashboard = item.bShow;
      state.bAdminDashboard = item.bAdmin;
    },
  },
  actions: {
    getPermissionsByUserGlobal({ dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(`${URI}/administrators/${state.sUserId}/profile`, {
          headers: {
            Authorization: "Bearer " + state.sToken,
          },
        })
          .then((response) => {
            if (response.data.results.aAdministratorPermissions !== null) {
              response.data.results.aAdministratorPermissions.push(
                {
                  sAdministratorModuleId: "1",
                  sModuleName: "Inicio",
                  aPermissions: [
                    {
                      sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
                      sPermissionName: "Ver",
                      bBoolean: false,
                    },
                    {
                      sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
                      sPermissionName: "Administrar",
                      bBoolean: false,
                    },
                  ],
                },
                {
                  sAdministratorModuleId: "4",
                  sModuleName: "Perfil",
                  aPermissions: [
                    {
                      sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                      sPermissionName: "Ver",
                      bBoolean: true,
                    },
                    {
                      sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                      sPermissionName: "Administrar",
                      bBoolean: true,
                    },
                  ],
                },
                {
                  sAdministratorModuleId: "5",
                  sModuleName: "Notificaciones",
                  aPermissions: [
                    {
                      sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                      sPermissionName: "Ver",
                      bBoolean: true,
                    },
                    {
                      sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                      sPermissionName: "Administrar",
                      bBoolean: true,
                    },
                  ],
                }
              );
            }
            let aPermissions = response.data.results.aAdministratorPermissions;
            commit("setPermissions", {
              aUserPermissions: aPermissions,
            });

            dispatch("setPermissionsBtnGlobal", aPermissions);
            resolve(aPermissions);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    setPermissionsBtnGlobal({ commit, state }, aPermissions) {
      return new Promise((resolve, reject) => {
        try {
          let bShow = false;
          let bAdmin = false;
          if (aPermissions !== null) {
            commit("setPermissionSIMCardsSolicitud", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionPlan", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionSIMCards", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionAdministrators", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionCustomers", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionPromotions", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionAudit", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionPrebilling", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionDashboard", {
              bShow: false,
              bAdmin: false,
            });
            aPermissions.forEach((element) => {
              bShow = false;
              bAdmin = false;

              //#region SOLICITUDES DE TARJETAS SIM//
              if (
                element.sAdministratorModuleId ==
                "83aab3ca-ea88-458a-9397-0ef3ef4733c4"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionSIMCardsSolicitud", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion SOLICITUDES DE TARJETAS SIM//

              //#region PLANES//
              if (
                element.sAdministratorModuleId ==
                "8b1aafee-c473-4faf-ace5-39611e067e6a"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionPlan", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion PLANES//

              //#region ADMINISTRADORES//
              if (
                element.sAdministratorModuleId ==
                "e2881b27-5049-4750-9e16-8da2f3995222"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAdministrators", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion ADMINISTRADORES//

              //#region CLIENTE //
              if (
                element.sAdministratorModuleId ==
                "042c5820-1d6d-469e-9b9e-c2ecc3aeee92"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionCustomers", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion CLIENTE //

              //#region TARJETA SIM//
              if (
                element.sAdministratorModuleId ==
                "875d2935-3c13-453a-9aa9-2853a1cf98be"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionSIMCards", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }

              //#endregion TARJETA SIM//

              //#region PROMOCIONES CLIENTE//
              if (
                element.sAdministratorModuleId ==
                "75ac765f-be48-4727-a11a-76d180d5d7c1"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //#region VER//
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //#endregion VER//
                  //#region ADMINISTRAR //
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                  //#endregion ADMINISTRAR //
                });
                commit("setPermissionPromotions", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }

              //#endregion PROMOCIONES CLIENTE//

              //#region AUDITORÍAS//
              if (
                element.sAdministratorModuleId ==
                "11e4a801-d1fc-4d4b-89e8-b4cf136bb9c8"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAudit", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion AUDITORÍAS//

              //#region PRE FACTURACIÓN //
              if (
                element.sAdministratorModuleId ==
                "4f33196a-791c-4f0b-b54a-bf70e8002bc1"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionPrebilling", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion PRE FACTURACIÓN //

              //#region DASHBOARD //
              if (
                element.sAdministratorModuleId ==
                "6be24029-d386-4117-a2ce-1ae9f54763bd"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionDashboard", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion DASHBOARD //
            });
          } else {
            commit("setPermissionSIMCardsSolicitud", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionPlan", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionSIMCards", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAdministrators", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionCustomers", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionPromotions", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAudit", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionPrebilling", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionDashboard", {
              bShow: true,
              bAdmin: true,
            });
          }
          resolve("success");
        } catch (error) {
          reject(error);
        }
      });
    },
    //#region NOTIFICATIONS GLOBALS//
    getNotificationGlobal({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          DB.get(`${URI}/notifications`, {
            headers: {
              Authorization: `Bearer ${state.sToken}`,
            },
            params: {
              // iPageNumber: this.iCurrentPage,
              // sSearch: this.sSearch,
              // iItemsPerPage: this.iItemsPerPage,
              // sNameOrder: this.sNameOrder,
              // bPlatformAccess: this.bPlatformAccess,
            },
          })
            .then((response) => {
              commit("setNotificationGlobal", response.data.results);
              resolve("success");
            })
            .catch((error) => {
              reject(error.response.data.message);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    setNotificationReadGlobal({ dispatch, state }, item) {
      return new Promise((resolve, reject) => {
        try {
          DB.get(`${URI}/notifications/${item.sNotificationId}`, {
            headers: {
              Authorization: `Bearer ${state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              dispatch("getNotificationGlobal")
                .then((resp) => {
                  // this.$router.push({ name: item.sCode, id: item.sCustomId })
                  if (
                    router.name === item.sCode &&
                    route.params.id === item.sCustomId
                  ) {
                    router.go();
                  } else {
                    router
                      .push({
                        name: item.sCode,
                        params: {
                          id: item.sCustomId,
                        },
                      })
                      .catch(() => {});
                    router.go();
                  }
                  resolve(item);
                })
                .catch((err) => {});
              resolve("success");
            })
            .catch((error) => {
              console.log(error.response.data.message, error.response.status);
            });
          resolve("Success");
        } catch (error) {
          reject(error);
        }
      });
    },

    //#endregion NOTIFICATIONS GLOBALS//

  },
  modules: {},
  plugins: [PersistedState()],
});

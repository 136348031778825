<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" sm="3">
          <div>
            <v-select
              v-model="codigoPais"
              :items="items"
              :hide-details="false"
              dense
              label="Código de país"
              class="global-inputs poppins"
              background-color="transparent"
              persistent-placeholder
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template v-slot:selection="{ item }">
                <img width="12px" :src="item.img" />
                <span class="txt-item-selected ml-1">{{ item.text }}</span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <img width="12px" :src="item.img" />
                      <span class="txt-item-selected ml-1">{{
                        item.text
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="label">
                <span
                  >Código de país
                  <span v-if="bObligatoryField" class="color-red">*</span></span
                >
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <v-text-field
              v-model="codigoArea"
              label="Código de área"
              placeholder="00"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="4"
              persistent-placeholder
              @keypress="soloNumbers"
              @input="nextInput"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span
                  >Código de área
                  <span v-if="bObligatoryField" class="color-red">*</span></span
                >
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <v-text-field
              v-model="numeroTelefono"
              ref="numeroTelefono"
              label="Número de teléfono"
              placeholder="0000 0000"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="8"
              persistent-placeholder
              @keypress="soloNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span
                  >Número de teléfono
                  <span v-if="bObligatoryField" class="color-red">*</span></span
                >
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <v-text-field
              v-model="extension"
              label="Extensión"
              placeholder="0000"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="6"
              persistent-placeholder
              @keypress="soloNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "CountryCodeInputComponent",
  props: {
    _bDialogAdd: Boolean,
    _codigoPais: String,
    _codigoArea: String,
    _numeroTelefono: String,
    _extension: String,
    _type: Boolean, // false-> Agregar -------- true -> Editar
    bObligatoryField: Boolean,
  },
  data() {
    return {
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      items: [
        {
          text: "+52",
          value: "52",
          img: `${require("@/assets/images/mx.png")}`,
        },
        {
          text: "+593",
          value: "593",
          img: `${require("@/assets/images/ecuador.png")}`,
        },
        {
          text: "+1",
          value: "1",
          img: `${require("@/assets/images/us.png")}`,
        },
      ],
    };
  },
  beforeMount() {
    if (this._type) {
      this.$nextTick((e) => {
        this.codigoPais = this._codigoPais;
        this.codigoArea = this._codigoArea;
        this.numeroTelefono = this._numeroTelefono;
        this.extension = this._extension;
        this.$emit("setCodigoPais", this.codigoPais);
        this.$emit("setCodigoArea", this.codigoArea);
        this.$emit("setNumeroTelefono", this.numeroTelefono);
        this.$emit("setExtension", this.extension);
      });
    }
  },
  methods: {
    nextInput: function (e) {
      if (e.length == 3) this.$refs.numeroTelefono.focus();
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
  watch: {
    codigoPais: function (e) {
      this.$emit("setCodigoPais", e);
    },
    codigoArea: function (e) {
      this.$emit("setCodigoArea", e);
    },
    numeroTelefono: function (e) {
      this.$emit("setNumeroTelefono", e);
    },
    extension: function (e) {
      this.$emit("setExtension", e);
    },
    _bDialogAdd: function () {
      if (!this._bDialogAdd) {
        this.codigoPais = "52";
        this.codigoArea = "";
        this.numeroTelefono = "";
        this.extension = "";
      }
    },
  },
};
</script>

<style scoped>
.txt-item-selected {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
</style>

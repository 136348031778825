<template>
  <div>
    <layout-audit-header @setDialogAdd="setDialogAdd" />
    <layout-audit-searchbar
      @setSearch="setSearch"
      @setRegistredAction="setRegistredAction"
      @setUserType="setUserType"
      @setDates="setDates"
    />
    <layout-audit-content
      :sSearch="sSearch"
      :sRegistredAction="sRegistredAction"
      :sUserType="sUserType"
      @setOptions="setOptions"
      :arrDates="arrDates"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutAuditContent from "@/layouts/Audit/Content.vue";
import LayoutAuditSearchbar from "@/layouts/Audit/Searchbar.vue";
import LayoutAuditHeader from "@/layouts/Audit/Header.vue";

export default {
  name: "AuditView",
  data() {
    return {
      sSearch: "",
      sRegistredAction: null,
      sUserType: null,
      bDialogAdd: false,
      oOptions: {},
      clientId: "",
      arrDates: [],
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setRegistredAction: function (val) {
      this.sRegistredAction = val;
    },
    setUserType: function (val) {
      this.sUserType = val;
    },
    setDates: function (val) {
      this.arrDates = val;
    },
  },
  components: {
    LayoutAuditContent,
    LayoutAuditSearchbar,
    LayoutAuditHeader,
  },
};
</script>
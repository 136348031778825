import Vue from "vue";

/** global Components **/
Vue.component("DeleteComponent", () => import("@/components/Delete.vue"));
Vue.component("EmptyContentComponent", () =>
  import("@/components/EmptyContent.vue")
);
Vue.component("InputCurrencyGlobal", () =>
  import("../components/InputCurrencyGlobal")
);
Vue.component("DialogConfirmGlobal", () =>
  import("../components/DialogConfirmGlobal")
);
Vue.component("PermissionsComponentGlobal", () => import("@/components/Permissions.vue"));

Vue.component('ConfirmResetSim', () => import('@/components/ConfirmResetSIM.vue'));


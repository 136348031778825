<template>
  <div>
    <div class="content-txt-title-section poppins">
      Estado actual de la solicitud
    </div>
    <div v-if="!bMobile">
      <div class="mt-5 mb-7 content-status">
        <div>
          <p class="txt-title-def poppins mb-0">Estado actual</p>
        </div>
        <v-spacer></v-spacer>
        <div :class="bMobile ? 'mb-3' : ''">
          <v-chip :color="getColorStatus(itemsSims).sColorBackground"
            :text-color="getColorStatus(itemsSims).sColorText">
            {{ sTextRequestStatus }}
          </v-chip>
        </div>
        <v-spacer v-show="bAdminSIMCardsSolicitud" v-if="
         sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
          sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
        "></v-spacer>

        <v-btn v-show="bAdminSIMCardsSolicitud" class="button-primary poppins" elevation="0"
          @click="setToogleDialog(true)" v-if="
           sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
            sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
          ">
          Modificar estado
        </v-btn>
      </div>
      <div class="border-line" />
    </div>
    <div v-else>
      <div class="mt-5 mb-7 content-status">
        <v-row>
          <v-col cols="6">
            <div>
              <p class="txt-title-def poppins mb-0">Estado actual</p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="mb-3 status">
              <v-chip :color="getColorStatus(itemsSims).sColorBackground"
                :text-color="getColorStatus(itemsSims).sColorText">
                {{ sTextRequestStatus }}
              </v-chip>
            </div>
          </v-col>
          <v-spacer v-show="bAdminSIMCardsSolicitud" v-if="
           sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
            sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
          "></v-spacer>
          <v-col cols="12">
            <v-btn v-show="bAdminSIMCardsSolicitud" class="button-primary poppins btn-state" elevation="0"
              @click="setToogleDialog(true)" v-if="
               sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
                sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
              ">
              Modificar estado
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="border-line" />
    </div>
    <layout-detail-sims-change-status :bDialog="bDialog" @setToogleDialog="setToogleDialog" :itemsSims="itemsSims" />
  </div>
</template>

<script>
import LayoutDetailSimsChangeStatus from "@/layouts/DetailSims/ChangeStatus.vue";

export default {
  name: "LayoutDetailSimsAcceptRequest",
  props: {
    itemsSims: {
      type: Object,
    },
  },
  data() {
    return {
      sTextRequestStatus: "",
      sColorRequestStatus: "",
      sBackgroundColorRequestStatus: "",
      sCurrentStatus: "",
      bDialog: false,
      bMobile: false
    };
  },
  beforeMount() {
    this.$store.dispatch("getPermissionsByUserGlobal");
    this.sTextRequestStatus = this.itemsSims.SIMRequestStatusRecord[0].sName;
    this.sBackgroundColorRequestStatus =
      this.itemsSims.SIMRequestStatusRecord[0].sStatusBackgroundColor;
    this.sColorRequestStatus =
      this.itemsSims.SIMRequestStatusRecord[0].sStatusTextColor;
    this.sCurrentStatus =
      this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId;
  },
  created() {
    window.addEventListener("resize", this.hanldeResize);
    this.hanldeResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.hanldeResize);
  },
  methods: {
    hanldeResize: function () {
      if (window.innerWidth > 599.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    setToogleDialog: function (val) {
      this.bDialog = val;
    },
    getColorStatus(sStatus) {
      let oColors = {};
      switch (sStatus.SIMRequestStatusRecord[0].sSIMRequestStatusId) {
        case "7f595ba0-3275-435f-a48e-4dfaa811702a": //Solicitud enviada
          oColors.sColorText = "#2C9DCE"
          oColors.sColorBackground = "#C0E2F0"
          return oColors;
        case "cd495340-fa3b-4234-89cd-b56c59db318d": //Evaluando
          oColors.sColorText = "#E7C127"
          oColors.sColorBackground = "#F8EDBE"

          return oColors;
        case "5834ff8c-0c17-42ee-afd1-5a84163363e8": //Confirmado
          oColors.sColorText = "#8E4DFF"
          oColors.sColorBackground = "#DDCAFD"
          return oColors;
        case "2a94cfb4-b4f1-420d-a513-c3a368946a48": //Enviado
          oColors.sColorText = "#68BBDF"
          oColors.sColorBackground = "#D2EBF6"

          return oColors;
        case "8ee1565e-ee98-462d-a445-b1d7248dbaed": //Entregado
          oColors.sColorText = "#1EA966"
          oColors.sColorBackground = "#BCE5D1"

          return oColors;
        case "875c3c08-cf5a-424b-988b-e724d33e4e8b": //Cancelado
          oColors.sColorText = "#D77580"
          oColors.sColorBackground = "#F3D6D9"

          return oColors;
        case "e6ea266d-ed35-40f4-9f06-98cffb846c9b": //Reposición
          oColors.sColorText = "#424657"
          oColors.sColorBackground = "#C6C8CD"

          return oColors;

        default:
          break;
      }
    }
  },
  computed: {
    bAdminSIMCardsSolicitud() {
      return this.$store.state.bAdminSIMCardsSolicitud
    }
  },
  components: {
    LayoutDetailSimsChangeStatus,
  },
};
</script>

<style scoped>
.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

.content-status {
  display: flex;
  align-items: center;
}

.content-status-mobile {
  text-align-last: center;
}

.border-line {
  border-top: 1px solid #a1acc3;
}

.content-accept-request {
  margin-top: 60px;
}

.txt-title-section {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  margin-bottom: 0;
}

.txt-title-def {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.content-txt-status {
  width: fit-content;
  padding: 2px 15px;
  border-radius: 20px;
}

.txt-status {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0;
}

.content-txt-estado-actual {
  display: flex;
  align-items: center;
  height: 100%;
}

.content-nombre-estado {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content-botones {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media (min-width: 600px) and (max-width: 960px) {
  .content-nombre-estado {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .txt-title-def {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 600;
  }
.status span.v-chip{
  width: 100%;
  justify-content: center;
}
  .btn-state {
    width: 100%;
  }

  .content-nombre-estado,
  .content-botones {
    justify-content: center;
  }

  .content-botones .button-primary,
  .content-botones .button-secondary {
    width: 100%;
  }
}
</style>
<template>
  <div class="mt-4 mb-9">
    <div class="content-label-txt-title poppins">Planes asignados</div>
    <div class="mt-7">
      <div v-if="plans.length > 0">
        <!-- cards content  -->
        <div class="mt-4">
          <v-container class="pa-0" fluid>
            <v-row>
              <!-- {{plans}} -->
              <v-col v-for="(item, index) in plans" :key="index" cols="12" sm="6" md="4">
                <div class="card-content">
                  <p class="card-content-txt-title poppins mb-1">
                    {{ item.sName }}
                  </p>
                  <p class="card-content-txt-description poppins mb-0" :style="{ height: `${heightText}px` }">
                      {{ item.sDescription }}
                  </p>
                  <div  class="content-buttons mt-3">
                    <v-btn v-if="getPermissionsActionsGlobal()" @click="deletePlan(item)" style="border-radius: 10px !important"
                      class="button-secondary-delete poppins" elevation="0">
                      Eliminar
                    </v-btn>
                    <v-spacer />
                    <v-btn @click="editPlan(item)" style="border-radius: 10px !important" class="button-primary poppins"
                      elevation="0">
                      {{getPermissionsActionsGlobal()? 'Modificar':'Ver'}} 
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination content  -->
        <div class="display-flex align-items-center justify-content-center mt-9">
          <div class="pagination-styles">
            <v-pagination v-model="iCurrentPage" :length="lengthPage" color="#2759A2" next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"></v-pagination>
          </div>
        </div>
      </div>
      <!-- empty content  -->
      <empty-content-component v-else text="Aún no hay planes asignados para mostrar." height="100" />
    </div>
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "LayoutDetailClientCustomPlans",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      bLoading: false,
      plans: [],
      lengthPage: 0,
      iCurrentPage: 1,
      iItemsPerPage: 6,
      sSearch: "",
      heightText: "",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    deletePlan: function (item) {
      let options = {
        active: true,
        id: item.sPlanId,
        api: `/enterprises/${item.sEnterpriseId}/custom-plans/${item.sCustomPlanId}`,
        title: "Eliminar plan asignado",
        description:
          "¿Estás seguro de eliminar el plan asignado? <br/> Al realizar esta <br/> acción no podrá revertirse",
      };
      this.$emit("setOptions", options);
    },
    editPlan: function (item) {
      this.$emit("setAsignPlanEdit", {
        activeDialogEdit: true,
        objDialogEdit: item,
      });
    },
    getData: function () {
      this.bLoading = true;

      DB.get(`${URI}/enterprises/${this.$route.params.id}/custom-plans`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.plans = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    resizeDivText: function (text) {
      var _text = document.getElementsByClassName(
        "card-content-txt-description"
      );
      let tam = [];
      for (let i = 0; i < _text.length; i++) {
        const element = _text[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.heightText = max;
      return text;
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    sSearch: lodash.debounce(function () {
      this.getData();
    }, 500),
    iCurrentPage: function () {
      this.getData();
    },
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #3a79bd19;
  padding: 10px;
  border-radius: 10px;
}

.card-content-txt-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.card-content-txt-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 100%;
  /* two lines  */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  background: #fff;
}

.content-label-txt-title {
  background: #d2e4fe 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 2px 15px;
  width: fit-content;
}

.border-top-line {
  border-top: 1px solid #d0dbf2;
}

.border-bottom-line {
  border-bottom: 1px solid #d0dbf2;
}
</style>
<template>
  <div
    @click="$router.push({ name: 'administrators' })"
    class="content-header"
  >
    <p class="txt-title poppins mb-0">
      <v-icon color="#000">
        mdi-arrow-left-thick
      </v-icon>
      Detalle de administrador: <span class="txt-title-blue">{{ sName }} {{ sLastname }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailAdministratorsHeader",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      sName: "",
      sLastname: "",
    };
  },
  beforeMount() {
    this.sName = this.items.sName;
    this.sLastname = this.items.sLastname;
  },
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 600;
}

.txt-title-blue {
  color: #2759a2;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 20px;
  }
}
</style>
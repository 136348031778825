<template>
  <div>
    <layout-add-clients-header />
    <div class="separator mt-4 mb-7" />
    <layout-add-clients-form />
  </div>
</template>

<script>
import LayoutAddClientsHeader from "@/layouts/AddClients/Header.vue";
import LayoutAddClientsForm from "@/layouts/AddClients/Form.vue";

export default {
  name: "AddClientsView",
  data() {
    return {};
  },
  methods: {},
  components: {
    LayoutAddClientsHeader,
    LayoutAddClientsForm,
  },
};
</script>
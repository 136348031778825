<template>
  <div class="content-header">

    <p @click="$router.push({ name: 'prebilling' })" class="txt-title poppins mb-0">
      <v-icon class="icon" color="#283c4d">
        mdi-arrow-left-thick
      </v-icon>Prefactura
      <span class="txt-title-blue">{{ alias }}</span>
    </p>
    <v-spacer />
    <v-btn v-if="getPermissionsActionsGlobal()" elevation="0" :loading="bLoadingPDF" icon
      class="button-secondary btn-refresh" style="width: 120px" @click="setDownload(items)">
      <v-icon>mdi-download</v-icon>
      Descargar
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailPrebillingHeader",
  props: {
    items: Object,
    pdf: String,

  },
  data() {
    return {
      bLoadingPDF: false
    }
  },
  beforeMount() {
    this.alias = this.items.sFolio;
    // this.alias = "PM-015925";

  },
  methods: {
    setDownload(item) {
      // var newWin = window.open("url");

      // if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      //   this.mixError("Activar ventana emergente del navegador", 0);
      // }else{
      //   this.mixSuccess("Ventana activada");

      // }
      this.bLoadingPDF = true
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            DB.get(`${URI}/advance-invoices/${item.sAdvanceInvoiceId}/pdf`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {
              },
            })
              .then((response) => {
                if (response.data.results) {
                  let LinkPDF = response.data.results
                  try {
                    let popUp = window.open(LinkPDF, "_blank")
                    
                    if (popUp === null || typeof (popUp) === 'undefined') {
                      this.mixError("Activar ventana emergente del navegador", 0);
                    }
                    else {
                      popUp.focus();
                    }
                  } catch (error) {
                    this.mixError("Activar ventana emergente del navegador", 0);
                  }

                } else {
                  this.mixError("Prefactura no disponible aún.", 0);
                }
                this.bLoadingPDF = false
              })
              .catch((error) => {
                this.mixError(error.response.data.message, error.response.status);
                this.bLoadingPDF = false
              });
          } else {
            this.bLoadingPDF = false
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoadingPDF = false
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        })
    }
  }
}

</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-title-blue {
  color: #2759a2;
}

.content-header {
  display: flex;
  align-items: center;
  /* width: fit-content; */
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 500px) {

  .txt-title,
  .icon {
    font-size: 25px !important;
  }
}
</style>
<template>
  <div>
    <div class="mt-5">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field v-model="name" type="text" label="Nombre" placeholder="Nombre" dense color="#2759A2"
                class="global-auth-inputs poppins mb-9" maxlength="85" persistent-placeholder @keypress="soloLetters">
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field v-model="lastname" type="text" label="Apellido" placeholder="Apellido" dense color="#2759A2"
                class="global-auth-inputs poppins mb-9" maxlength="85" persistent-placeholder @keypress="soloLetters">
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field v-model="email" type="text" label="Correo electrónico" placeholder="Correo electrónico"
                dense color="#2759A2" class="global-auth-inputs poppins mb-9" maxlength="85" persistent-placeholder
                @keypress="formatEmail" readonly disabled></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <v-text-field v-model="position" type="text" label="Cargo laboral" placeholder="Cargo laboral" dense
                color="#2759A2" class="global-auth-inputs poppins mb-9" maxlength="85" persistent-placeholder
                @keypress="soloLettersAndNumbers"></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <div>
              <!-- Start: phone component -->
              <country-code-input-component class="" @setCodigoPais="setCodigoPais" @setCodigoArea="setCodigoArea"
                @setNumeroTelefono="setNumeroTelefono" @setExtension="setExtension" :_bDialogAdd="false"
                :_codigoPais="codigoPais" :_codigoArea="codigoArea" :_numeroTelefono="numeroTelefono"
                :_extension="extension" :_type="true" />
              <!-- End: phone component -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="border-line mt-5 mb-9" />
    <div class="
        content-buttons
        display-flex
        align-items-center
        justify-content-flex-end
      ">
      <v-btn @click="cancel" class="button-secondary poppins pl-4 pl-4" elevation="0">
        Descartar cambios
      </v-btn>
      <v-btn @click="save" :loading="bLoading" :disabled="!validationForm" class="button-primary poppins ml-2 pl-4 pr-4"
        elevation="0">
        Guardar cambios
      </v-btn>
    </div>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutProfileEdit",
  props: {
    editArr: {
      type: Object,
    },
  },
  data() {
    return {
      name: "",
      email: "",
      lastname: "",
      position: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      bLoading: false,
    };
  },
  beforeMount() {
    this.loadUser();
  },
  methods: {
    loadUser: function () {
      this.name = this.editArr.sName;
      this.lastname = this.editArr.sLastname;
      this.email = this.editArr.oGeneralInfo.sEmail;
      this.position = this.editArr.oEnterpriseInfo.sJobTitle;
      this.codigoPais = this.editArr.oContactInfo.sCountryCallingCode
        ? this.editArr.oContactInfo.sCountryCallingCode
        : "52";
      this.codigoArea = this.editArr.oContactInfo.sAreaCallingCode;
      this.numeroTelefono = this.editArr.oContactInfo.sPhoneNumber;
      this.extension = this.editArr.oContactInfo.sPhoneExtension;
    },
    cancel: function () {
      this.name = "";
      this.lastname = "";
      this.email = "";
      this.position = "";
      this.codigoPais = "52";
      this.codigoArea = "";
      this.numeroTelefono = "";
      this.extension = "";
      this.$emit("cancel");
    },
    save: function () {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          sName: this.name,
          sLastname: this.lastname,
          sJobTitle: this.position,
          sPhoneNumber: this.numeroTelefono,
          sAreaCallingCode: this.codigoArea,
          sCountryCallingCode: this.codigoPais,
          sPhoneExtension: this.extension,
        };
      DB.put(
        `${URI}/administrators/${this.$store.state.sUserId}/profile`,
        payload,
        config
      )
        .then((response) => {
          this.$store.commit(
            "setFullName",
            { sName: response.data.results.sName, sLastname: response.data.results.sLastname }
          );
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.cancel();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setCodigoPais: function (e) {
      this.codigoPais = e;
    },
    setCodigoArea: function (e) {
      this.codigoArea = e;
    },
    setNumeroTelefono: function (e) {
      this.numeroTelefono = e;
    },
    setExtension: function (e) {
      this.extension = e;
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.name != "" &&
        this.lastname != "" &&
        this.email != "" &&
        this.position != "" &&
        this.codigoPais != "" &&
        this.codigoArea != "" &&
        this.numeroTelefono != ""
      );
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.border-line {
  border-bottom: 1px solid #d0dbf2;
}

@media (max-width: 600px) {
  .global-auth-inputs {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 500px) {
  .content-buttons {
    display: block !important;
  }

  .button-primary,
  .button-secondary {
    width: 100%;
  }

  .button-primary {
    margin: 20px 0px !important;
  }
}
</style>
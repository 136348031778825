<template>
  <div class="height-100">
    <navbar-component @setOptions="setOptions" />

    <div class="content-inside-component">
      <div class="display-flex align-items-flex-start">
        <div :class="(bMenu) ? 'content-left active' : 'content-left inactive'"
          :style="{ height: `${screenHeight}px` }">
          <sidebar-component />
        </div>
        <div class="content-right" ref="ContentRightAll" :style="{ height: `${screenHeight}px` }">
          <router-view />
        </div>
      </div>
    </div>
    <menu-responsive-component />
    <delete-component :oOptions="oOptions" @setOptions="setOptions" />
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import MenuResponsiveComponent from "@/components/MenuResponsive.vue";

export default {
  name: "InsideContentView",
  data() {
    return {
      screenHeight: 0,
      oOptions: {},
      bMobileOrTablet: false
    };
  },
  beforeMount() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("reisze", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 70;
      this.bMobileOrTablet = window.innerWidth < 959.99;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
  },
  computed: {
    bMenu: function () {
      return this.$store.state.bMenu;
    },
    bGoToTop() {
      return this.$store.state.bGoToTop;
    }
  },
  watch: {
    bGoToTop() {

      this.$nextTick(() => {
        if (this.bMobileOrTablet) {
          this.$refs.ContentRightAll.scrollTop = 0;
        }
      });
    }
  },
  components: {
    NavbarComponent,
    SidebarComponent,
    MenuResponsiveComponent,
  },
};
</script>

<style scoped>
.content-inside-component {
  height: calc(100% - 70px);
  background-color: #f2f6fe;
}

.content-left {
  padding: 0px 0px 20px 20px;
  overflow: auto;
}

.active {
  width: 410px;
  transition: all 0.3s;
  overflow: hidden;
}

.inactive {
  width: 120px;
  transition: all 0.3s;
  overflow: hidden;
}

.content-right {
  padding: 0px 20px 20px 20px;
  overflow: auto;
  width: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 960px) {
  .content-left {
    display: none;
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-txt-title-section poppins"},[_vm._v(" Estado actual de la solicitud ")]),(!_vm.bMobile)?_c('div',[_c('div',{staticClass:"mt-5 mb-7 content-status"},[_vm._m(0),_c('v-spacer'),_c('div',{class:_vm.bMobile ? 'mb-3' : ''},[_c('v-chip',{attrs:{"color":_vm.getColorStatus(_vm.itemsSims).sColorBackground,"text-color":_vm.getColorStatus(_vm.itemsSims).sColorText}},[_vm._v(" "+_vm._s(_vm.sTextRequestStatus)+" ")])],1),(
       _vm.sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
        _vm.sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
      )?_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminSIMCardsSolicitud),expression:"bAdminSIMCardsSolicitud"}]}):_vm._e(),(
         _vm.sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
          _vm.sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
        )?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminSIMCardsSolicitud),expression:"bAdminSIMCardsSolicitud"}],staticClass:"button-primary poppins",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.setToogleDialog(true)}}},[_vm._v(" Modificar estado ")]):_vm._e()],1),_c('div',{staticClass:"border-line"})]):_c('div',[_c('div',{staticClass:"mt-5 mb-7 content-status"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('p',{staticClass:"txt-title-def poppins mb-0"},[_vm._v("Estado actual")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"mb-3 status"},[_c('v-chip',{attrs:{"color":_vm.getColorStatus(_vm.itemsSims).sColorBackground,"text-color":_vm.getColorStatus(_vm.itemsSims).sColorText}},[_vm._v(" "+_vm._s(_vm.sTextRequestStatus)+" ")])],1)]),(
         _vm.sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
          _vm.sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
        )?_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminSIMCardsSolicitud),expression:"bAdminSIMCardsSolicitud"}]}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(
             _vm.sCurrentStatus !== '875c3c08-cf5a-424b-988b-e724d33e4e8b' &&
              _vm.sCurrentStatus !== '8ee1565e-ee98-462d-a445-b1d7248dbaed'
            )?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.bAdminSIMCardsSolicitud),expression:"bAdminSIMCardsSolicitud"}],staticClass:"button-primary poppins btn-state",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.setToogleDialog(true)}}},[_vm._v(" Modificar estado ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"border-line"})]),_c('layout-detail-sims-change-status',{attrs:{"bDialog":_vm.bDialog,"itemsSims":_vm.itemsSims},on:{"setToogleDialog":_vm.setToogleDialog}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"txt-title-def poppins mb-0"},[_vm._v("Estado actual")])])}]

export { render, staticRenderFns }
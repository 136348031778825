<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      <div
        v-if="!activeCard"
        style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px"
      >
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item_header, index_header) in headers"
                  :key="index_header"
                  :class="item_header.align"
                  class="table-header-text poppins"
                >
                  {{ item_header.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="editItem(item.sAdministratorId)"
                >
                  {{ item.sFullName }}
                </td>
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="editItem(item.sAdministratorId)"
                >
                  {{ item.oEnterpriseInfo.sJobTitle }}
                </td>
                <td
                  class="txt-item-table text-left poppins cursor-pointer"
                  @click="editItem(item.sAdministratorId)"
                >
                  {{ item.oGeneralInfo.sEmail }}
                </td>
                <td
                  class="txt-item-table text-left poppins d-flex align-center cursor-pointer"
                  @click="editItem(item.sAdministratorId)"
                >
                  <img
                    v-if="getValidatePhoneEmpty(item.oContactInfo)"
                    class="mr-3"
                    width="20"
                    height="20"
                    :src="getFlag(item.oContactInfo.sCountryCallingCode)"
                  />
                  <span v-if="getValidatePhoneEmpty(item.oContactInfo)">
                    {{ formatPhone(item.oContactInfo) }}
                  </span>

                  <span v-if="!getValidatePhoneEmpty(item.oContactInfo)" class="txt-item-table-card-item-empty" >
                    "No hay información"
                  </span>
                </td>
                <td
                  v-if="item.oGeneralInfo.bPlatformAccess"
                  class="txt-item-table text-left poppins"
                  @click="changeStatus(item.sAdministratorId, false)"
                >
                  <div class="active-platform">
                    Bloquear
                    <v-icon color="#E33F3F" size="14px">
                      mdi-lock-outline
                    </v-icon>
                  </div>
                </td>
                <td v-else class="txt-item-table text-left poppins">
                  <div
                    @click="changeStatus(item.sAdministratorId, true)"
                    class="inactive-platform"
                  >
                    Desbloquear
                    <v-icon color="#2759A2" size="14px">
                      mdi-lock-open-outline
                    </v-icon>
                  </div>
                </td>
                <td class="txt-item-table text-center poppins">
                  <div
                    class="display-flex align-items-center justify-content-center"
                  >
                    <v-btn @click="editItem(item.sAdministratorId)" icon>
                      <v-icon color="#000" size="16px">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="getPermissionsActionsGlobal()"
                      @click="deleteItem(item.sAdministratorId)"
                      icon
                    >
                      <v-icon size="16px"> mdi-close </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- pagination -->
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="display-flex align-items-center"
                >
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item
                      v-for="(item_pages, index_pages) in pages"
                      :key="index_pages"
                      @click="changeNumPages(item_pages)"
                      link
                    >
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col
                v-for="(item, index) in items"
                :key="index"
                cols="12"
                sm="6"
              >
                <div
                  :class="
                    item.oGeneralInfo.bPlatformAccess
                      ? 'content-card-responsive card-active position-relative'
                      : 'content-card-responsive card-inactive position-relative'
                  "
                >
                  <v-menu
                    offset-y
                    content-class="content-menu-options"
                    attach
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="content-status-mobile-card">
                        <div
                          @click="
                            changeStatus(
                              item.sAdministratorId,
                              !item.oGeneralInfo.bPlatformAccess
                            )
                          "
                          :class="
                            item.oGeneralInfo.bPlatformAccess
                              ? 'active-platform'
                              : 'inactive-platform'
                          "
                        >
                          <!-- {{item.oGeneralInfo.bPlatformAccess? 'Bloquear':'Desbloquear'}} -->
                          <v-icon
                            :color="
                              item.oGeneralInfo.bPlatformAccess
                                ? '#E33F3F'
                                : '#2759A2'
                            "
                            size="14px"
                          >
                            {{
                              item.oGeneralInfo.bPlatformAccess
                                ? "mdi-lock-outline"
                                : "mdi-lock-open-outline"
                            }}
                          </v-icon>
                        </div>
                      </div>
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="content-menu-options-dots-global"
                      >
                        <v-icon color="#000" size="20px">
                          mdi-dots-vertical
                        </v-icon>
                      </div>
                    </template>
                    <div class="circle-content-options">
                      <div>
                        <v-list dense>
                          <v-list-item
                            link
                            @click="editItem(item.sAdministratorId)"
                          >
                            <v-list-item-icon>
                              <v-icon color="#000" size="14px">
                                mdi-eye-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="poppins">
                              Detalle
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            v-if="getPermissionsActionsGlobal()"
                            link
                            @click="deleteItem(item.sAdministratorId)"
                          >
                            <v-list-item-icon>
                              <v-icon size="14px"> mdi-close </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content
                              class="text-delete-card poppins"
                            >
                              Eliminar
                            </v-list-item-content>
                          </v-list-item>
                          <!-- list item -->
                        </v-list>
                      </div>
                    </div>
                  </v-menu>
                  <div class="display-flex height-100">
                    <div>
                      <p class="txt-item-table-card-title poppins mb-0">
                        {{ item.sFullName }}
                      </p>
                      <p class="txt-item-table-card-item poppins mb-0">
                        {{ item.oEnterpriseInfo.sJobTitle }}
                      </p>
                      <p class="txt-item-table-card-item poppins mb-0">
                        {{ item.oGeneralInfo.sEmail }}
                      </p>
                      <p
                        v-if="getValidatePhoneEmpty(item.oContactInfo)"
                        class="txt-item-table-card-item d-flex align-center poppins mb-0"
                      >
                        <img
                          class="mr-1"
                          width="20"
                          height="20"
                          :src="getFlag(item.oContactInfo.sCountryCallingCode)"
                        />
                        {{ formatPhone(item.oContactInfo) }}
                      </p>
                      <p v-else>"Sin información"</p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div
          class="content-pagination mt-4"
          style="background-color: transparent"
        >
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="display-flex align-items-center"
                >
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item
                      v-for="(item_pages, index_pages) in pages"
                      :key="index_pages"
                      @click="changeNumPages(item_pages)"
                      link
                    >
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <empty-content-component
      v-else
      text="Aún no hay información para mostrar."
      height="450"
    />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
    <!-- confirm status  -->
    <confirm-change-status-component
      :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus"
    />
  </div>
</template>

<script>
import lodash from "lodash";
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutAdministratorsContent",
  props: {
    sSearch: {
      type: String,
    },
    sNameOrder: {
      type: String,
    },
    bPlatformAccess: {
      type: String,
    },
  },
  data() {
    return {
      bLoading: false,
      items: [],
      headers: [
        {
          title: "Administrador",
          align: "text-left  ",
        },
        {
          title: "Cargo laboral",
          align: "text-left ",
        },
        {
          title: "Correo electrónico",
          align: "text-left  ",
        },
        {
          title: "Teléfono",
          align: "text-left ",
        },
        {
          title: "Estado",
          align: "text-center ",
        },
        {
          title: "Acciones",
          align: "text-center",
        },
      ],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
      oChangeStatusOptions: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (sAdministratorId, bAction) {
      if (this.getPermissionsActionsGlobal()) {
        this.oChangeStatusOptions = {
          active: true,
          api: `${URI}/administrators/${sAdministratorId}`,
          payload: { bPlatformAccess: bAction },
          bInputs: false,
          title:
            bAction == true
              ? "Desbloqueo de administrador"
              : "Bloqueo de administrador",
          action: bAction,
          description:
            bAction == true
              ? "La siguiente acción restablecerá su acceso a la plataforma.</br>¿Desea continuar?"
              : "La siguiente acción restringirá su acceso a la plataforma.</br> ¿Desea continuar?",
          sTextBtn: bAction == true ? "Desbloquear" : "Bloquear",
        };
      }
    },
    getData: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/administrators`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {
              iPageNumber: this.iCurrentPage,
              sSearch: this.sSearch,
              iItemsPerPage: this.iItemsPerPage,
              sNameOrder: this.sNameOrder,
              bPlatformAccess: this.bPlatformAccess,
            },
          })
            .then((response) => {
              this.bLoading = false;
              this.items = response.data.results;

              this.numPages = response.data.iNumPages;
              this.iPageNumber = response.data.iNumPages;
              this.totalItems = response.data.iTotal;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/administrators/${id}`,
        title: "Eliminar administrador",
        description:
          "Eliminar un administrador es una acción irreversible </br>  ¿Desea continuar? ",
      };
      this.$emit("setOptions", options);
    },
    editItem: function (id) {
      this.$router.push({
        name: "detailAdministrators",
        params: {
          id: id,
        },
      });
    },
    //helpers
    // formatPhone: function (item) {
    //   let result = "";

    //   if (item.sPhoneExtension == "") {
    //     result =
    //       "+" +
    //       item.sCountryCallingCode +
    //       " " +
    //       item.sAreaCallingCode +
    //       item.sPhoneNumber;
    //   } else {
    //     result =
    //       "+" +
    //       item.sCountryCallingCode +
    //       " " +
    //       item.sAreaCallingCode +
    //       item.sPhoneNumber +
    //       " Ext. " +
    //       item.sPhoneExtension;
    //   }
    //   return result;
    // },
    formatPhone(item) {
      let sCountryCallingCode = item.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 4),
              "-",
              item.sPhoneNumber.slice(4),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        case "1":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 3),
              "-",
              item.sPhoneNumber.slice(3),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        default:
          break;
      }
    },
    getValidatePhoneEmpty(oItem) {
      if (oItem.sAreaCallingCode && oItem.sPhoneNumber) {
        return true;
      } else {
        return false;
      }
    },
    getFlag(sCountryCallingCodeOrigin) {
      switch (sCountryCallingCodeOrigin) {
        case "52":
          return [require("@/assets/images/mx.png")];
        case "593":
          return [require("@/assets/images/ecuador.png")];
        case "1":
          return [require("@/assets/images/us.png")];

        default:
          break;
      }
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    formatDate: function (date) {
      return MOMENT(date).locale("es").format("DD/MMM/YYYY");
    },
    // pagination
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },
    sSearch: lodash.debounce(function () {
      this.getData();
    }, 500),
    sNameOrder: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    bPlatformAccess: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.card-inactive {
  border: 1px solid #2759a2;
}

.card-active {
  border: 1px solid #2759a2;
}

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.active-platform {
  white-space: nowrap;
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.inactive-platform {
  white-space: nowrap;
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.text-delete-card {
  /* color: #e33f3f; */
}

.content-status-mobile-card {
  width: 40px;
  position: absolute;
  right: 30px;
  top: 10px;
}
.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}
</style>

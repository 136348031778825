<template>
  <div>
    <layout-clients-header @setDialogAdd="setDialogAdd" />
    <layout-clients-searchbar
      @setSearch="setSearch"
      @setNameOrder="setNameOrder"
      @setPlatformAccess="setPlatformAccess"
      @setDates="setDates"
    />
    <layout-clients-content
      :sSearch="sSearch"
      :sNameOrder="sNameOrder"
      :bPlatformAccess="bPlatformAccess"
      @setOptions="setOptions"
      :arrDates="arrDates"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutClientsContent from "@/layouts/Clients/Content.vue";
import LayoutClientsSearchbar from "@/layouts/Clients/Searchbar.vue";
import LayoutClientsHeader from "@/layouts/Clients/Header.vue";

export default {
  name: "ClientsView",
  data() {
    return {
      sSearch: "",
      sNameOrder: undefined,
      bPlatformAccess: undefined,
      bDialogAdd: false,
      oOptions: {},
      clientId: "",
      arrDates: [],
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setNameOrder: function (val) {
      this.sNameOrder = val;
    },
    setPlatformAccess: function (val) {
      this.bPlatformAccess = val;
    },
    setDates: function (val) {
      this.arrDates = val;
    },
  },
  components: {
    LayoutClientsContent,
    LayoutClientsSearchbar,
    LayoutClientsHeader,
  },
};
</script>
<template>
  <div>
    <!-- content logo -->
    <div class="display-flex align-items-center justify-content-center mt-5">
      <div class="circle-extern">
        <!-- <div class="circle-intern display-flex align-items-center justify-content-center">
          PR
        </div> -->
        <img v-if="sLogo" :src="sLogo" />
        <img v-else src="@/assets/images/logo-color.svg" />
        <v-btn :disabled="!getPermissionsActionsGlobal()"  class="replace-logo" dark icon @click="openMedia" :loading="bLoading">
        </v-btn>
        <v-btn :disabled="!getPermissionsActionsGlobal()"  v-if="sLogo" @click="openMedia" color="#2759a2" dark class="icon-edit-photo">
          <v-icon size="20px"> mdi-pencil-outline </v-icon>
        </v-btn>
        <v-btn :disabled="!getPermissionsActionsGlobal()"  v-else @click="openMedia" color="#2759a2" dark class="icon-edit-photo">
          <v-icon size="20px"> mdi-camera-outline </v-icon>
        </v-btn>
      </div>
    </div>
    <input ref="fileInput" id="fileInput" type="file" style="display: none" accept=".png, .jpg, .jpeg"
      @change="uploadLogo" />
  </div>
</template>

<script>
export default {
  name: "LayoutDetailClientPhoto",
  props: {
    items: Object,
  },
  data() {
    return {
      sLogo: "",
      bLoading: false,
    };
  },
  beforeMount() {
    if (!this.items.oLogoUrl) {
      this.sLogo = undefined;
    } else {
      this.sLogo = this.items.oLogoUrl.md;
    }
  },
  methods: {
    openMedia: function () {
      this.$refs.fileInput.click();
    },
    uploadLogo: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.sendImage(files[0]);
        });
      }
    },
    sendImage(file) {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      var form = new FormData();
      form.append("aPicture", file);

      DB.patch(
        `${URI}/enterprises/${this.items.sEnterpriseId}/logo`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;

          this.mixError(err.response.data.message);
        });
    },
  },
  watch: {
    items: function () { },
  },
};
</script>

<style scoped>
.replace-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent !important;
}

.icon-edit-photo {
  position: absolute;
  bottom: 10px;
  right: -5px;
  width: 35px !important;
  max-width: 35px !important;
  min-width: 35px !important;
  border-radius: 100%;
  cursor: pointer;
}

.circle-extern {
  width: 250px;
  height: 250px;
  border: 3px solid #79defc;
  border-radius: 100%;
  position: relative;
}

.circle-extern img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .circle-extern img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 100%;
  }
}

.circle-intern {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #2759a2 0% 0% no-repeat padding-box;
  text-align: left;
  font-size: 50px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
</style>
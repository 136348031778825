<template>
  <div class="content-search mt-9 mb-9">
    <div class="content-input-search">
      <v-text-field
        v-model="sSearch"
        type="text"
        label="Buscar por"
        placeholder="Buscar por alias, correo o teléfono"
        dense
        color="#2759A2"
        class="global-auth-inputs poppins mb-4"
        maxlength="85"
        append-icon="mdi-magnify"
        persistent-placeholder
        @input="setSearch"
      ></v-text-field>
      <div
        v-show="bMobile"
        class="
          display-flex
          align-items-center
          justify-content-flex-end
          position-relative
        "
      >
        <v-menu
          offset-y
          content-class="circle-profile-content"
          :close-on-content-click="false"
          min-width="200px"
          attach
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="button-secondary ml-9"
              style="width: 50px; margin-top: -5px"
              elevation="0"
            >
              <v-icon>mdi-tune-vertical</v-icon>
            </v-btn>
          </template>
          <div class="circle-content-options">
            <div>
              <div class="content-down-menu">
                <p class="txt-title-down-menu poppins mb-0">Estado</p>
                <div>
                  <v-checkbox
                    v-for="(item, index) in itemsEstatus"
                    :key="index"
                    v-model="resultEstatus"
                    :value="item.value"
                    label=""
                    hide-details=""
                    color="#2759a2"
                    class="mb-1"
                  >
                    <template v-slot:label>
                      <p class="txt-description-down-menu poppins mb-0">
                        {{ item.text }}
                      </p>
                    </template>
                  </v-checkbox>
                </div>
                <p class="txt-title-down-menu poppins mb-1">Orden alfabético</p>
                <div>
                  <v-checkbox
                    v-for="(item, index) in itemsAlphabetic"
                    :key="index"
                    v-model="resultAlfabetico"
                    :value="item.value"
                    label=""
                    hide-details=""
                    color="#2759a2"
                    class="mb-1"
                  >
                    <template v-slot:label>
                      <p class="txt-description-down-menu poppins mb-0">
                        {{ item.text }}
                      </p>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </v-menu>
      </div>
    </div>

    <div class="position-relative" @click="menu = true">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="rangoFechas"
        transition="scale-transition"
        offset-y
        min-width="auto"
        attach
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="content-input-date-range">
            <v-combobox
              v-model="rangoFechas"
              v-bind="attrs"
              v-on="on"
              label="Rango de fechas"
              placeholder="Seleccionar fechas"
              persistent-placeholder
              readonly
              class="global-auth-inputs"
              dense
              color="#2759A2"
              append-icon="mdi-calendar"
              chips
              small-chips
              multiple
              clearable
            ></v-combobox>
          </div>
        </template>
        <v-date-picker
          v-model="rangoFechas"
          :max="nowDate"
          no-title
          scrollable
          multiple
          range
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              menu = false;
              $refs.menu.save((dates = []));
              rangoFechas = [];
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              $refs.menu.save(rangoFechas);
              saveDates();
            "
            :disabled="rangoFechas.length < 2"
          >
            Aceptar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-spacer />
    <div
      v-show="!bMobile"
      class="
        display-flex
        align-items-center
        justify-content-flex-end
        position-relative
      "
    >
      <v-menu
        offset-y
        content-class="circle-profile-content"
        :close-on-content-click="false"
        min-width="200px"
        attach
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="button-secondary"
            style="width: 50px; margin-top: -5px"
            elevation="0"
          >
            <v-icon>mdi-tune-vertical</v-icon>
          </v-btn>
        </template>
        <div class="circle-content-options">
          <div>
            <div class="content-down-menu">
              <p class="txt-title-down-menu poppins mb-0">Estado</p>
              <div>
                <v-checkbox
                  v-for="(item, index) in itemsEstatus"
                  :key="index"
                  v-model="resultEstatus"
                  :value="item.value"
                  label=""
                  hide-details=""
                  color="#2759a2"
                  class="mb-1"
                >
                  <template v-slot:label>
                    <p class="txt-description-down-menu poppins mb-0">
                      {{ item.text }}
                    </p>
                  </template>
                </v-checkbox>
              </div>
              <p class="txt-title-down-menu poppins mb-1">Orden alfabético</p>
              <div>
                <v-checkbox
                  v-for="(item, index) in itemsAlphabetic"
                  :key="index"
                  v-model="resultAlfabetico"
                  :value="item.value"
                  label=""
                  hide-details=""
                  color="#2759a2"
                  class="mb-1"
                >
                  <template v-slot:label>
                    <p class="txt-description-down-menu poppins mb-0">
                      {{ item.text }}
                    </p>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutClientsSearchbar",
  data() {
    return {
      resultAlfabetico: undefined,
      resultEstatus: undefined,
      sSearch: "",
      menu: false,
      rangoFechas: [],
      itemsEstatus: [
        {
          text: "Activos",
          value: "true",
        },
        {
          text: "Inactivos",
          value: "false",
        },
      ],
      itemsAlphabetic: [
        {
          text: "De la A a la Z",
          value: "asc",
        },
        {
          text: "De la Z a la A",
          value: "desc",
        },
      ],
      nowDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: new Date(),
      picker: new Date().toISOString().substr(0, 10),
      landscape: false,
      reactive: false,
      bMobile: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 959.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    saveDates: function () {
      this.$emit("setDates", this.rangoFechas);
    },
  },
  computed: {
    getEndDate() {
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        10
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  watch: {
    rangoFechas: function () {
      if (this.rangoFechas.length == 2) {
        if (this.rangoFechas[0] > this.rangoFechas[1]) {
          let fecha_inicial = this.rangoFechas[1];
          this.rangoFechas = [];
          this.rangoFechas.push(fecha_inicial);
        }
      }

      if (this.rangoFechas.length == 0) {
        this.$emit("setDates", []);
      }
    },
    resultEstatus: function () {
      this.$emit("setPlatformAccess", this.resultEstatus);
    },
    resultAlfabetico: function () {
      this.$emit("setNameOrder", this.resultAlfabetico);
    },
  },
};
</script>

<style scoped>
.content-search {
  display: flex;
  width: 100%;
}
.content-input-search {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}
.content-input-date-range {
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  margin-left: 25px;
}
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-search {
    display: block;
    width: 100%;
  }
  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .content-search {
    display: block;
    width: 100%;
  }
  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
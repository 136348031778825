<template>
  <div>
    <div class="content-title-section mb-2">Top 20 Clientes</div>
    <!-- :style="{'height': iHeightCard + 'px','min-height': iHeightCard + 'px' }" -->
    <div class="content-dashboard content-dashboard-customer">
      <!-- :style="{'height': iHeightCard + 'px','min-height': iHeightCard + 'px' }" -->
      <v-data-table
        v-if="bMobileAndTable"
        no-data-text="No hay información"
        :hide-default-footer="true"
        :mobile-breakpoint="0"
        :items-per-page="1000000"
        :height="iHeightCard"
        :headers="headers"
        fixed-header
        :items="aCustomer"
      >
        <template v-slot:[`item.iPrebilling`]="{ item }">
          <span> ${{ formatMoneyGlobal(item.iPrebilling) }} MXN </span>
        </template>
      </v-data-table>
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col v-for="(item, index) in aCustomer" :key="index" cols="12" sm="6">
                <div class="content-card-responsive">
                  <div class="content-all-card-dashboard-customer">
                    <p
                      class="txt-item-table-card-title font-bold fs-14 poppins mb-0"
                    >
                      {{ item.sName }}
                    </p>
                  </div>
                  <div class="content-all-card-dashboard-customer">
                    <div class="content-left-card-dashboard-customer">
                      <p class="fs-14 txt-item-table-card-title poppins mb-0">
                        Activas
                      </p>
                    </div>

                    <div class="content-right-card-dashboard-customer">
                      <p
                        class="fs-14 font-bold color-green text-end txt-item-table-card-title poppins mb-0"
                      >
                        {{ item.iActive }}
                      </p>
                    </div>
                  </div>
                  <div class="content-all-card-dashboard-customer">
                    <div class="content-left-card-dashboard-customer">
                      <p class="fs-14 txt-item-table-card-title poppins mb-0">
                        Inactivas
                      </p>
                    </div>

                    <div class="content-right-card-dashboard-customer">
                      <p
                        class="fs-14 font-bold color-red text-end txt-item-table-card-title poppins mb-0"
                      >
                        {{ item.iInactive }}
                      </p>
                    </div>
                  </div>
                  <div class="content-all-card-dashboard-customer">
                    <div class="content-left-card-dashboard-customer">
                      <p class="fs-14 txt-item-table-card-title poppins mb-0">
                        Pre-facturación
                      </p>
                    </div>

                    <div class="content-right-card-dashboard-customer">
                      <p
                        class="fs-14 txt-item-table-card-title text-end poppins mb-0"
                      >
                        ${{ formatMoneyGlobal(item.iPrebilling) }} MXN
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <div
                class="content-dashboard d-flex justify-center txt-empty-global"
                v-if="aCustomer.length === 0"
              >
                No hay información
              </div>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aCustomer: Array,
  },
  data() {
    return {
      bMobileAndTable: false,
      iHeightCard: 0,
      iHeightBodyTable: 0,
      headers: [
        {
          text: "Nombre",
          value: "sName",
          class: "text-left table-header-text poppins",
          cellClass: "text-left",
          sortable: false,
        },
        {
          text: "Activas",
          value: "iActive",
          class: "text-center table-header-text poppins",
          cellClass:
            "text-center txt-item-table text-center poppins color-green",
          align: "center",
          sortable: false,
        },
        {
          text: "Inactivas",
          value: "iInactive",
          class: "text-center table-header-text poppins",
          cellClass: "text-center txt-item-table text-center poppins color-red",
          align: "center",
          sortable: false,
        },
        {
          text: "Pre-facturación",
          value: "iPrebilling",
          class: "text-center table-header-text poppins",
          cellClass: "text-center txt-item-table text-center poppins",
          align: "center",
          sortable: false,
        },
      ],
      // aCustomer: [
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur Hugo ",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },
      //     // {
      //     //     sName: "Taxis del Sur 1",
      //     //     iActive: 32,
      //     //     iInactive: 12,
      //     //     iPrebilling: 999999999
      //     // },

      // ]
    };
  },
  updated() {
    this.onResize();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.iHeightCard = window.innerHeight - 216;
      this.bMobileAndTable = window.innerWidth > 959.99;
    },
  },
  computed: {},
};
</script>
<style>
.content-all-card-dashboard-customer {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.content-left-card-dashboard-customer {
  /* background-color: red; */
  width: 50%;
}

.content-right-card-dashboard-customer {
  /* background-color: blue; */
  width: 50%;
}

.fs-14 {
  font-size: 14px;
}

.content-dashboard-customer {
  overflow: auto;
  /* height: 358px; */
}

.content-body-table-dashboard-customer {
  overflow: auto !;
}
</style>

<template>
  <div>
    <layout-sims-header @setDialogAdd="setDialogAdd" />
    <layout-sims-searchbar
      @setSearch="setSearch"
      @setPlanId="setPlanId"
      @setStatusId="setStatusId"
      @setDates="setDates"
    />
    <layout-sims-content
      :sSearch="sSearch"
      @setOptions="setOptions"
      :sPlanId="sPlanId"
      :statusId="statusId"
      :arrDates="arrDates"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutSimsHeader from "@/layouts/Sims/Header.vue";
import LayoutSimsContent from "@/layouts/Sims/Content.vue";
import LayoutSimsSearchbar from "@/layouts/Sims/Searchbar.vue";

export default {
  name: "SimsView",
  data() {
    return {
      sSearch: "",
      bDialogAdd: false,
      oOptions: {},
      sPlanId: undefined,
      statusId: undefined,
      arrDates: [],
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setPlanId: function (val) {
      this.sPlanId = val;
    },
    setStatusId: function (val) {
      this.statusId = val;
    },
    setDates: function (val) {
      this.arrDates = val;
    },
  },
  components: {
    LayoutSimsHeader,
    LayoutSimsContent,
    LayoutSimsSearchbar,
  },
};
</script>
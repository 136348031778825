<template>
  <div class="mt-4">
    <div class="content-txt-title-section poppins">Acceso a la plataforma</div>
    <v-container fluid class="pl-4">
      <v-row>
        <v-col cols="12" sm="6">
          <div class="display-flex align-items-center height-100">
            <p class="txt-title poppins mb-0" v-if="!toogleAccess">
              El usuario <span class="txt-red txt-bold">no tiene acceso</span> a
              la plataforma
            </p>
            <p class="txt-title poppins mb-0" v-else>
              El usuario
              <span class="txt-green txt-bold">cuenta con acceso</span> a la
              plataforma
            </p>
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="6">
          <div class="content-button">
            <v-btn v-if="getPermissionsActionsGlobal()" @click="setChangeStatusDialog()" class="button-primary poppins" elevation="0">
              <span v-if="!toogleAccess"> Restablecer acceso </span>
              <span v-else> Restringir acceso </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div></div>
    </v-container>
    
    <div class="border-line mt-4" />
    <!-- <v-btn @click="setDeleteDialog()" class="button-secondary-delete poppins mr-3 w-165 mt-5" elevation="0">
      <span> Eliminar</span>
    </v-btn> -->
    <confirm-change-status-component :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="setChangeStatusDialog" />
   
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";
export default {
  components: { ConfirmChangeStatusComponent },
  name: "LayoutDetailAdministratorsAccess",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      toogleAccess: null,
      removePermission: false,
      addPermission: false,
      bLoading: false,
      oChangeStatusOptions: {},
    };
  },
  beforeMount() {
    this.toogleAccess = this.items.oGeneralInfo.bPlatformAccess;
  },
  methods: {
    edit: function (value) {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          bPlatformAccess: value,
        };

      DB.patch(
        `${URI}/administrators/${this.items.sAdministratorId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.removePermission = false;
          this.addPermission = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.removePermission = false;
          this.addPermission = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setChangeStatusDialog: function () {
      let bActiveDialog = (this.oChangeStatusOptions.active =
        !this.oChangeStatusOptions.active);
      if (bActiveDialog) {
        this.oChangeStatusOptions = {
          active: true,
          api: `${URI}/administrators/${this.$route.params.id}`,
          payload: { bPlatformAccess: !this.toogleAccess },
          bInputs: false,
          title:
            this.toogleAccess == false
              ? "Desbloqueo administrador"
              : "Bloqueo administrador",
          action: !this.toogleAccess,
          description:
            this.toogleAccess == false
              ? "La siguiente acción restablecerá su acceso a la plataforma.</br>¿Desea continuar?"
              : "La siguiente acción restringirá su acceso a la plataforma.</br> ¿Desea continuar?",
          sTextBtn: this.toogleAccess == false ? "Desbloquear" : "Bloquear",
        };
      } else {
        this.oChangeStatusOptions = {
          active: false,
          api: "",
          title: "",
          action: "",
          description: "",
          sTextBtn: "",
        };
      }
    },
    setDeleteDialog() {
      let options = {
        active: true,
        id: this.$route.params.id,
        api: `/administrators/${this.$route.params.id}`,
        title: "Eliminar administrador",
        description:
          "Eliminar un administrador es una acción irreversible </br>  ¿Desea continuar? ",
        bReturnView: true,
      };
      this.$emit("setOptions", options);
    },
  },
};
</script>

<style scoped>
.content-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 20px 40px #3a79bd19;
  padding: 20px;
  position: relative;
}

.txt-title-dialog {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.txt-description-dialog {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 400;
}

.content-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

.txt-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3c3c3c;
  opacity: 1;
  font-weight: 400;
}

.border-line {
  border-top: 1px solid #a1acc3;
}

@media (max-width: 600px) {
  .content-button {
    display: block;
  }
}
</style>
<template>
  <transition name="fade">
    <div class="content-menu-responsive" v-if="bMenuResponsive">
      <div class="display-flex height-100">
        <div class="sidebar-content height-100">
          <div class="content-image mb-9">
            <img src="@/assets/images/logo-blanco.png" alt="" />
          </div>
          <div v-for="(item, index) in filteredItems" :key="index">
            <div
              :class="
                selected == item.link
                  ? 'content-sidebar-option-active display-flex align-items-center'
                  : 'content-sidebar-option-inactive display-flex align-items-center'
              "
              @click="selectOption(item.link)"
              v-if="checkAdmin(item)"
            >
              <span
                v-if="item.title !== 'Solicitudes de tarjetas SIM'"
                class="material-icons icons-menu-global"
              >
                {{ item.icon }}
              </span>
              <img
                v-else
                class="img-icon-customer"
                src="@/assets/images/clientes.svg"
              />
              <p class="poppins mb-0 ml-1">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
        <div @click="closeMenuResponsive" class="glass-content" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MenuResponsiveComponent",
  data() {
    return {
      filteredItems: [],
      items: [
        {
          title: "Inicio",
          link: "inicio",
          icon: "mdi-home",
          bManager: false,
        },
        {
          title: "Solicitudes de tarjetas SIM",
          link: "sims",
          icon: "mdi-plus-circle-outline",
          bManager: false,
        },
        {
          title: "Administradores",
          link: "administrators",
          icon: "mdi-shield-account",
          bManager: true,
        },
        {
          title: "Clientes",
          link: "clients",
          icon: "mdi-account-multiple-outline",
          bManager: false,
        },
        {
          title: "Tarjetas SIM",
          link: "sim-cards",
          icon: "mdi-memory",
          bManager: false,
        },
      ],
      selected: "inicio",
    };
  },
  beforeMount() {
    this.changePermissions();
    this.selected = this.$route.path.split("/")[2];
  },
  methods: {
    selectOption: function (link) {
      if (link === this.$route.name) {
        this.$store.commit("refresher", true);
      } else {
        this.$router.push({ name: link }).catch((err) => {});
      }
      this.$store.commit("setMenuResponsive", false);
    },
    checkAdmin(item) {
      if (this.$store.state.bManager == true) {
        return true;
      } else if (!item.bManager) {
        return true;
      } else if (item.bManager) {
        return false;
      }
    },
    closeMenuResponsive: function () {
      this.$store.commit("setMenuResponsive", false);
    },
    changePermissions() {
      this.$store
        .dispatch("setPermissionsBtnGlobal", this.userPermissions)
        .then((resp) => {
          this.filteredItems = this.aMenuGlobal.filter((routeOption) => {
            if (this.userPermissions === null) {
              return routeOption;
            } else {
              var exp = this.userPermissions;
              let arr = "";
              exp.forEach((e) => {
                if (
                  e.sAdministratorModuleId ===
                  routeOption.sAdministratorModuleId
                ) {
                  arr = e;
                }
              });
              return arr;
            }
          });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
  },
  computed: {
    aMenuGlobal() {
      return this.$store.state.aMenuGlobal;
    },
    bMenuResponsive: function () {
      return this.$store.state.bMenuResponsive;
    },
    userPermissions() {
      return this.$store.state.aPermissions;
    },
    bManager: function () {
      return this.$store.state.bManager;
    },
  },
  watch: {
    $route: function () {
      this.selected = this.$route.path.split("/")[2];
    },
    userPermissions() {
      this.changePermissions();
    },
  },
};
</script>

<style scoped>
.img-icon-customer {
  width: 22px;
}

.content-image {
  width: 150px;
  height: 50px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.content-menu-responsive {
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.content-sidebar-option-active {
  background: #ffffff40 0% 0% no-repeat padding-box;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.content-sidebar-option-inactive {
  background-color: transparent;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 15px;
}

.content-sidebar-option-inactive:hover {
  background: #ffffff40 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.content-sidebar-option-active p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-sidebar-option-inactive p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.sidebar-content {
  background: radial-gradient(
    at right center,
    rgb(121, 222, 252) 11%,
    rgb(39, 89, 162) 79%
  );
  border-radius: 0px;
  padding: 15px;
  width: 270px;
  z-index: 9;
}

.glass-content {
  background-color: #00000090;
  width: calc(100% - 270px);
  z-index: 9;
}

.glass-content:hover {
  cursor: pointer;
}
</style>

<template>
    <div>
        <!--#region INFROMACION DE FACTURACIÓN -->
        <v-row>
            <v-col cols="12" class="ml-0 mr-0 mt-3">
                <div class="information-title">
                    Información de facturación
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Razón Social
                </p>
                <p class="txt-value-global">
                    {{ oBillingInformation.sBusinessName }}
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Dirección de facturación
                </p>
                <p v-if="oBillingInformation.sLocationBilling" class="txt-value-description-global">
                    {{ oBillingInformation.sLocationBilling }}
                </p>
                <p v-else class="txt-empty-global">
                    {{ emptyData }}

                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="ml-0 mr-0">
                <p class="txt-label-global">
                    Correo electrónico
                </p>
                <p v-if="oBillingInformation.sEmail" class="txt-value-global">
                    {{ oBillingInformation.sEmail }}
                </p>
                <p v-else class="txt-empty-global">
                    {{ emptyData }}
                </p>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="ml-0 mr-0">
                <p class="txt-label-global">
                    RFC: Razón Social
                </p>
                <p v-if="oBillingInformation.sTaxt" class="txt-value-global">
                    {{ oBillingInformation.sTaxt }}
                </p>
                <p v-else class="txt-empty-global">
                    {{ emptyData }}
                </p>
            </v-col>

        </v-row>
        <!--#endregion INFROMACION DE FACTURACIÓN -->

    </div>
</template>

<script>
export default {
    props: {
        oBillingInformation: Object
    },
    data() {
        return {
            emptyData: "Sin información.",

        }
    }
}
</script>

<style scoped>
.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 200px;
}
</style>
<template>
  <div class="mt-9">
    <div class="content-txt-title-section poppins">
      Información del administrador
    </div>
    <div class="mt-4 ml-4">
      <p class="txt-title-content-inputs poppins">Datos de registro</p>
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="nombre"
              type="text"
              label="Nombre"
              placeholder="Nombre"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Nombre(s) <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <div>
              <v-text-field
                v-model="apellido"
                type="text"
                label="Apellido"
                placeholder="Apellido"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                maxlength="85"
                persistent-placeholder
                @keypress="soloLetters"
                :disabled="!getPermissionsActionsGlobal()"
              >
                <template slot="label">
                  <span>Apellido(s) <span class="color-red">*</span></span>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div>
              <v-text-field
                v-model="puesto"
                type="text"
                label="Cargo laboral"
                placeholder="Cargo laboral"
                dense
                color="#2759A2"
                class="global-inputs poppins"
                background-color="transparent"
                maxlength="85"
                persistent-placeholder
                @keypress="soloLettersAndNumbers"
                :disabled="!getPermissionsActionsGlobal()"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="mt-4 ml-4">
      <p class="txt-title-content-inputs poppins">Datos de contacto</p>
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="correo"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              maxlength="85"
              persistent-placeholder
              background-color="transparent"
              readonly
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8">
            <div>
              <country-code-input-component
                class="mb-5"
                :bObligatoryField="false"
                @setCodigoPais="setCodigoPais"
                @setCodigoArea="setCodigoArea"
                @setNumeroTelefono="setNumeroTelefono"
                @setExtension="setExtension"
                :_codigoPais="codigoPais"
                :_codigoArea="codigoArea"
                :_numeroTelefono="numeroTelefono"
                :_extension="extension"
                :_bDialogAdd="true"
                :_type="true"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <div v-if="!activeButtons" class="content-buttons">
      <v-btn
        @click="discardChanges"
        class="button-secondary poppins"
        elevation="0"
      >
        Descartar cambios
      </v-btn>
      <v-btn
        :disabled="!validateForm"
        :loading="bLoading"
        @click="edit"
        class="button-primary poppins ml-3"
        elevation="0"
      >
        Guardar cambios
      </v-btn>
    </div> -->
    <div class="border-line mt-5" />
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutDetailAdministratorsForm",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      itemsOriginal: {},
      nombre: "",
      apellido: "",
      correo: "",
      puesto: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      activeButtons: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.nombre = this.items.sName;
    this.apellido = this.items.sLastname;
    this.correo = this.items.oGeneralInfo.sEmail;
    this.codigoPais = this.items.oContactInfo.sCountryCallingCode;
    this.codigoArea = this.items.oContactInfo.sAreaCallingCode;
    this.numeroTelefono = this.items.oContactInfo.sPhoneNumber;
    this.extension = this.items.oContactInfo.sPhoneExtension;
    this.puesto = this.items.oEnterpriseInfo.sJobTitle;
    this.itemsOriginal = this.items;
  },
  methods: {
    setCodigoPais: function (e) {
      this.codigoPais = e;
    },
    setCodigoArea: function (e) {
      this.codigoArea = e;
    },
    setNumeroTelefono: function (e) {
      this.numeroTelefono = e;
    },
    setExtension: function (e) {
      this.extension = e;
    },

    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
  },
  computed: {
    validateForm: function () {
      return (
        this.nombre !== "" &&
        this.apellido !== ""
        // &&
        // this.correo !== "" &&
        // this.telefono !== ""
      );
    },
  },
  watch: {
    itemsOriginal: function () {},
    nombre() {
      this.$emit("setnombre", this.nombre);
    },
    apellido() {
      this.$emit("setapellido", this.apellido);
    },
    correo() {
      this.$emit("setcorreo", this.correo);
    },
    puesto() {
      this.$emit("setpuesto", this.puesto);
    },
    codigoPais() {
      this.$emit("setcodigoPais", this.codigoPais);
    },
    codigoArea() {
      this.$emit("setcodigoArea", this.codigoArea);
    },
    numeroTelefono() {
      this.$emit("setnumeroTelefono", this.numeroTelefono);
    },
    extension() {
      this.$emit("setextension", this.extension);
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

.txt-title-content-inputs {
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  font-weight: 600;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .content-buttons button:nth-child(1) {
    width: 100%;
  }

  .content-buttons button:nth-child(2) {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }
}

.border-line {
  border-top: 1px solid #a1acc3;
}
</style>

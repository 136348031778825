<template>
  <div class="content-detail-global">
    <v-container fluid>
      <!--#region INFROMACION GENERAL DE LA PROMOCION -->
      <v-row>
        <v-col cols="12">
          <div class="information-title">Información general</div>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" xl="3" v-if="!bModify">
          <p class="txt-label-global">Folio</p>
          <p class="txt-value-global">
            {{ items.sFolio }}
          </p>
        </v-col>
        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
          <div v-if="bModify">
            <v-text-field
              v-model="sTitle"
              label="Nombre de la promoción"
              placeholder="Nombre de la promoción"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="30"
              persistent-placeholder
            >
              <template slot="label">
                <span
                  >Nombre de la promoción <span class="color-red">*</span></span
                >
              </template>
            </v-text-field>
          </div>
          <div v-else>
            <p class="txt-label-global">Nombre de la promoción</p>
            <p class="txt-value-global">
              {{ items.sTitle }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <div v-if="bModify">
            <v-select
              v-model="sPromotionType"
              :items="itemPromotionType"
              item-value="sPromotionTypeId"
              item-text="sName"
              label="Tipo de promoción"
              placeholder="Tipo de promoción"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              no-data-text="No hay datos disponibles"
            >
              <template slot="label">
                <span>Tipo de promoción <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
          <div v-else>
            <p class="txt-label-global">Tipo de promoción</p>
            <p class="txt-value-global">
              {{ items.oPromotionType.sName }}
            </p>
          </div>
        </v-col>

        <v-col cols="12">
          <div v-if="bModify">
            <v-textarea
              v-model="sShortDescription"
              label="Descripción corta"
              placeholder="Descripción corta"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="100"
              persistent-placeholder
              rows="2"
            >
              <template slot="label">
                <span>Descripción corta <span class="color-red">*</span></span>
              </template>
            </v-textarea>
          </div>
          <div v-else>
            <p class="txt-label-global">Descripción corta</p>
            <p class="txt-value-description-global">
              {{ items.sShortDescription }}
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <div v-if="bModify">
            <v-textarea
              v-model="sLongDescription"
              label="Descripción larga"
              placeholder="Descripción larga"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="700"
              persistent-placeholder
              rows="4"
            >
              <template slot="label">
                <span>Descripción larga <span class="color-red">*</span></span>
              </template>
            </v-textarea>
          </div>
          <div v-else>
            <p class="txt-label-global">Descripción larga</p>
            <p class="txt-value-description-global">
              {{ items.sLongDescription }}
            </p>
          </div>
        </v-col>
      </v-row>
      <!--#endregion INFROMACION GENERAL DE LA PROMOCION -->
      <div class="separator mt-5 mb-5" v-if="!bModify" />
      <!--#region REGISTRO DE ACTIVIDAD DE LA PROMACION -->
      <v-row v-if="!bModify">
        <v-col cols="12">
          <div class="information-title">Registro de actividades</div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <p class="txt-label-global">Fecha de creación</p>
          <p class="txt-value-global">
            {{ items.oCreatedAt.tCreatedAt }} por
            {{ items.oCreatedAt.oAdministrator.sName }}
          </p>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <p class="txt-label-global">Última modificación</p>
          <p class="txt-value-global">
            {{ items.oUpdatedAt.tUpdatedAt }} por
            {{ items.oUpdatedAt.oAdministrator.sName }}
          </p>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
          <p class="txt-label-global">Último cambio de estado</p>
          <p class="txt-value-global">
            {{ items.oPromotionStatus.tUpdatedAt }} por
            {{ items.oPromotionStatus.oAdministrator.sName }}
          </p>
        </v-col>
      </v-row>
      <!--#endregion REGISTRO DE ACTIVIDAD DE LA PROMACION -->
      <div class="separator mt-5 mb-5" />
      <!--#region CONTENT ESTADO DE LA PROMACION -->
      <v-row>
        <v-col cols="12">
          <div class="information-title">Estado</div>
        </v-col>

        <v-col>
          <p class="txt-value-global">
            La promoción se encuentra en estado
            <span
              :class="
                items.oPromotionStatus.sName === 'Activo'
                  ? 'text-active'
                  : 'text-inactive'
              "
              >{{ items.oPromotionStatus.sName }}</span
            >
          </p>
        </v-col>

        <v-col cols="12" sm="2" class="d-flex justify-end">
          <div
            v-if="getPermissionsActionsGlobal()"
            class="content-btn-change-status-promotion"
          >
            <v-btn
              v-if="bModify"
              class="button-secondary btn-paused-promotion"
              width="100%"
              @click="
                changeStatus(
                  sPromotionId,
                  items.oPromotionStatus.sName === 'Activo' ? false : true
                )
              "
            >
              {{
                items.oPromotionStatus.sName === "Activo"
                  ? "Pausar promoción"
                  : "Activar promoción"
              }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!--#endregion CONTENT ESTADO DE LA PROMACION -->
      <div class="separator mt-5 mb-5" v-if="getPermissionsActionsGlobal()" />
      <!--#region CONTENIDO DE BOTONES -->
      <div v-if="getPermissionsActionsGlobal()" class="content-btns-global">
        <div class="content-btn-back-global">
          <v-btn
            v-if="bModify"
            class="button-secondary"
            width="100%"
            @click="fullData()"
          >
            Cancelar
          </v-btn>
          <v-btn v-else class="button-secondary" width="100%" @click="back">
            Regresar
          </v-btn>
        </div>

        <div class="content-btn-edit">
          <v-btn
            v-if="bModify"
            class="button-primary"
            width="100%"
            @click="changePromotion"
            :loading="bLoading"
          >
            Guardar cambios
          </v-btn>
          <v-btn
            v-else
            class="button-primary"
            width="100%"
            @click="bModify = !bModify"
          >
            Modificar
          </v-btn>
        </div>
      </div>
      <!--#endregion CONTENIDO DE BOTONES -->
      <div class="separator mt-5 mb-5" />
      <!--#region CONTENT DISEÑO PREVIO -->
      <v-row>
        <v-col cols="12">
          <div class="information-title">Previo del diseño</div>
        </v-col>
        <v-col cols="12">
          <v-sheet
            class="content-promotions"
            :color="sColorCode"
            height="100%"
            tile
          >
            <div
              :style="{
                'background-color': sColorCodeTypePromotion,
                color: sColorTextTypePromotion,
              }"
              class="content-type-promotion"
            >
              <span class="text-type-promotion poppins">
                {{ sPromotionTypeName }}
              </span>
            </div>

            <div class="content-message-promotion">
              <img class="img-promotion" :src="sPromotionImg" alt="" />
              <span
                :style="{ color: sColorTextTypePromotion }"
                class="text-message-promotion poppins"
                :class="bMobile ? 'd-inline-block text-truncate' : ''"
              >
                {{ sTitle }}
              </span>
            </div>
            <div class="content-divider">
              <v-divider
                :vertical="bMobile ? false : true"
                class="divider-vertical"
              ></v-divider>
            </div>

            <div class="content-description-promotion">
              <span
                :style="{ color: sColorTextTypePromotion }"
                class="text-description-promotion"
              >
                {{ sShortDescription }}
              </span>
            </div>

            <div class="content-btn">
              <span
                class="button-primary btn-search poppins btn-disabled-promotion"
                elevation="0"
              >
                Ver promoción
              </span>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <!--#endregion CONTENT DISEÑO PREVIO -->
    </v-container>
    <confirm-change-status-component
      :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus"
    />
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  props: {
    items: Object,
  },
  data() {
    return {
      bLoading: false,
      bMobile: false,
      bModify: false,
      itemsEdit: {},
      sPromotionId: "",
      bStatusPromotion: false,
      sColorCode: "",
      sColorCodeTypePromotion: "#ffffffcc",
      sColorTextTypePromotion: "#2759a2",
      sTitle: "",
      sPromotionImg: "",
      sPromotionType: null,
      sPromotionTypeName: "",
      itemPromotionType: [],
      sShortDescription: "",
      sLongDescription: "",
      oChangeStatusOptions: {},
    };
  },
  beforeMount() {
    this.sPromotionId = this.$route.params.id;
    this.getPromotionsType();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 960) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    setDetailPromotion(item) {
      this.bDialogPromotionDetail = !this.bDialogPromotionDetail;
      this.oItemPromotion = item;
    },
    fullData() {
      this.bModify = false;

      this.sTitle = this.items.sTitle;
      this.sPromotionImg = this.items.oPromotionType.sIconURL;
      this.sPromotionType = this.items.oPromotionType.sPromotionTypeId;
      this.sPromotionTypeName = this.itemPromotionType.find(
        (e) => e.sPromotionTypeId === this.sPromotionType
      ).sName;
      this.sColorCode = this.itemPromotionType.find(
        (e) => e.sPromotionTypeId === this.sPromotionType
      ).sColorCode;

      this.sShortDescription = this.items.sShortDescription;
      this.sLongDescription = this.items.sLongDescription;

      this.bStatusPromotion =
        this.items.oPromotionStatus.sName === "Activo" ? true : false;
    },
    discardChange() {
      this.fullData();
    },
    getPromotionsType() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/promotions/types/`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              this.itemPromotionType = response.data.results;
              this.fullData();
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    changePromotion() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                sPromotionTypeId: this.sPromotionType,
                sTitle: this.sTitle,
                sShortDescription: this.sShortDescription,
                sLongDescription: this.sLongDescription,
              };
            DB.put(
              `${URI}/promotions/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        });
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (sPromotionId, bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URI}/promotions/${sPromotionId}`,
        payload: { sStatus: bAction ? "Active" : "Inactive" },
        bInputs: false,
        title:
          bAction == true
            ? "Reactivación de promoción"
            : "Desactivación de promoción",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción restablecerá la vigencia de esta promoción dentro de la plataforma.</br>¿Desea continuar?"
            : "La siguiente acción bloqueara el estado de la promoción.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Reactivar" : "Desactivar",
      };
    },
    back() {
      this.$router.push({ name: "promotions" }).catch((e) => {});
    },
    setColorTextTypePromotion() {
      if (
        this.sPromotionType === "c8b3e1d8-2346-4608-87bc-9ef817afcbfd" ||
        this.sPromotionType === "f43ea60e-8e1d-469c-82b4-fa84650a2401"
      ) {
        this.sColorTextTypePromotion = "#2759A2";
      } else {
        this.sColorTextTypePromotion = "#FFFF";
      }
    },
  },
  watch: {
    sPromotionType() {
      this.setColorTextTypePromotion();
      this.sPromotionImg = this.itemPromotionType.find(
        (e) => e.sPromotionTypeId === this.sPromotionType
      ).sIconURL;
      this.sPromotionTypeName = this.itemPromotionType.find(
        (e) => e.sPromotionTypeId === this.sPromotionType
      ).sName;
      this.sColorCode = this.itemPromotionType.find(
        (e) => e.sPromotionTypeId === this.sPromotionType
      ).sColorCode;
    },
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.information-title {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 2px;
  width: 170px;
}

.information-text {
  color: #2759a2;
  font-size: 12px;
  margin-bottom: 0px;
}

.text-active {
  color: #e33f3f;
}

.text-inactive {
  color: #2759a2;
}

.content-promotions {
  border-radius: 10px !important;
  display: flex;
}

.content-type-promotion {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  align-self: center;
  justify-content: center;
  height: 85px;
  width: 25px;
  border-radius: 0px 5px 5px 0px;
}

.text-type-promotion {
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #2759a2;
  opacity: 1;
  display: flex;
  align-self: center;
}

.content-message-promotion {
  display: flex;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
  align-items: center;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-message-promotion {
  font-size: 60px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.text-message-promotion {
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.content-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider-vertical {
  margin-left: 15px;
  margin-right: 15px;
  border-color: #ffffff33 !important;
}

.content-description-promotion {
  display: flex;
  width: 60%;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-description-promotion {
  font-size: 12px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  text-align: left;
}

.content-btn {
  display: flex;
  align-items: center;
  width: 150px;
  margin-left: 10px;
  margin-right: 15px;
}

.img-promotion {
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}

.btn-paused-promotion:hover {
  transform: scale(1.05);
}

/*#region GLOBAL CONTENT BTN */
.content-btns-global {
  display: flex;
  justify-content: flex-end;
}

.content-btn-change-status-promotion {
  width: 160px;
}

.content-btn-back-global {
  width: 150px;
  margin-right: 15px;
}

.content-btn-edit {
  width: 150px;
}

.btn-disabled-promotion {
  width: 150px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
}

/*#endregion GLOBAL CONTENT BTN */

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-promotions {
    height: auto !important;
    border-radius: 10px !important;
    display: block;
  }

  .content-type-promotion {
    writing-mode: inherit;
    transform: rotate(0deg);
    display: flex;
    align-self: center;
    justify-content: center;
    height: 15px;
    width: auto;
    border-radius: 5px 5px 0px 0px;
  }

  .text-type-promotion {
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #2759a2;
    opacity: 1;
    display: flex;
    align-self: center;
  }

  .content-message-promotion {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-message-promotion {
    font-size: 60px;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  .img-promotion {
    margin-left: 0px;
    margin-right: 15px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
  }

  .text-message-promotion {
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  .content-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .divider-vertical {
    margin-left: 15px;
    margin-right: 15px;
    border-color: #ffffff33 !important;
  }

  .content-description-promotion {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
    margin-right: 0px;
    min-height: 40%;
  }

  .text-description-promotion {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
  }

  .btn-disabled-promotion {
    width: 150px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: no-drop;
    margin-bottom: 10px;
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: block;
    justify-content: flex-end;
  }

  .content-btn-change-status-promotion {
    width: 100%;
  }

  .content-btn-back-global {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }

  .btn-disabled-promotion {
    width: 150px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: no-drop;
    margin-bottom: 10px;
  }

  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-promotions {
    height: auto !important;
    border-radius: 10px !important;
    display: block;
  }

  .content-type-promotion {
    writing-mode: inherit;
    transform: rotate(0deg);
    display: flex;
    align-self: center;
    justify-content: center;
    height: 15px;
    width: auto;
    border-radius: 5px 5px 0px 0px;
  }

  .text-type-promotion {
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #2759a2;
    opacity: 1;
    display: flex;
    align-self: center;
  }

  .content-message-promotion {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-message-promotion {
    font-size: 60px;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  .img-promotion {
    margin-left: 0px;
    margin-right: 15px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
  }

  .text-message-promotion {
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  .content-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .divider-vertical {
    margin-left: 15px;
    margin-right: 15px;
    border-color: #ffffff33 !important;
  }

  .content-description-promotion {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
    margin-right: 0px;
    min-height: 40%;
  }

  .text-description-promotion {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
  }

  .btn-disabled-promotion {
    width: 150px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: no-drop;
    margin-bottom: 10px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>

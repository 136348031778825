<template>
  <div>
    <layout-prebilling-header @setDialogAdd="setDialogAdd" />
    <layout-prebilling-searchbar
      @setSearch="setSearch"
      @setRegistredAction="setRegistredAction"
      @setUserType="setUserType"
      @setDate="setDate"
    />
    <layout-prebilling-content
      :sSearch="sSearch"
      :sRegistredAction="sRegistredAction"
      :sUserType="sUserType"
      @setOptions="setOptions"
      :sDate="sDate"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutPrebillingContent from "@/layouts/Prebillings/Content.vue";
import LayoutPrebillingSearchbar from "@/layouts/Prebillings/Searchbar.vue";
import LayoutPrebillingHeader from "@/layouts/Prebillings/Header.vue";

export default {
  name: "PrebillingView",
  data() {
    return {
      sSearch: "",
      sRegistredAction: null,
      sUserType: null,
      bDialogAdd: false,
      oOptions: {},
      clientId: "",
      sDate: "",
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setRegistredAction: function (val) {
      this.sRegistredAction = val;
    },
    setUserType: function (val) {
      this.sUserType = val;
    },
    setDate: function (val) {
      this.sDate = val;
    },
  },
  components: {
    LayoutPrebillingContent,
    LayoutPrebillingSearchbar,
    LayoutPrebillingHeader,
  },
};
</script>
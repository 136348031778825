<template>
  <div class="">
    <div class="content-title-label mb-4">Información del Administrador</div>
    <div class="content-inputs">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <v-text-field
            v-model="nombre"
            type="text"
            label="Nombre(s)"
            placeholder="Nombre(s)"
            dense
            color="#2759A2"
            class="global-inputs poppins mb-5"
            maxlength="85"
            persistent-placeholder
            @keypress="soloLetters"
          >
          <template slot="label">
            <span>Nombre(s) <span class="color-red">*</span></span>
          </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <v-text-field
            v-model="apellido"
            type="text"
            label="Apellido(s)"
            placeholder="Apellido(s)"
            dense
            color="#2759A2"
            class="global-inputs poppins mb-5"
            maxlength="85"
            persistent-placeholder
            @keypress="soloLetters"
          >
          <template slot="label">
            <span>Apellido(s) <span class="color-red">*</span></span>
          </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <v-text-field
            v-model="puesto"
            type="text"
            label="Cargo laboral"
            placeholder="Cargo laboral"
            dense
            color="#2759A2"
            class="global-inputs poppins mb-5"
            maxlength="85"
            persistent-placeholder
            @keypress="soloLettersAndNumbers"
          >

        </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <v-text-field
            v-model="correo"
            type="text"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            dense
            color="#2759A2"
            class="global-inputs poppins mb-5"
            maxlength="85"
            persistent-placeholder
            @keypress="formatEmail"
          >
          <template slot="label">
            <span>Correo electrónico <span class="color-red">*</span></span>
          </template>
        </v-text-field>
        </v-col>
      </v-row>
      <!-- Start: phone component -->
      <country-code-input-component
        class="mb-5"
        :bObligatoryField="false"
        @setCodigoPais="setCodigoPais"
        @setCodigoArea="setCodigoArea"
        @setNumeroTelefono="setNumeroTelefono"
        @setExtension="setExtension"
        :_bDialogAdd="bDialogAdd"
        :_codigoPais="codigoPais"
        :_codigoArea="codigoArea"
        :_numeroTelefono="numeroTelefono"
        :_extension="extension"
        :_type="false"
      />
      <!-- End: phone component -->
    </div>
    <PermissionsComponentGlobal
      @setbAddPermissions="setbAddPermissions"
      @setObjectPermissions="setObjectPermissions"
      :bBoolean="bBoolean"
      @setBoolean="setBoolean"
      :bGetPermissions="true"
    />
    <div class="content-buttons">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="12" sm="5">
            <div
              class="display-flex align-items-center justify-content-flex-start"
            >
              <v-btn
                @click="$router.push({ name: 'administrators' })"
                class="button-secondary poppins btn-back"
                elevation="0"
                width="100%"
              >
                Regresar
              </v-btn>
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="5">
            <div
              class="display-flex align-items-center justify-content-flex-end"
            >
              <v-btn
                @click="add"
                :loading="bLoading"
                :disabled="!validationForm"
                class="button-primary poppins btn-add"
                elevation="0"
                width="100%"
              >
                Añadir
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutAdministratorsAdd",
  props: {
    bDialogAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      screenWidth: 0,
      nombre: "",
      apellido: "",
      correo: "",
      puesto: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      bLoading: false,
      bBoolean: false,
      bActive: false,
      oPermissions: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.codigoPais = "52";
  },
  methods: {
    setObjectPermissions(oPermissions) {
      this.oPermissions = oPermissions;
    },
    setbAddPermissions(bActive) {
      this.bActive = bActive;
    },
    setBoolean(bBoolean) {
      this.bBoolean = bBoolean;
    },
    setCodigoPais: function (e) {
      this.codigoPais = e;
    },
    setCodigoArea: function (e) {
      this.codigoArea = e;
    },
    setNumeroTelefono: function (e) {
      this.numeroTelefono = e;
    },
    setExtension: function (e) {
      this.extension = e;
    },
    add: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                sEmail: this.correo,
                sPhoneNumber: this.numeroTelefono,
                sJobTitle: this.puesto,
                sName: this.nombre,
                sLastname: this.apellido,
                sAreaCallingCode: this.codigoArea,
                sCountryCallingCode: this.codigoPais,
                sPhoneExtension: this.extension,
                aAdministratorPermissions:
                  this.oPermissions.aPermissionsEdit.map((e) => {
                    return {
                      sAdministratorModuleId: e.sModuleId,
                      aPermissions: [
                        {
                          bBoolean: e.bShow,
                          sPermissionId: e.sShowPermissionId,
                        },
                        {
                          bBoolean: e.bAdmin,
                          sPermissionId: e.sAdminPermissionId,
                        },
                      ],
                    };
                  }),
              };
            DB.post(`${URI}/auth/administrator`, payload, config)
              .then((response) => {
                this.bLoading = false;
                this.closeDialog();
                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.closeDialog();
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.closeDialog();
          this.$store.commit("refresher", true);
        });
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$emit("setDialogAdd", false);
      this.nombre = "";
      this.apellido = "";
      this.correo = "";
      this.puesto = "";
      this.codigoPais = "52";
      this.codigoArea = "";
      this.numeroTelefono = "";
      this.extension = "";
      this.bLoading = false;
      this.$router.push({
        name: "administrators",
      });
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.nombre !== "" &&
        this.apellido !== "" &&
        this.correo !== "" &&
        // this.codigoArea !== "" &&
        // this.numeroTelefono !== "" &&
        this.bActive !== false
      );
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.btn-add,
.btn-back {
  width: 200px !important;
}

.content-inputs {
  /* margin-top: 50px; */
}

@media (max-width: 600px) {
  .btn-add,
  .btn-back {
    width: 100% !important;
  }
}
</style>

<template>
  <div class="content-image">
    <img
      src="@/assets/images/login-sims.png"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: "LayoutForgotPasswordBackground",
};
</script>

<style scoped>
.content-image {
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(265deg, #79defc 0%, #2759a2 100%) 0% 0% no-repeat padding-box !important;

}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>
<template>
  <div fluid>
    <!-- <v-data-table :headers="aHeader" :items="aPaid" :hide-default-footer="true"
      no-data-text="No hay información" class="elevation-1 mt-5">
      <template v-slot:[`item.sActions`]="{ item }">
        <v-btn @click="editItem(item)" icon>
          <v-icon color="#000000"> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-divider class="divider-vertical"></v-divider>

        <div class="content-add-consumption">
          <v-btn elevation="0" icon class="button-secondary btn-add-additional-product">
            Añadir pago
          </v-btn>
        </div>
      </template>
    </v-data-table> -->
    <v-simple-table v-if="!bMobile" class="mt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(item, i) in aHeader" :key="i"
              :class="i === 2 ? bAddPaid ? item.sClassHeader : 'w-220 txt-color-black-global' : item.sClassHeader">
              {{ item.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in aPaid" :key="index">
            <td>
              <div v-if="item.bEdit">
                <v-text-field v-model="sDateEdit" color="#A1ACC3" class="rounded-lg" :hide-details="true" outlined dense
                  placeholder="Fecha">
                </v-text-field>
              </div>
              <div v-else>
                {{ item.tPaidAt }}
              </div>
            </td>
            <td>
              <div v-if="item.bEdit">
                <v-text-field v-model="sDescriptionEdit" color="#A1ACC3" class="rounded-lg" :hide-details="true"
                  outlined dense placeholder="Descripción">
                </v-text-field>
              </div>
              <div v-else>
                {{ item.sDescription }}
              </div>
            </td>
            <td>
              <div v-if="item.bEdit">
                <input-currency-global :flagModal="item.bEdit" labelGlobal=""
                  classDynamic="global-inputs poppins width-100" :bOutlined="true" :bDense="true"
                  :bImportantData="false" @changeMontoGlobal="changeQuantityEdit" :modelValue="iQuantityEdit"
                  sPrefix="$" />
              </div>
              <div v-else>
                ${{  formatMoneyGlobal(item.dAmount)  }} MXN
              </div>
            </td>
            <td>
              <div v-if="item.bEdit">
                <input-currency-global :flagModal="item.bEdit" labelGlobal=""
                  classDynamic="global-inputs poppins width-100" :bOutlined="true" :bDense="true"
                  :bImportantData="false" @changeMontoGlobal="changeQuantityEdit" :modelValue="iQuantityEdit"
                  sPrefix="$" />
              </div>
              <div v-else>
                <!-- {{ aPaymentMethods.filter((e) => e.sPaymentMethodId != item.sPaymentId )  }} -->
                {{  item.oPaymentMethod.sName   }}

              </div>

            </td>
          </tr>
          <tr v-if="aPaid.length === 0">
            <td colspan="4" class="text-center">
              <span>Sin información</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="bAddPaid">
            <td>
              <div class="d-flex pt-2 pb-2">
                <date-picker v-model="sDate" valueType="format" placeholder="Fecha" :use12h="false" language="es"
                  type="datetime"></date-picker>
                <!-- <v-text-field v-model="sDate" color="#A1ACC3" class="rounded-lg" :hide-details="true" outlined dense
                  placeholder="Fecha">
                </v-text-field> -->
              </div>
            </td>
            <td>
              <div class="d-flex pt-2 pb-2">
                <v-text-field v-model="sDescription" color="#A1ACC3" class="rounded-lg" :hide-details="true" outlined
                  dense placeholder="Descripción">
                </v-text-field>
              </div>
            </td>
            <td>
              <div class="d-flex pt-2 pb-2">
                <input-currency-global :flagModal="bAddPaid" labelGlobal=""
                  classDynamic="global-inputs poppins width-100" :bOutlined="true" :bDense="true"
                  :bImportantData="false" @changeMontoGlobal="changeQuantity" :modelValue="iQuantity" sPrefix="$"
                  sSuffix="MXN" />
              </div>
            </td>
            <td>
              <div class="pt-6 d-flex">
                <v-select v-model="sPaymentMethodId" item-value="sPaymentMethodId" item-text="sName"  :items="aPaymentMethods" placeholder="Método de pago" dense outlined></v-select>
                <v-btn v-if="getPermissionsActionsGlobal()" elevation="0" @click="addPaid" :disabled="!validationForm" icon
                  class="button-primary btn-add-additional-product ml-5">
                  Añadir
                </v-btn>
              </div>
            </td>

            <!-- <td>
              <div>
                <v-btn elevation="0" @click="addPaid" :disabled="!validationForm" icon
                  class="button-primary btn-add-additional-product ml-5">
                  Añadir
                </v-btn>
              </div>
            </td> -->
          </tr>
          <tr v-else>
            <td colspan="4">
              <div class="content-add-consumption">
                <v-btn v-if="getPermissionsActionsGlobal()" elevation="0" @click="setAddItem" icon class="button-secondary btn-add-additional-product">
                  Añadir pago
                </v-btn>
              </div>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <div v-else class="mt-3">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col v-for="(item, index) in aPaid" :key="index" cols="12" :sm="aPaid.length === 1 ? 12 : 6">
            <div class="content-card-responsive  position-relative">
              <div class="height-100">
                <div>
                  <div class="content-first-row-card">
                    <div class="content-text-title">
                      <p class="txt-item-table-card-title poppins mb-3">
                        {{ item.tPaidAt }}
                      </p>
                    </div>
                    <!-- <div class="content-menu-actions">
                      <v-menu offset-y content-class="content-menu-options" attach left>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="content-actions">

                            <div v-bind="attrs" v-on="on" class="content-menu-options-dots">
                              <v-icon color="#000" size="20px">
                                mdi-dots-vertical
                              </v-icon>
                            </div>
                          </div>
                        </template>
                        <div class="circle-content-options">
                          <div>
                            <v-list dense>
                              <v-list-item link @click="fillItemEdit(item), setDialogAddOrEditPaid(false)">
                                <v-list-item-icon>
                                  <v-icon color="#000" size="14px">
                                    mdi-pencil
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="poppins">
                                  Editar
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </div>
                      </v-menu>
                    </div> -->
                  </div>
                  <div class="flex-wrap display-flex align-items-center">
                    <div class="content-left">
                      <p class="px-txt-card-title mb-0 mr-2">Método de pago</p>
                    </div>
                    <div class="content-right">
                      <p class="px-txt-card-item mb-0">{{
                          item.oPaymentMethod.sName
                      }}</p>
                    </div>

                  </div>
                  <div class="flex-wrap display-flex align-items-center">
                    <div class="content-left">
                      <p class="px-txt-card-title mb-0 mr-2">Cantidad</p>
                    </div>
                    <div class="content-right">
                      <p class="px-txt-card-item mb-0"> ${{
                          formatMoneyGlobal(item.dAmount)
                      }} MXN</p>
                    </div>

                  </div>
                  <div class=" display-flex align-items-center">
                    <div class="content-left">
                      <p class="px-txt-card-title mb-0 mr-2">Descripción</p>
                    </div>
                    <div class="content-right">
                      <p class="px-txt-card-item mb-0">
                        {{ item.sDescription }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col v-if="aPaid.length === 0">
            <EmptyContentComponent text="Sin pagos" height="150" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!--#region CONTENIDO DE BOTONES -->
            <div class="content-btns-global">
              <div class="content-btn-edit">
                <v-btn class="button-secondary" width="100%" @click="setDialogAddOrEditPaid(true)">
                  Añadir producto
                </v-btn>

              </div>
            </div>
            <!--#endregion CONTENIDO DE BOTONES -->
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="content-total-all">
      <div class="content-total-paid-pay">
        <div class="content-left-total-all">
          <span class="txt-total-left">Total de la prefactura</span>
        </div>
        <div class="content-right-total-all">
          <span class="txt-total-right">$ {{ oExpense ? formatMoneyGlobal(oExpense.iTotalAmount) : 0 }} MXN</span>
        </div>
      </div>
      <div class="content-total-paid-pay">
        <div class="content-left-total-all">
          <span class="txt-total-left">Total pagado</span>
        </div>
        <div class="content-right-total-all">
          <span class="txt-total-right">$ {{ oExpense ? formatMoneyGlobal(oExpense.iPayed) : 0 }} MXN</span>
        </div>
      </div>

      <div class="content-total">
        <div class="content-left-total">
          <span>Total a pagar</span>
        </div>
        <v-spacer />
        <div class="content-right-total">
          <span>$ {{ oExpense ? formatMoneyGlobal(oExpense.iPending) : 0 }} MXN</span>
        </div>
      </div>
    </div>

    <!--#region CONTENIDO DE BOTONES -->
    <div class="content-btns-global">

      <div class="content-btn-edit">
        <v-btn v-if="getPermissionsActionsGlobal()" class="button-primary" width="100%" @click="finishedInvoice">
          Finalizar factura
        </v-btn>

      </div>
    </div>
    <!--#endregion CONTENIDO DE BOTONES -->
    <dialog-confirm-global :bDialogConfirmGlobal="bDialogConfirmGlobal" :oItem="oItemDialogFinishedInvoice"
      @setDialogConfirmGlobal="finishedInvoice" />

    <DialogAddOrEditPaid :bDialogAddOrEditPaid="bDialogAddOrEditPaid" :bAddOrEdit="bAddOrEdit" :oItem="oItem"
      @setDialogAddOrEditPaid="setDialogAddOrEditPaid" />
  </div>
</template>

<script>
import DialogAddOrEditPaid from "./DialogAddOrEditPaid.vue";


export default {
  props: {
    items: Object,
    oExpense: Object,
  },
  data() {
    return {
      aHeader: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'sFolio',
          sClassHeader: "txt-color-black-global w-250"

        },
        {
          text: 'Descripción',
          sortable: false,
          value: 'sDescription',
          sClassHeader: "txt-color-black-global"
        },
        {
          text: 'Cantidad',
          sortable: false,
          value: 'iQuantity',
          sClassHeader: "txt-color-black-global w-200"
        },
        {
          text: 'Método de pago',
          sortable: false,
          value: 'sPaymentMethodId',
          sClassHeader: "txt-color-black-global w-400"
        },
      ],
      aPaid: [],
      bDialogConfirmGlobal: false,
      oItemDialogFinishedInvoice: {},
      bMobile: false,
      sDate: "",
      sDateEdit: "",
      sDescription: "",
      sDescriptionEdit: "",
      iQuantity: 0,
      iQuantityEdit: 0,
      bAddPaid: false,
      bDialogAddOrEditPaid: false,
      bAddOrEdit: false,
      oItem: {},
      aPaymentMethods: [],
      sPaymentMethodId: "",
    }
  },
  beforeMount() {
    this.fullData(this.oExpense);
    this.addPaymentMethod();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 959.99) {
        this.bMobile = false;
        this.bDialogAddOrEditPaid = false;

      }
      else {
        this.bMobile = true;
        this.bAddPaid = false;
        for (let i = 0; i < this.aPaid.length; i++) {
          this.aPaid[i].bEdit = false;
        }
        this.iQuantity = 0;
      }
    },
    changeQuantity(value) {
      if (value !== "") {
        this.iQuantity = parseFloat(value);
      }
      else {
        this.iQuantity = 0;
      }
    },
    changeQuantityEdit(value) {
      if (value !== "") {
        this.iQuantityEdit = parseFloat(value);
      }
      else {
        this.iQuantityEdit = 0;
      }
    },
    setEditItem(item) {
      this.bAddPaid = false;
      for (let i = 0; i < this.aPaid.length; i++) {
        this.aPaid[i].bEdit = false;
      }
      item.bEdit = true;
      this.sDescriptionEdit = item.sDescription;
      this.iQuantityEdit = item.iQuantity;
      this.iPriceEdit = item.iPrice;

    },
    editItem(item) {

    },
    setAddItem() {
      for (let i = 0; i < this.aPaid.length; i++) {
        this.aPaid[i].bEdit = false;
      }
      this.bAddPaid = true;
    },
    addPaymentMethod(){
      DB.get(`${URI}/payment-methods`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aPaymentMethods = response.data.result.Array.map((e)=>{
            return{
              ...e,
              sPaymentMethodId: e.sPaymentMethodId,
              sName: e.sName,

          }});
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    addPaid() {
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            }, payload = {
              tPaidAt: this.sDate,
              sDescription: this.sDescription,
              dAmount: this.iQuantity,
              sPaymentMethodId: this.sPaymentMethodId,
            };
            DB.post(`${URI}/advance-invoices/${this.$route.params.id}/payments`, payload, config)
              .then((response) => {

                this.bLoading = false;
                this.bAddPaid = false;
                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {

                this.bLoading = false;
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.bLoading = false;
            this.bAddPaid = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoading = false;
          this.bAddPaid = false;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        })
    },
    fullData(oExpense) {
      this.aPaid = oExpense.aPayments;
      // DB.get(`${URI}/advance-invoice/${this.$route.params.id}/payments`, {
      //   headers: {
      //     Authorization: `Bearer ${this.$store.state.sToken}`,
      //   },
      //   params: {},
      // })
      //   .then((response) => {
      //   })
      //   .catch((error) => {
      //     this.mixError(error.response.data.message, error.response.status);
      //   });
      //this.aPaid = 
      // [
      //   {
      //     sAdvanceInvoiceId: 0,
      //     sDate: "01/01/2022",
      //     sDescription: "5Mb global / RENTA SERVICIO",
      //     iQuantity: 10,
      //   },
      //   {
      //     sAdvanceInvoiceId: 1,
      //     sDate: "01/01/2022",
      //     sDescription: "5Mb global / EXCEDENTE DE DATOS MB kjdhkajhkas daskjdbkas aksjdbkasd ksdjbaksd kasjdbkas",
      //     iQuantity: 34.1,
      //   }
      // ]
      // this.aSubtotal = [
      //   {
      //     sName: "Consumo",
      //     iAmount: 999999999.99
      //   },
      //   {
      //     sName: "Productos adicionales",
      //     iAmount: 999999999.99
      //   },
      //   {
      //     sName: "Descuentos",
      //     iAmount: 999999999.99
      //   },
      //   {
      //     sName: "Impuestos",
      //     iAmount: 999999999.99
      //   },
      //   {
      //     sName: "Total",
      //     iAmount: 999999999.99
      //   }
      // ];

    },
    finishedInvoice() {
      let oItem = {
        sTitleDialog: "Finalizar factura",
        sQuestion: `<span>¿Estas seguro de finalizar la prefactura ${this.items.sFolio}?</span><br/><span>Al realizar esta acción no podrá revertirse</span>`,
        sDoubleConfirmation: true,
        sConfirmation: `Acepto la responsabilidad y deseo proseguir con la finalización de la prefactura: ${this.items.sFolio}`,
        sApi: "",
        sNameBtnAction: "Finalizar",
      }
      this.oItemDialogFinishedInvoice = oItem;
      this.bDialogConfirmGlobal = !this.bDialogConfirmGlobal
    },
    setDialogAddOrEditPaid(bAddOrEdit) {
      this.bAddOrEdit = bAddOrEdit;
      this.bDialogAddOrEditPaid = !this.bDialogAddOrEditPaid;
    }
  },
  computed: {
    validationForm: function () {
      return (this.sDate !== "" && this.sDescription !== "" &&
        this.iQuantity > 0 && this.sPaymentMethodId !== "");
    },
    validationFormEdit: function () {
      return (this.sDate !== "" && this.sDescription !== "" &&
        this.iQuantity > 0 && this.sPaymentMethodId !== "");
    },
  },
  watch: {
    sDate() {
    },
    oExpense(){
      this.fullData(this.oExpense);
    }
  },
  components: { DialogAddOrEditPaid, }
}
</script>

<style scoped>
.information-title {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 2px;
  width: 200px;
}

.content-add-consumption {
  padding: 10px;
}

.btn-add-additional-product {
  width: 150px;
}

.content-total-all {
  margin-top: 15px;
  margin-left: 1px;
  margin-bottom: 1px;
  height: 180px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.content-total-paid-pay {
  padding: 15px;
  display: flex;

}

.content-left {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.content-right {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.content-left-total-all {
  width: 100%;
}

.content-right-total-all {
  width: 100%;
  text-align: end;
}

.content-total {
  display: flex;
  background-color: #D8E5FF;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
}

.txt-total-left {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.txt-total-right {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}



/*#region STYLES CARDS */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  overflow-wrap: break-word !important;

}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.content-text-title {
  width: 90%;
  max-width: 90%;
  min-width: auto;
}

.content-actions {
  display: flex;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  margin-left: 10px;
}

.content-first-row-card {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}



.content-status-mobile-card {
  width: 70px;
  display: flex;
  /* position: absolute; */
  right: 30px;
  top: 10px;
}


.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}

.content-left {
  width: 90px;
  display: flex;
  height: 100%;
  align-self: start
}

/*#endregion STYLES CARDS */



/*#region GLOBAL CONTENT BTN */
.content-btns-global {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.content-btn-edit {
  width: 150px;
}


/*#endregion GLOBAL CONTENT BTN */
/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }


  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
<template>
  <div class="mt-9">
    <layout-detail-sims-accept-request :itemsSims="itemsSims" />
    <layout-detail-sims-order-information :itemsSims="itemsSims" />
    <layout-detail-sims-delivery-data :itemsSims="itemsSims" />
  </div>
</template>

<script>
import LayoutDetailSimsAcceptRequest from "@/layouts/DetailSims/AcceptRequest.vue";
import LayoutDetailSimsOrderInformation from "@/layouts/DetailSims/OrderInformation.vue";
import LayoutDetailSimsDeliveryData from "@/layouts/DetailSims/DeliveryData.vue";

export default {
  name: "LayoutSimsContent",
  props: {
    itemsSims: {
      type: Object,
    },
  },
  components: {
    LayoutDetailSimsAcceptRequest,
    LayoutDetailSimsOrderInformation,
    LayoutDetailSimsDeliveryData,
  },
};
</script>

<style scoped>
.border-line {
  border-top: 1px solid #a1acc3;
}
</style>
<template>
  <div class="content-search mt-9">
    <div class="content-filter-select">
      <v-select
        v-model="sFilterType"
        label="Buscar por"
        placeholder="Buscar por"
        dense
        color="#2759A2"
        class="global-auth-inputs poppins"
        persistent-placeholder
        :items="aFilterType"
        item-text="sName"
        item-value="sId"
        clearable
        @change="selectFilterType()"
      ></v-select>
    </div>
    <div class="content-filter-text-field">
      <v-select
        v-if="sFilterType == 5 || sFilterType == 3"
        v-model="sSearch"
        :label="sLabelSelect"
        :placeholder="sPlaceholderSelect"
        dense
        color="#2759A2"
        class="global-auth-inputs poppins"
        persistent-placeholder
        :items="aItemsSelect"
        item-text="sName"
        item-value="sId"
        clearable
        @change="selectFilterType()"
        :disabled="sFilterType === null"
        no-data-text="No hay datos disponibles"
      ></v-select>
      <v-text-field
        v-else
        v-model="sSearch"
        :disabled="sFilterType === null"
        label="Buscar"
        placeholder="Buscar"
        dense
        color="#2759A2"
        class="global-inputs poppins"
        background-color="transparent"
        persistent-placeholder
        clearable
      >
      </v-text-field>
    </div>
    <div class="content-btn-search">
      <v-btn
        :disabled="sSearch === '' || sSearch === null"
        class="button-primary btn-search poppins"
        elevation="0"
        @click="setFilterSim()"
      >
        Buscar</v-btn
      >
    </div>
    <v-spacer></v-spacer>
    <v-btn
      elevation="0"
      :loading="bLoadingExcel"
      class="button-secondary btn-export"
      @click="getExcel()"
    >
      Exportar Excel
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutSimCardsSearchbar",
  data() {
    return {
      sSearch: "",
      resultPlanDatos: undefined,
      items: [],
      sFilterType: this.$store.state.sFilterTypeGlobal,
      sFilterTypeLast: null,
      aFilterType: [
        {
          sName: "ICC",
          sId: 1,
        },
        {
          sName: "IMEI",
          sId: 2,
        },
        {
          sName: "Plan de datos",
          sId: 3,
        },
        {
          sName: "ID",
          sId: 4,
        },
        {
          sName: "Estado",
          sId: 5,
        },
      ],
      sSearch: "",
      oPayload: {},
      aItemStatus: [
        {
          sName: "Inactiva nueva",
          sId: "INACTIVE_NEW",
        },
        {
          sName: "Prueba",
          sId: "TEST",
        },
        {
          sName: "Lista para activar",
          sId: "ACTIVATION_READY",
        },
        {
          sName: "Activa",
          sId: "ACTIVE",
        },
        {
          sName: "Desactivada",
          sId: "DEACTIVATED",
        },
      ],
      aItemPlans: [],
      bLoadingExcel: false,

      sLabelSelect: "",
      sPlaceholderSelect: "",
      aItemsSelect: [],
    };
  },
  beforeMount() {
    this.sSearch = this.sSearchGlobal;
    if (this.sSearchGlobal !== "" && this.sFilterTypeGlobal !== null) {
      this.setFilterSim();
    }
    this.getPlans();

  },
  methods: {
    getExcel: function () {
      this.bLoadingExcel = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/sim-cards/excel`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              this.bLoadingExcel = false;
              window.open(response.data.results, "blank_");
            })
            .catch((error) => {
              this.bLoadingExcel = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.bLoadingExcel = false;
          this.mixError(error.message, error.code);
        });
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    getData: function () {
      DB.get(`${URI}/plans`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.items = response.data.plans;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectFilterType() { },
    getPlans() {
      DB.get(
        `${URI}/plans`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.items = response.data.results.map((e) => {
            return {
              sName: e.sName,
              sId: e.sPlanId,
            };
          });

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setFilterSim() {
      switch (this.sFilterType) {
        case 1:
          this.oPayload = {
            sICC: this.sSearch.trim(),
          };
          break;
        case 2:
          this.oPayload = {
            sIMEI: this.sSearch.toString().replaceAll("-", "").trim(),
          };
          break;
        case 3:
          this.oPayload = {
            sDataPlan: this.sSearch.trim(),
          };
          break;
        case 4:
          this.oPayload = {
            sId: this.sSearch.trim(),
          };
          break;
        case 5:
          this.oPayload = {
            sLifeCycleStatus: this.sSearch.trim(),
          };
          break;

        default:
          break;
      }
      this.$emit("setPayload", this.oPayload);
      this.$store.commit("setFilterTypeGlobal", this.sFilterType);
      this.$store.commit("setSearchGlobal", this.sSearch);
    },
  },
  computed: {
    sFilterTypeGlobal() {
      return this.$store.state.sFilterTypeGlobal;
    },
    sSearchGlobal() {
      return this.$store.state.sSearchGlobal;
    },
  },
  watch: {
    resultPlanDatos: function () {
      this.$emit("setPlanId", this.resultPlanDatos);
    },
    sFilterType() {
      // this.$store.commit("setFilterTypeGlobal", this.sFilterType)
      if (this.sFilterType !== this.sFilterTypeLast) {
        this.sSearch = "";
      }
      this.sFilterTypeLast = this.sFilterType;
      if (this.sFilterType === null) {
        this.sSearch = "";
        this.$store.commit("setFilterTypeGlobal", null);
        this.$store.commit("setSearchGlobal", "");
      }

      if (this.sFilterType === 3) {
        this.sLabelSelect = "Plan de datos";
        this.sPlaceholderSelect = "Seleccione el plan de datos";
        this.aItemsSelect = this.items;

      } else if (this.sFilterType === 5) {
        this.sLabelSelect = "Estado";
        this.sPlaceholderSelect = "Seleccione el estado";
        this.aItemsSelect = this.aItemStatus;
      }
    },
    sSearch() {
      // this.$store.commit("setSearchGlobal", this.sSearch)
      if (this.sSearch === "" || this.sSearch === null) {
        this.$emit("setPayload", {});
        this.$store.commit("setFilterTypeGlobal", null);
        this.$store.commit("setSearchGlobal", "");
      }
    },
  },
};
</script>

<style scoped>
.btn-export {
  width: 140px;
  height: 45px;
}
.content-search {
  display: flex;
  width: 100%;
}
.content-filter-select {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  margin-right: 20px;
}

.content-filter-text-field {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  margin-right: 20px;
}

.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search {
    display: block;
    width: 100%;
  }
  .btn-export {
    width: 100%;
    margin-top: 15px;
  }
  .content-filter-select {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .content-filter-text-field {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .content-btn-search {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .btn-search {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-search {
    display: flex;
    width: 100%;
  }

  .content-filter-select {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    margin-right: 20px;
  }

  .content-filter-text-field {
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    margin-right: 20px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>

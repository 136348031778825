<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      <div v-if="!activeCard" style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item_header, index_header) in headers" :key="index_header" :class="item_header.align"
                  class="table-header-text poppins">
                  {{ item_header.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index" @click="viewItem(item)">
                <td class="txt-item-table text-left poppins cursor-pointer">
                  {{ item.sPublicName }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer">
                  {{ item.created_at }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer">
                  {{ item.planName }}
                </td>
                <td class="txt-item-table text-center poppins cursor-pointer">
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                    ">
                    <span class="txt-green">{{
                        item.iLargeSIMsQuantityApproved
                    }}</span>/{{ item.iLargeSIMsQuantityRequired }}
                  </div>
                  <span class="txt-green">Aprobadas</span>
                </td>
                <td class="txt-item-table text-center poppins cursor-pointer">
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                    ">
                    <span class="txt-green">{{
                        item.iMediumSIMsQuantityApproved
                    }}</span>/{{ item.iMediumSIMsQuantityRequired }}
                  </div>
                  <span class="txt-green">Aprobadas</span>
                </td>
                <td class="txt-item-table text-center poppins cursor-pointer">
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                    ">
                    <span class="txt-green">{{
                        item.iSmallSIMsQuantityApproved
                    }}</span>/{{ item.iSmallSIMsQuantityRequired }}
                  </div>
                  <span class="txt-green">Aprobadas</span>
                </td>
                <td :style="{
                  color: `${getColorStatus(item).sColorText}`,
                  fontWeight: '600',
                }" class="txt-item-table text-left poppins cursor-pointer">
                  {{ item.sLastStatusName }}
                </td>
                <td class="txt-item-table text-center global-col-body-accion poppins cursor-pointer">
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                    ">
                    <v-btn icon>
                      <v-icon color="#000" size="16px">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                    <!-- <v-btn @click="deleteItem(item.sSIMRequestId)" icon>
                      <v-icon  size="16px">
                        mdi-close
                      </v-icon>
                    </v-btn> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- pagination -->
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                <div class="content-card-responsive position-relative">
                  <v-menu offset-y content-class="content-menu-options" attach left>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="content-menu-options-dots-global">
                        <v-icon color="#000" size="20px">
                          mdi-dots-vertical
                        </v-icon>
                      </div>
                    </template>
                    <div class="circle-content-options">
                      <div>
                        <v-list dense>
                          <v-list-item link @click="viewItem(item)">
                            <v-list-item-icon>
                              <v-icon color="#000" size="14px">
                                mdi-eye-outline
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="poppins">
                              Detalle
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-menu>
                  <div class="display-flex align-items-center">
                    <p class="txt-item-table-card-title poppins mb-0">
                      {{ item.sPublicName }}
                    </p>
                  </div>
                  <div class="display-flex">
                    <p class="txt-item-table-card-item poppins mb-0">
                      {{ item.planName }}
                    </p>
                    <v-spacer />
                    <div>
                      <p class="txt-item-table-card-item poppins mb-0"
                        :style="{ color: `${getColorStatus(item).sColorText}` }">
                        {{ item.sLastStatusName }}
                      </p>
                      <p class="txt-item-table-card-item poppins mb-0">
                        {{ item.created_at }}
                      </p>
                    </div>
                  </div>
                  <v-container class="pa-0 mt-4" fluid>
                    <v-row>
                      <v-col cols="4" sm="4">
                        <div class="text-center">
                          <div class="
                              display-flex
                              align-items-center
                              justify-content-center
                              mb-n2
                            ">
                            <span class="txt-item-table-card-item txt-green">{{
                                item.iLargeSIMsQuantityApproved
                            }}</span>
                            /
                            <span class="txt-item-table-card-item">{{
                                item.iLargeSIMsQuantityRequired
                            }}</span>
                          </div>
                          <span class="txt-item-table-card-item txt-green">Aprobadas</span>
                          <p class="txt-item-table-card-title">Estándar</p>
                        </div>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <div class="text-center">
                          <div class="
                              display-flex
                              align-items-center
                              justify-content-center
                              mb-n2
                            ">
                            <span class="txt-item-table-card-item txt-green">{{
                                item.iMediumSIMsQuantityApproved
                            }}</span>
                            /
                            <span class="txt-item-table-card-item">{{
                                item.iMediumSIMsQuantityRequired
                            }}</span>
                          </div>
                          <span class="txt-item-table-card-item txt-green">Aprobadas</span>
                          <p class="txt-item-table-card-title">Micro</p>
                        </div>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <div class="text-center">
                          <div class="
                              display-flex
                              align-items-center
                              justify-content-center
                              mb-n2
                            ">
                            <span class="txt-item-table-card-item txt-green">{{
                                item.iSmallSIMsQuantityApproved
                            }}</span>
                            /
                            <span class="txt-item-table-card-item">{{
                                item.iSmallSIMsQuantityRequired
                            }}</span>
                          </div>
                          <span class="txt-item-table-card-item txt-green">Aprobadas</span>
                          <p class="txt-item-table-card-title">Nano</p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div class="content-pagination mt-4" style="background-color: transparent">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import lodash from "lodash";
import store from "../../store";

export default {
  name: "LayoutSimsContent",
  props: {
    sSearch: String,
    sPlanId: String,
    arrDates: Array,
    statusId: String,
  },
  data() {
    return {
      bLoading: false,
      items: [],
      headers: [
        {
          title: "Cliente",
          align: "text-left",
        },
        {
          title: "Fecha",
          align: "text-left",
        },
        {
          title: "Plan de datos",
          align: "text-left",
        },
        {
          title: "Estándar",
          align: "text-center",
        },
        {
          title: "Micro",
          align: "text-center",
        },
        {
          title: "Nano",
          align: "text-center",
        },
        {
          title: "Estado",
          align: "text-left",
        },
        {
          title: "Acciones",
          align: "text-center global-col-header-accion",
        },
      ],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/sim-requests/${id}`,
        title: "Eliminar solicitud",
        description:
          "¿Estás seguro de eliminar esta solicitud? <br/> Al realizar esta <br/> acción no podrá revertirse",
      };
      this.$emit("setOptions", options);
    },
    viewItem: function (item) {
      this.$router.push({
        name: "detailSims",
        params: {
          id: item.sSIMRequestId,
        },
      });
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    formatDate: function (date) {
      return MOMENT(date).locale("es").format("DD/MMM/YYYY");
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    getData: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/sim-requests`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {
              iPageNumber: this.iCurrentPage,
              sSearch: this.sSearch,
              iItemsPerPage: this.iItemsPerPage,
              bPlatformAccess: this.sStatus,
              sPlanId: this.sPlanId,
              tStartDate: this.arrDates[0],
              tEndDate: this.arrDates[1],
              sSIMRequestStatusId: this.statusId,
            },
          })
            .then((response) => {
              this.bLoading = false;
              this.items = response.data.results;

              this.numPages = response.data.iNumPages;
              this.iPageNumber = response.data.iNumPages;
              this.totalItems = response.data.iTotal;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });

    },
    getColorStatus(sStatus) {
      let oColors = {};
      switch (sStatus.lastStatus) {
        case "7f595ba0-3275-435f-a48e-4dfaa811702a": //Solicitud enviada
          oColors.sColorText = "#2C9DCE"
          oColors.sColorBackground = "#C0E2F0"
          return oColors;
        case "cd495340-fa3b-4234-89cd-b56c59db318d": //Evaluando
          oColors.sColorText = "#E7C127"
          oColors.sColorBackground = "#F8EDBE"

          return oColors;
        case "5834ff8c-0c17-42ee-afd1-5a84163363e8": //Confirmado
          oColors.sColorText = "#8E4DFF"
          oColors.sColorBackground = "#DDCAFD"
          return oColors;
        case "2a94cfb4-b4f1-420d-a513-c3a368946a48": //Enviado
          oColors.sColorText = "#68BBDF"
          oColors.sColorBackground = "#D2EBF6"

          return oColors;
        case "8ee1565e-ee98-462d-a445-b1d7248dbaed": //Entregado
          oColors.sColorText = "#1EA966"
          oColors.sColorBackground = "#BCE5D1"

          return oColors;
        case "875c3c08-cf5a-424b-988b-e724d33e4e8b": //Cancelado
          oColors.sColorText = "#D77580"
          oColors.sColorBackground = "#F3D6D9"

          return oColors;
        case "e6ea266d-ed35-40f4-9f06-98cffb846c9b": //Reposición
          oColors.sColorText = "#424657"
          oColors.sColorBackground = "#C6C8CD"

          return oColors;

        default:
          break;
      }
    }
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },

  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },
    sSearch: lodash.debounce(function () {
      this.getData();
    }, 500),
    sPlanId: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    statusId: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    arrDates: function () {
      this.getData();
    },
  },
};
</script>

<style scoped>
.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  opacity: 1;
}

.text-delete-card {
  /* color: #e33f3f; */
}
</style>
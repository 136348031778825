<template>
  <div class="mt-4">
    <div class="content-txt-title-section poppins">Información del pedido</div>
    <div class="mt-4">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4">
            <div class="display-flex align-items-center">
              <v-select v-model="sPlanIdEdit" @change="listenChanges()" :loading="bLoadingSelectPlan" label="Plan solicitado"
                placeholder="Plan solicitado" dense color="#2759A2" class="global-auth-inputs poppins"
                persistent-placeholder :items="aPlans" item-text="sName" item-value="sCustomPlanId"
                no-data-text="No hay datos disponibles" :disabled="!getPermissionsActionsGlobal()">
              </v-select>
              <!-- <p class="txt-title-def poppins mb-0">Plan solicitado</p>
              <div class="content-txt-plan-descripcion poppins ml-2">
                {{ sPlanName }}
              </div> -->
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <div class="display-flex">
              <p class="txt-title-def poppins mb-0">Descripción del plan</p>
              <p class="content-txt-plan-descripcion poppins mb-0 ml-2">
                {{ sPlanDescription }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- desktop content card -->
    <div v-if="!isCard" class="card-content-desktop mt-4 pb-2">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="4">
            <div class="pl-5 pr-5 pb-2 pt-0">
              <p class="table-header-text poppins mb-0">
                Tarjeta SIM solicitada
              </p>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="pl-5 pr-5 pb-2 pt-0">
              <p class="table-header-text poppins mb-0">Cantidad solicitada</p>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="pl-5 pr-5 pb-2 pt-0">
              <p class="table-header-text poppins mb-0">Cantidad aprobada</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="border-bottom-line ml-5 mr-5 mb-0" />
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5">
              <p class="txt-item-table text-left poppins mb-0">SIM Estándar</p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5">
              <p class="txt-item-table text-left poppins mb-0">
                {{ itemsSims.iLargeSIMsQuantityRequired }} Unidades
              </p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5 display-flex align-items-center">
              <div style="width: 50px">
                <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simStandarAproved" type="text" dense
                  color="#2759A2" class="global-inputs poppins" maxlength="5" persistent-placeholder
                  @keypress="soloNumbers" @keyup="listenChanges" hide-details :disabled="fCurrentStatus()">
                </v-text-field>
                <span class="txt-item-table text-left poppins" v-else> {{ simStandarAproved }}</span>
              </div>
              <span class="txt-item-table text-left poppins mb-0 ml-2">Unidades</span>
            </div>
          </v-col>
        </v-row>
        <div class="border-bottom-line-body mb-2 mt-2" />
        <v-row>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5">
              <p class="txt-item-table text-left poppins mb-0">SIM Micro</p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5">
              <p class="txt-item-table text-left poppins mb-0">
                {{ itemsSims.iMediumSIMsQuantityRequired }} Unidades
              </p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5 display-flex align-items-center">
              <div style="width: 50px">
                <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simMicroAproved" type="text" dense color="#2759A2"
                  class="global-inputs poppins" maxlength="5" persistent-placeholder @keypress="soloNumbers"
                  @keyup="listenChanges" hide-details :disabled="fCurrentStatus()"></v-text-field>
                <span class="txt-item-table text-left poppins" v-else> {{ simMicroAproved }}</span>

              </div>
              <span class="txt-item-table text-left poppins mb-0 ml-2">Unidades</span>
            </div>
          </v-col>
        </v-row>
        <div class="border-bottom-line-body mb-2 mt-2" />
        <v-row>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5 ">
              <p class="txt-item-table  text-left poppins mb-0">SIM Nano</p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5">
              <p class="txt-item-table text-left poppins mb-0">
                {{ itemsSims.iSmallSIMsQuantityRequired }} Unidades
              </p>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <div class="pl-5 pr-5 display-flex align-items-center">
              <div style="width: 50px">
                <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simNanoAproved" type="text" dense color="#2759A2"
                  class="global-inputs poppins" maxlength="5" persistent-placeholder @keypress="soloNumbers"
                  @keyup="listenChanges" hide-details :disabled="fCurrentStatus()"></v-text-field>
                <span class="txt-item-table text-left poppins" v-else> {{ simNanoAproved }}</span>

              </div>
              <span class="txt-item-table text-left poppins mb-0 ml-2">Unidades</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- responsive content card -->
    <div v-else class="mt-4">
      <div class="card-content-desktop pb-2 mb-5">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="7">
              <div class="pl-5 pr-5 pt-0">
                <p class="table-header-text poppins mb-0">
                  Tarjeta SIM solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table poppins mb-0">
                SIM Estándar
              </p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table  poppins mb-0">
                {{ itemsSims.iLargeSIMsQuantityRequired }} Unidades
              </p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad aprobada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <div class="pl-5 pr-5 display-flex align-items-center">
                <div style="width: 50px">
                  <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simStandarAproved" type="text" dense
                    color="#2759A2" class="global-inputs poppins" maxlength="5" persistent-placeholder
                    @keypress="soloNumbers" @keyup="listenChanges" hide-details></v-text-field>
                  <!-- <span class="txt-item-table  poppins mb-0 ml-2">{{ simStandarAproved }}</span> -->
                </div>
                <span class="txt-item-table  poppins mb-0 ml-2">Unidades</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="card-content-desktop pb-2 mb-5">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="7">
              <div class="pl-5 pr-5 pt-0">
                <p class="table-header-text poppins mb-0">
                  Tarjeta SIM solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table  poppins mb-0">SIM Micro</p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table  poppins mb-0">
                {{ itemsSims.iMediumSIMsQuantityRequired }} Unidades
              </p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad aprobada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <div class="pl-5 pr-5 display-flex align-items-center">
                <div style="width: 50px">
                  <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simMicroAproved" type="text" dense
                    color="#2759A2" class="global-inputs poppins" maxlength="5" persistent-placeholder
                    @keypress="soloNumbers" @keyup="listenChanges" hide-details></v-text-field>
                  <!-- <span class="txt-item-table  poppins mb-0 ml-2">{{ simMicroAproved }}</span> -->
                </div>
                <span class="txt-item-table  poppins mb-0 ml-2">Unidades</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="card-content-desktop pb-2">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="7">
              <div class="pl-5 pr-5 pt-0">
                <p class="table-header-text poppins mb-0">
                  Tarjeta SIM solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table  poppins mb-0">
                SIM Estándar
              </p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad solicitada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <p class="txt-item-table  poppins mb-0">
                {{ itemsSims.iSmallSIMsQuantityRequired }} Unidades
              </p>
            </v-col>
            <v-col cols="7">
              <div class="pl-5 pr-5 pb-2 pt-0">
                <p class="table-header-text poppins mb-0">
                  Cantidad aprobada
                </p>
              </div>
            </v-col>
            <v-col cols="5">
              <div class="pl-5 pr-5 display-flex align-items-center">
                <div style="width: 50px">
                  <v-text-field v-if="bAdminSIMCardsSolicitud" v-model="simNanoAproved" type="text" dense
                    color="#2759A2" class="global-inputs poppins" maxlength="5" persistent-placeholder
                    @keypress="soloNumbers" @keyup="listenChanges" hide-details></v-text-field>
                  <!-- <span class="txt-item-table  poppins mb-0 ml-2">{{ simNanoAproved }}</span> -->
                </div>
                <span class="txt-item-table  poppins mb-0 ml-2">Unidades</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="content-message">
      <v-checkbox v-model="checkToogleSims" class="checkToogle" color="#f8a130" hide-details label=""
        @change="listenChanges" :disabled="fCurrentStatus()"></v-checkbox>
      Todas las tarjetas SIM deben ir activas a petición del cliente.
    </div>
    <!-- <div class="content-check mt-2">
      <v-checkbox
        v-model="checkToogleSims"
        class="checkToogle"
        color="#2759A2"
        hide-details
        label="Todas las tarjetas SIM deben ir activas a petición del cliente."
        @change="listenChanges"
        :disabled="fCurrentStatus()"
      ></v-checkbox>
    </div> -->
    <div v-if="!activeButtons" class="mt-4">
      <div v-if="bAdminSIMCardsSolicitud" class="content-buttons">
        <v-btn @click="discardChanges" class="button-secondary poppins" elevation="0">
          Descartar cambios
        </v-btn>
        <v-btn :loading="bLoading" @click="edit" :disabled="!validateForm" class="button-primary poppins ml-5"
          elevation="0">
          Guardar cambios
        </v-btn>
      </div>
    </div>
    <div class="border-line mt-5" />
  </div>
</template>

<script>
export default {
  name: "LayoutDetailSimsOrderInformation",
  props: {
    itemsSims: {
      type: Object,
    },
  },
  data() {
    return {
      sCustomerId:"",
      itemsOriginal: {},
      sPlanName: "",
      sPlanDescription: "",
      simStandarAproved: 0,
      simMicroAproved: 0,
      simNanoAproved: 0,
      checkToogleSims: false,
      isCard: false,
      activeButtons: true,
      bLoading: false,
      sPlanIdEdit: "",
      aPlans: [],
      bLoadingSelectPlan: true
    };
  },
  beforeMount() {
    this.sEnterpriseId = this.itemsSims.sEnterpriseId;
    this.sPlanName = this.itemsSims.CustomPlan.sName;
    this.sPlanDescription = this.itemsSims.CustomPlan.sDescription;
    this.simStandarAproved = this.itemsSims.iLargeSIMsQuantityApproved;
    this.simMicroAproved = this.itemsSims.iMediumSIMsQuantityApproved;
    this.simNanoAproved = this.itemsSims.iSmallSIMsQuantityApproved;
    this.checkToogleSims = this.itemsSims.bActiveSIMCards;
    this.itemsOriginal = this.itemsSims;
    this.getPlans();

  },
  created() {
    window.addEventListener("resize", this.hanldeResize);
    this.hanldeResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.hanldeResize);
  },
  methods: {
    fCurrentStatus: function () {
      let status_boolean = false;
      if (
        this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId ==
        "8ee1565e-ee98-462d-a445-b1d7248dbaed"
      ) {
        status_boolean = true;
      } else if (
        this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId ==
        "875c3c08-cf5a-424b-988b-e724d33e4e8b"
      ) {
        status_boolean = true;
      } else if (!this.bAdminSIMCardsSolicitud) {
        status_boolean = true;
      } else {
        status_boolean = false;
      }
      return status_boolean;
    },
    edit: function (value) {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            },
              payload = {
                iSmallSIMsQuantityApproved: this.simNanoAproved === '' ? 0:this.simNanoAproved,
                iMediumSIMsQuantityApproved: this.simMicroAproved === '' ? 0 :this.simMicroAproved,
                iLargeSIMsQuantityApproved: this.simStandarAproved === '' ? 0:this.simStandarAproved,
                bActiveSIMCards: this.checkToogleSims,
                sCustomPlanId:this.sPlanIdEdit
              };

            DB.patch(`${URI}/sim-requests/${this.$route.params.id}`, payload, config)
              .then((response) => {
                this.bLoading = false;
                this.activeButtons = true;

                this.mixSuccess(response.data.message);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.activeButtons = true;
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.activeButtons = true;
          this.$store.commit("refresher", true);
        })
    },
    hanldeResize: function () {
      if (window.innerWidth > 600) {
        this.isCard = false;
      } else {
        this.isCard = true;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    listenChanges: function () {
      this.activeButtons =
        this.simNanoAproved == this.itemsOriginal.iSmallSIMsQuantityApproved &&
        this.simMicroAproved ==
        this.itemsOriginal.iMediumSIMsQuantityApproved &&
        this.simStandarAproved ==
        this.itemsOriginal.iLargeSIMsQuantityApproved &&
        this.sPlanIdEdit === this.itemsOriginal.CustomPlan.sPlanId &&
        this.checkToogleSims == this.itemsOriginal.bActiveSIMCards;
    },
    discardChanges: function () {
      this.activeButtons = true;
      this.simStandarAproved = this.itemsSims.iLargeSIMsQuantityApproved;
      this.simMicroAproved = this.itemsSims.iMediumSIMsQuantityApproved;
      this.simNanoAproved = this.itemsSims.iSmallSIMsQuantityApproved;
      this.checkToogleSims = this.itemsSims.bActiveSIMCards;
    },
    getPlans() {
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            DB.get(`${URI}/enterprises/${this.itemsSims.sEnterpriseId}/custom-plans/dropdown`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {
              },
            })
              .then((response) => {
                this.aPlans = response.data.results;
                this.sPlanIdEdit = this.itemsSims.CustomPlan.sCustomPlanId;
                this.bLoadingSelectPlan = false;
                this.$store.commit("refresher", false);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.bLoadingSelectPlan = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$store.commit("refresher", true);
          }
        }).catch((error) => {
          this.bLoadingSelectPlan = false;
          this.mixError(error.message, error.code);
          this.$store.commit("refresher", true);
        })

    }
  },
  computed: {
    bAdminSIMCardsSolicitud() {
      return this.$store.state.bAdminSIMCardsSolicitud
    },
    validateForm: function () {
      return (
        this.simStandarAproved != "" ||
        this.simMicroAproved != "" ||
        this.simNanoAproved != "" ||
        this.sPlanIdEdit !== null ||
        this.checkToogleSims == !this.itemsOriginal.bActiveSIMCards
      );
    },
  },
};
</script>


<style>
.checkToogle .v-label {
  color: #f8a130 !important;
}

.checkToogle .theme--light.v-icon {
  color: #f8a130;
}

.checkToogle {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.border-line {
  border-top: 1px solid #a1acc3;
}

.content-check {
  border: 1px solid #2759a2;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.border-bottom-line {
  border-bottom: 1px solid #949494;
}

.border-bottom-line-body {
  border-bottom: 1px solid #3A79BD19;
}

.card-content-desktop {
  background-color: #fff;
  box-shadow: 0px 20px 40px #3a79bd19;
  border-radius: 10px;
}

.content-txt-plan-descripcion {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 70%;
  font-weight: 400;
}

.content-label-plan-solcitado {
  background: #d0e3ff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
  padding: 2px 10px;
}

.content-txt-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 2px 20px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  width: fit-content;
}

.txt-title-def {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-message {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f8a130;
  border-left: 5px solid #f8a130;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #f8a130;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: 600px) {
  .content-txt-plan-descripcion {
    text-align: end;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 70%;
    font-weight: 400;
  }

  .txt-item-table {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
  }

  .content-buttons {
    display: block;
  }

  .content-buttons button:nth-child(1) {
    width: 100%;
    margin-top: 15px;
  }

  .content-buttons button:nth-child(2) {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }
}
</style>
<template>
  <div class="content-header">
    <p class="txt-title poppins mb-0">Tarjetas SIM</p>
    <v-spacer />
    <v-btn
      elevation="0"
      icon
      class="button-secondary btn-refresh"
      style="width: 120px"
      @click="$store.commit('refresher', true)"
    >
      <v-icon>mdi-refresh</v-icon>
      Actualizar
    </v-btn>
    <!-- <v-btn
      @click="openDialog"
      class="button-primary poppins"
      elevation="0"
    >
      <span v-if="!responsive">+ Añadir empleado</span>
      <v-icon
        v-else
        color="#fff"
        size="18px"
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: "LayoutSimCardsHeader",
  data() {
    return {
      responsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.responsive = false;
      } else {
        this.responsive = true;
      }
    },
    openDialog: function () {
      this.$emit("setDialogAdd", true);
    },
  },
};
</script>

<style scoped>
.content-header {
  display: flex;
  align-items: center;
}
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .txt-title {
    font-size: 25px;
  }
  .content-header {
    display: block;
    align-items: center;
  }
  .btn-refresh {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}
/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>
<template>
  <div>
    <layout-administrators-header @setDialogAdd="setDialogAdd" />
    <layout-administrators-searchbar
      @setSearch="setSearch"
      @setNameOrder="setNameOrder"
      @setPlatformAccess="setPlatformAccess"
    />
    <layout-administrators-content
      :sSearch="sSearch"
      :sNameOrder="sNameOrder"
      :bPlatformAccess="bPlatformAccess"
      @setOptions="setOptions"
      @setDialogEdit="setDialogEdit"
    />
    <layout-administrators-add
      :bDialogAdd="bDialogAdd"
      @setDialogAdd="setDialogAdd"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutAdministratorsHeader from "@/layouts/Administrators/Header.vue";
import LayoutAdministratorsContent from "@/layouts/Administrators/Content.vue";
import LayoutAdministratorsSearchbar from "@/layouts/Administrators/Searchbar.vue";
import LayoutAdministratorsAdd from "@/layouts/Administrators/Add.vue";

export default {
  name: "AdministratorsView",
  data() {
    return {
      sSearch: "",
      sNameOrder: undefined,
      bPlatformAccess: undefined,
      bDialogAdd: false,
      bDialogEdit: false,
      oOptions: {},
      oItems: {},
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setDialogEdit: function (item) {
      this.oItems = item.obj;
      this.bDialogEdit = item.active;
    },
    setNameOrder: function (val) {
      this.sNameOrder = val;
    },
    setPlatformAccess: function (val) {
      this.bPlatformAccess = val;
    },
  },
  components: {
    LayoutAdministratorsHeader,
    LayoutAdministratorsContent,
    LayoutAdministratorsSearchbar,
    LayoutAdministratorsAdd,
  },
};
</script>
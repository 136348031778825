<template>
    <div>
        <div v-if="Object.keys(items).length > 0">
            <layout-detail-promotions-header :items="items" />
            <div class="separator mt-5 mb-5" />
            <layout-detail-promotions-content :items="items"/>

        </div>

        <!-- loader -->
        <v-overlay class="content-overlay-global" :value="bLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            <span>Obteniendo información, por favor espere...</span>
        </v-overlay>
        <!-- delete component  -->
        <delete-component :oOptions="oOptions" @setOptions="setOptions" />
    </div>
</template>

<script>
import LayoutDetailPromotionsHeader from "@/layouts/DetailPromotions/Header.vue";
import LayoutDetailPromotionsContent from "@/layouts/DetailPromotions/Content.vue";

export default {
    name: "DetailPromotionsView",
    data() {
        return {
            bLoading: false,
            items: {},
            activeDialog: false,
            objDialog: {},
            activeDialogEdit: false,
            objDialogEdit: {},
            oOptions: {},
        };
    },
    beforeMount() {
        // this.clientId = this.$route.params.id;
        this.getData();
    },
    methods: {
        setOptions: function (val) {
            this.oOptions = val;
        },
        getData: function () {
            this.items = {};
              this.bLoading = true;
              DB.get(`${URI}/promotions/${this.$route.params.id}`, {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {
                },
              })
                .then((response) => {
                  this.bLoading = false;
                  this.items = response.data.results;

                  this.$store.commit("refresher", false);
                })
                .catch((error) => {
                  this.bLoading = false;
                  this.mixError(error.response.data.message,error.response.status);
                });
        },

    },
    computed: {
        refresh: function () {
            return this.$store.state.refresh;
        },
    },
    watch: {
        refresh: function () {
            this.getData();
        },
    },
    components: {
        LayoutDetailPromotionsHeader,
        LayoutDetailPromotionsContent
    },
};
</script>

<template>
  <v-dialog
    v-model="bDialogLaunchTest"
    :width="screenWidth"
    persistent
  >
    <div class="content-card-dialog">
      <v-btn
        class="content-card-button-close"
        @click="closeDialog"
        icon
      >
        <v-icon
          color="#000"
          size="16px"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins mb-0">Lanzaminento de prueba</p>
      <p class="content-card-txt-description poppins mt-2">
        ¿Esta seguro de realizar el lanzamiento de prueba para la tarjeta SIM? <br />
        Presione el boton de confirmación para realizar esta acción.
      </p>
      <div class="content-buttons">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              <div class="display-flex align-items-center justify-content-flex-start">
                <v-btn
                  @click="closeDialog"
                  class="button-secondary poppins"
                  elevation="0"
                  width="100%"
                >
                  Regresar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="5"
            >
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn
                  @click="send"
                  :loading="bLoading"
                  class="button-primary poppins"
                  elevation="0"
                  width="100%"
                >
                  Enviar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LaunchTestComponent",
  props: {
    selectedItems: Array,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    send: function () {
      this.bLoading = true;

      let s_icc = "8934076100143460755";

      DB.get(`${URL_KITE}/${s_icc}/diagnostic`, {
        headers: {
          "x-api-key": KEY_KITE,
        },
        params: {},
      })
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message,error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.screenWidth = 420 + "px";
      } else {
        this.screenWidth = 100 + "%";
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogLaunchTest", false);
    },
  },
  computed: {
    bDialogLaunchTest: function () {
      return this.$store.state.bDialogLaunchTest;
    },
  },
  watch: {
    bDialogLaunchTest: function () {
      if (this.bDialogLaunchTest) {
      }
    },
  },
};
</script>

<style scoped>
.content-inputs {
  margin-top: 50px;
}

.content-buttons {
  margin-top: 25px;
}
</style>
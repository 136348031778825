<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      <div v-if="!activeCard" style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item_header, index_header) in headers" :key="index_header" :class="item_header.align"
                  class="table-header-text poppins">
                  {{ item_header.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.sFolio }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.sTitle }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.sShortDescription }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.sNamePromotionType }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.tCreatedAt }}
                </td>
                <td class="txt-item-table text-left poppins cursor-pointer" @click="editItem(item.sPromotionId)">
                  {{ item.tUpdatedAt }}
                </td>
                <td class="txt-item-table text-center w-space poppins">
                  <div @click="changeStatus(item.sPromotionId, !item.bPromotionStatus)"
                    v-if="item.sNamePromotionStatus === 'Activo' ? true : false" class="active-platform">
                    Desactivar
                    <v-icon color="#E33F3F" size="14px">
                      mdi-lock-outline
                    </v-icon>
                  </div>
                  <div @click="changeStatus(item.sPromotionId, !item.bPromotionStatus)" v-else
                    class="inactive-platform">
                    Activar
                    <v-icon color="#2759A2" size="14px">
                      mdi-lock-open-outline
                    </v-icon>
                  </div>
                </td>
                <td class="txt-item-table text-center global-col-body-accion poppins">
                  <div class="
                      display-flex
                      align-items-center
                      justify-content-center
                    " >
                    <v-btn @click="editItem(item.sPromotionId)" icon>
                      <v-icon color="#000" size="16px">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                    <v-btn v-if="getPermissionsActionsGlobal()" @click="deleteItem(item.sPromotionId)" icon>
                      <v-icon size="16px">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                <div :class="
                  true
                    ? 'content-card-responsive card-inactive position-relative'
                    : 'content-card-responsive card-active position-relative'
                ">
                  <div class="height-100">
                    <div>
                      <div class="content-first-row-card">
                        <div class="content-text-title">
                          <p class="txt-item-table-card-title poppins mb-0">
                            {{ item.sTitle }}
                          </p>
                          <p class="poppins mb-2" :class="
                            item.sFolio
                              ? 'txt-item-table-card-item'
                              : 'txt-item-table-card-item-empty'
                          ">
                            {{ item.sFolio ? item.sFolio : "Sin folio" }}
                          </p>
                        </div>
                        <div class="content-menu-actions">
                          <v-menu offset-y content-class="content-menu-options" attach left>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="content-actions">
                                <div class="content-status-mobile-card">
                                  <!-- @click="changeStatus(item.sPromotionId, !item.bPlatformAccess)" -->
                                  <div @click="changeStatus(item.sPromotionId, !item.bPromotionStatus)"
                                    :class="item.bPromotionStatus ? 'active-platform' : 'inactive-platform'">
                                    {{ item.bPromotionStatus ? 'Desactivar' : 'Activar' }}
                                    <v-icon :color="item.bPromotionStatus ? '#E33F3F' : '#2759a2'" size="14px">
                                      {{ item.bPromotionStatus ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
                                    </v-icon>
                                  </div>
                                </div>
                                <div v-bind="attrs" v-on="on" class="content-menu-options-dots">
                                  <v-icon color="#000" size="20px">
                                    mdi-dots-vertical
                                  </v-icon>
                                </div>
                              </div>
                            </template>
                            <div class="circle-content-options">
                              <div>
                                <v-list dense>
                                  <v-list-item link @click="editItem(item.sPromotionId)">
                                    <v-list-item-icon>
                                      <v-icon color="#000" size="14px">
                                        mdi-eye-outline
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="poppins">
                                      Detalle
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-if="getPermissionsActionsGlobal()" link
                                    @click="deleteItem(item.sPromotionId)">
                                    <v-list-item-icon>
                                      <v-icon size="14px">
                                        mdi-close
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-delete-card poppins">
                                      Eliminar
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>
                            </div>
                          </v-menu>
                        </div>
                      </div>

                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Tipo de promoción</p>
                        <p class="px-txt-card-item mb-0">{{ item.sNamePromotionType }}</p>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Fecha de creación</p>
                        <p class="px-txt-card-item mb-0">{{ item.tCreatedAt }}</p>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <p class="px-txt-card-title mb-0">Última modificación</p>
                        <p class="px-txt-card-item mb-0">{{ item.tUpdatedAt }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div class="content-pagination mt-4" style="background-color: transparent">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
    <!-- confirm status  -->
    <confirm-change-status-component :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus" />
  </div>
</template>

<script>
import lodash from "lodash";
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutClientsContent",
  props: {
    sSearch: String,
    sPromotionsType: String,
    sCreatedAt: String,
    sLastChangeStatus: String,
  },
  data() {
    return {
      bLoading: false,
      items: [],
      headers: [
        {
          title: "Folio",
          align: "text-left",
        },
        {
          title: "Nombre",
          align: "text-left",
        },
        {
          title: "Descripción corta",
          align: "text-left",
        },
        {
          title: "Tipo de promoción",
          align: "text-left",
        },
        {
          title: "Creación",
          align: "text-left",
        },
        {
          title: "Última modif.",
          align: "text-left",
        },
        {
          title: "Estado",
          align: "text-center",
        },
        {
          title: "Acciones",
          align: "text-center global-col-header-accion",
        },
      ],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
      oChangeStatusOptions: {},
      emptyData: "Sin información.",
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    editItem: function (id) {
      this.$router.push({
        name: "detailPromotions",
        params: {
          id: id,
        },
      });
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (sPromotionId, bAction) {
      if (this.getPermissionsActionsGlobal()) {
        this.oChangeStatusOptions = {
          active: true,
          api: `${URI}/promotions/${sPromotionId}`,
          payload: { sStatus: bAction ? 'Active' : 'Inactive' },
          bInputs: false,
          title: bAction == true ? "Reactivación de promoción" : "Desactivación de promoción",
          action: bAction,
          description:
            bAction == true
              ? "La siguiente acción restablecerá la vigencia de esta promoción dentro de la plataforma.</br>¿Desea continuar?"
              : "La siguiente acción suspenderá la vigencia de esta promoción dentro de la plataforma.</br> ¿Desea continuar?",
          sTextBtn: bAction == true ? "Reactivar" : "Desactivar",
        };
      }
    },
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/promotions/${id}`,
        title: "Eliminar promoción",
        description:
          "Eliminar una promoción es una acción irreversible </br>  ¿Desea continuar? ",
      };

      this.$emit("setOptions", options);
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    formatDate: function (date) {
      var _date = MOMENT(date, "DD-MM-YYYY");
      // return MOMENT(_date).format("DD/MMM/YYYY");
      return MOMENT(_date).locale("es").format("DD/MMM/YYYY").replace(".", "");
    },
    //helpers
    getFlag(sCountryCallingCodeOrigin) {
      switch (sCountryCallingCodeOrigin) {
        case "52":
          return [require("@/assets/images/mx.png")];
        case "593":
          return [require("@/assets/images/ecuador.png")];
        case "1":
          return [require("@/assets/images/us.png")];

        default:
          break;
      }
    },
    formatPhone: function (item) {
      let sCountryCallingCode = item.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 4),
              "-",
              item.sPhoneNumber.slice(4),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        case "1":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 3),
              "-",
              item.sPhoneNumber.slice(3),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        default:
          break;
      }
      // let result = "";

      // if (item.sExtension == "") {
      //   if (item.sAreaCallingCode !== "" && item.sAreaCallingCode !== "") {
      //     result =
      //       "+" +
      //       item.sCountryCallingCode +
      //       " " +
      //       item.sAreaCallingCode +
      //       item.sPhoneNumber;
      //   } else {
      //     result = "Sin teléfono";
      //   }
      // } else {
      //   result =
      //     "+" +
      //     item.sCountryCallingCode +
      //     " " +
      //     item.sAreaCallingCode +
      //     item.sPhoneNumber +
      //     " Ext. " +
      //     item.sExtension;
      // }
      // return result;
    },
    formatEmptyPhone(item) {
      let bln = true;
      if (item.sPhoneNumber === "" || item.sAreaCallingCode === "") {
        bln = false;
      }
      return bln;
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    getData: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/promotions`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
              sSearch: this.sSearch,
              sType: this.sPromotionsType,
              sSortStatus: this.sCreatedAt,

            },
          }).then((response) => {
            this.bLoading = false;
            this.items = response.data.results.map((e) => {
              return {
                ...e,
                sNamePromotionType: e.oPromotionType.sName,
                sNamePromotionStatus: e.oPromotionStatus.sName,
                bPromotionStatus: e.oPromotionStatus.sName == 'Activo' ? true : false,
              };
            });

            this.numPages = response.data.iNumPages;
            this.iPageNumber = response.data.iNumPages;
            this.totalItems = response.data.iTotal;
            this.$store.commit("refresher", false);
          })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },
    sSearch: lodash.debounce(function () {
      this.getData();
    }, 500),
    sCreatedAt: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    sLastChangeStatus: function () {
      this.getData();
    },
    sPromotionsType() {
      this.getData();
    }
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.card-inactive {
  border: 1px solid #2759a2;
}

.card-active {
  border: 1px solid #2759a2;
}

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.active-platform {
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  padding: 5px;
  color: #e33f3f;
  background: #ffc6c6 0% 0% no-repeat padding-box;

}

.inactive-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  padding: 5px;
  color: #2759a2;

}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  overflow-wrap: break-word !important;

}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.text-delete-card {
  /* color: #e33f3f; */
}

.content-text-title {
  width: 80%;
  max-width: 80%;
  min-width: auto;
}

.content-actions {
  display: flex;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  margin-left: 10px;
}

.content-first-row-card {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}



.content-status-mobile-card {
  width: 70px;
  display: flex;
  /* position: absolute; */
  right: 30px;
  top: 10px;
}

.content-menu-options-dots {
  /* position: absolute; */
  /* top: 10px; */
  /* right: 10px; */
}

.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}
</style>
export const mixs = {
  methods: {
    mixSuccess(e) {
      this.$toast.success(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    mixError(e, sStatus) {
      this.$toast.error(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      if (sStatus === 401) {
        this.$store.commit("setToken", "");
        this.$store.commit("setUserId", "");
        this.$store.commit("setStatus", "");
        this.$store.commit("setManager", null);
        // remove local storage
        localStorage.removeItem("bManager");
        localStorage.removeItem("bMenu");
        localStorage.removeItem("bMenuResponsive");
        localStorage.removeItem("sStatus");
        localStorage.removeItem("sToken");
        localStorage.removeItem("sUserId");

        this.$router.push("/login").catch(() => {});
      } else if (sStatus === 403) {
        this.$router.go(-1);
      }
    },
    mixWarning(e) {
      this.$toast.warning(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    mixInfo(e) {
      this.$toast.info(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    formatMoneyGlobal(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getPermissionsActionsGlobal() {
      switch (this.$route.name) {
        case "sims":
          return this.bAdminSIMCardsSolicitud;
        case "detailSims":
          return this.bAdminSIMCardsSolicitud;

        case "administrators":
          return this.bAdminAdministrators;
        case "addAdministrators":
          return this.bAdminAdministrators;
        case "detailAdministrators":
          return this.bAdminAdministrators;

        case "clients":
          return this.bAdminCustomers;
        case "addClients":
          return this.bAdminCustomers;
        case "detailClient":
          return this.bAdminCustomers;

        case "sim-cards":
          return this.bAdminSIMCards;
        case "detailSimCards":
          return this.bAdminSIMCards;

        case "promotions":
          return this.bAdminPromotions;
        case "addPromotions":
          return this.bAdminPromotions;
        case "detailPromotions":
          return this.bAdminPromotions;

        case "prebilling":
          return this.bAdminPrebilling;
        case "detailPrebilling":
          return this.bAdminPrebilling;
        case "inicio":
          return this.bShowDashboard;
        case "profile":
          return true;
        default:
          break;
      }
    },
    getWithoutPermissionsActionsGlobal(sTextDynamic) {
      {
        return sTextDynamic.includes("notificación");
      }
    },
    getColorGlobal: function (iValue, iLimit) {
      let dPorcent = this.getPercentGlobal(iValue, iLimit);
      let color = "gray";
      if (dPorcent < 24.99) {
        color = "green";
      } else if (dPorcent >= 25 && dPorcent < 49.99) {
        color = "yellow";
      } else if (dPorcent >= 50 && dPorcent < 74.99) {
        color = "orange";
      } else {
        color = "red";
      }
      return color;
    },
    getPercentGlobal(iValue, iLimit) {
      let res = 0;

      res = (iValue / iLimit) * 100;

      return res === Infinity ? 100 : isNaN(res) ? 0 : res;
    },
  },
  computed: {
    bShowSIMCardsSolicitud() {
      return this.$store.state.bShowSIMCardsSolicitud;
    },
    bAdminSIMCardsSolicitud() {
      return this.$store.state.bAdminSIMCardsSolicitud;
    },

    bShowAdministrators() {
      return this.$store.state.bShowAdministrators;
    },
    bAdminAdministrators() {
      return this.$store.state.bAdminAdministrators;
    },

    bShowCustomers() {
      return this.$store.state.bShowCustomers;
    },
    bAdminCustomers() {
      return this.$store.state.bAdminCustomers;
    },
    bShowSIMCards() {
      return this.$store.state.bShowSIMCards;
    },
    bAdminSIMCards() {
      return this.$store.state.bAdminSIMCards;
    },

    bAdminPromotions() {
      return this.$store.state.bAdminPromotions;
    },
    bShowPromotions() {
      return this.$store.state.bShowPromotions;
    },

    bAdminPrebilling() {
      return this.$store.state.bAdminPrebilling;
    },
    bShowPrebilling() {
      return this.$store.state.bShowPrebilling;
    },

    bShowDashboard() {
      return this.$store.state.bShowDashboard;
    },
    bAdminDashboard() {
      return this.$store.state.bAdminDashboard;
    },
  },
};

<template>
  <div>
    <div class="content-title-label mb-4">Información del representante</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="sManagerName"
            type="text"
            label="Nombre(s)"
            placeholder="Nombre(s)"
            dense
            color="#2759A2"
            class="global-inputs poppins"
            background-color="transparent"
            maxlength="85"
            persistent-placeholder
            @keypress="soloLetters"
            :disabled="!getPermissionsActionsGlobal()"
          >
            <template slot="label">
              <span>Nombre(s) <span class="color-red">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerLastname"
              type="text"
              label="Apellido(s)"
              placeholder="Apellido(s)"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Apellido(s) <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sManagerJobTitle"
              type="text"
              label="Cargo laboral"
              placeholder="Cargo laboral"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <!-- Start: phone component -->
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisManager"
              @setCodigoArea="setCodigoAreaManager"
              @setNumeroTelefono="setNumeroTelefonoManager"
              @setExtension="setExtensionManager"
              :_bDialogAdd="false"
              :_codigoPais="sManagerCountryCallingCode"
              :_codigoArea="sManagerAreaCallingCode"
              :_numeroTelefono="sManagerPhoneNumber"
              :_extension="sManagerPhoneExtension"
              :_type="false"
            />
            <!-- End: phone component -->
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="content-title-label mt-7 mb-4">Información de la empresa</div>
    <v-container class="pl-0 pr-0" fluid>
      <v-row>
        <!-- <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sRealName"
              type="text"
              label="Razón social"
              placeholder="Razón social"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              :error="bRealName"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Razón social <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col> -->
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sPublicName"
              type="text"
              label="Nombre comercial"
              placeholder="Nombre comercial"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              :error="bPublicName"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span
                  >Nombre comercial
                  <span class="color-red">*</span></span
                >
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisEmpresa"
              @setCodigoArea="setCodigoAreaEmpresa"
              @setNumeroTelefono="setNumeroTelefonoEmpresa"
              @setExtension="setExtensionEmpresa"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="false"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" md="6">
          <div>
            <country-code-input-component
              class=""
              :bObligatoryField="false"
              @setCodigoPais="setCodigoPaisEmpresa"
              @setCodigoArea="setCodigoAreaEmpresa"
              @setNumeroTelefono="setNumeroTelefonoEmpresa"
              @setExtension="setExtensionEmpresa"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="false"
            />
          </div>
        </v-col> -->
        <!-- <v-col cols="12" md="6">
          <div>
            <v-text-field
              v-model="sEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
              :error="bEmailEnterpise"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sLocationAddress"
              type="text"
              label="Dirección comercial"
              placeholder="Dirección comercial"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="300"
              rows="3"
              persistent-placeholder
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Dirección comercial <span class="color-red">*</span></span>
              </template>
            </v-textarea>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sLocationAddressDetail"
              type="text"
              label="Referencias"
              placeholder="Ej. Número de bodega, oficina, edificio, piso, departamento, etc."
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="300"
              rows="3"
              persistent-placeholder
              :disabled="!getPermissionsActionsGlobal()"
            >
            </v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sLocationCity"
              type="text"
              label="Ciudad"
              placeholder="Ciudad"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatCity"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Ciudad <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sLocationZIPCode"
              type="text"
              label="Código postal"
              placeholder="Código postal"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="5"
              persistent-placeholder
              @keypress="soloNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Código postal <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sCountry"
              label="País"
              placeholder="País"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aCountries"
              item-text="sName"
              item-value="sCountryId"
              @change="selectStates()"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>País <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sState"
              label="Estado"
              placeholder="Estado"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aStates"
              item-text="sName"
              item-value="sStateId"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Estado <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="content-title-label mt-4 mb-4">Información de facturación</div>
    <v-container class="pl-0 pr-0" fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sRealName"
              type="text"
              label="Razón social"
              placeholder="Razón social"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              :error="bRealName"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Razón social <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6"> </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxId"
              type="text"
              label="RFC"
              placeholder="RFC"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="12"
              persistent-placeholder
              :error="bRFCTax"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>RFC <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico para facturación"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
              :error="bEmailTax"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sTaxLocationAddress"
              type="text"
              label="Dirección"
              placeholder="Dirección"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Dirección <span class="color-red">*</span></span>
              </template>
            </v-textarea>
          </div>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="6">
          <div>
            <v-textarea
              v-model="sTaxLocationAddressDetail"
              type="text"
              label="Referencias"
              placeholder="Ej. Número de bodega, oficina, edificio, piso, departamento, etc."
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="500"
              rows="3"
              persistent-placeholder
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span
                  >Referencias <span class="color-red">*</span></span
                >
              </template>
            </v-textarea>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxLocationCity"
              type="text"
              label="Ciudad"
              placeholder="Ciudad"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="85"
              persistent-placeholder
              @keypress="formatCity"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Ciudad <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-text-field
              v-model="sTaxLocationZIPCode"
              type="text"
              label="Código postal"
              placeholder="Código postal"
              dense
              color="#2759A2"
              class="global-inputs poppins"
              background-color="transparent"
              maxlength="5"
              persistent-placeholder
              @keypress="soloNumbers"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Código postal <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sTaxLocationCountry"
              label="País"
              placeholder="País"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              persistent-placeholder
              :items="aTaxCountries"
              item-text="sName"
              item-value="sCountryId"
              @change="selectTaxStates()"
              no-data-text="No hay datos disponibles"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>País <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div>
            <v-select
              v-model="sTaxLocationState"
              label="Estado"
              placeholder="Estado"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              no-data-text="No hay datos disponibles"
              persistent-placeholder
              :items="aTaxStates"
              item-text="sName"
              item-value="sStateId"
              :disabled="!getPermissionsActionsGlobal()"
            >
              <template slot="label">
                <span>Estado <span class="color-red">*</span></span>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="content-permissions">
      <div class="content-label-txt-title poppins">Permisos</div>
      <div class="content-switch-all">
        <v-switch v-model="bCanActivateSIM">
          <template #label>
            <span class="ml-3 poppins txt-label-switch">
              Permiso de activación y desactivación de sus tarjetas SIM propias.
            </span>
          </template>
        </v-switch>
        <v-switch v-model="bCanChangeField">
          <template #label>
            <span class="ml-3 poppins txt-label-switch">
              Permiso de edición de campo 1 de tarjeta sim.
            </span>
          </template>
        </v-switch>
      </div>
    </div>
    <!-- buttons -->
    <div v-if="getPermissionsActionsGlobal()" class="content-buttons">
      <v-btn
        @click="returnClients"
        class="button-secondary poppins"
        elevation="0"
      >
        Regresar
      </v-btn>
      <!-- :disabled="!validationForm" -->
      <v-btn
        @click="add"
        :loading="bLoading"
        
        class="button-primary poppins"
        elevation="0"
      >
        Añadir
      </v-btn>
    </div>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutAddClientsForm",
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      aCountries: [],
      aStates: [],
      aTaxCountries: [],
      aTaxStates: [],
      bPublicName: false,
      bRealName: false,
      bEmailEnterpise: false,
      bRFCTax: false,
      bEmailTax: false,
      // Empresa
      sPublicName: "",
      sRealName: "",
      sCountryCallingCode: "52",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",
      sEmail: "",
      sLocationCity: "",
      sLocationAddress: "",
      sLocationAddressDetail: "",
      sLocationZIPCode: "",
      sCountry: "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b",
      sState: "1ab1a61b-aed2-4243-a417-4451760a1019",
      // Facturacion
      sTaxId: "",
      sTaxEmail: "",
      sTaxLocationAddress: "",
      sTaxLocationAddressDetail: "",
      sTaxLocationCity: "",
      sTaxLocationZIPCode: "",
      sTaxLocationCountry: "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b",
      sTaxLocationState: "1ab1a61b-aed2-4243-a417-4451760a1019",
      // Representante
      sManagerCountryCallingCode: "52",
      sManagerAreaCallingCode: "",
      sManagerPhoneExtension: "",
      sManagerPhoneNumber: "",
      sManagerJobTitle: "",
      sManagerName: "",
      sManagerLastname: "",
      sManagerEmail: "",

      bCanActivateSIM: false,
      bCanChangeField: false,
    };
  },
  beforeMount() {
    this.$store.dispatch("getPermissionsByUserGlobal");
    this.sCountryCallingCode = "52";
    this.sManagerCountryCallingCode = "52";
    this.getCountries();
    this.getStates();
    this.getTaxCountries();
    this.getTaxStates();
  },
  methods: {
    add: function () {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                sPublicName: this.sPublicName,
                sRealName: this.sRealName,
                sPhoneNumber: this.sPhoneNumber,
                sEmail: this.sEmail,
                sLocationCity: this.sLocationCity,
                sLocationAddress: this.sLocationAddress,
                sLocationAddressDetail: this.sLocationAddressDetail,
                sLocationZIPCode: this.sLocationZIPCode,
                sStateId: this.sState,
                sCountryCallingCode: this.sCountryCallingCode,
                sAreaCallingCode: this.sAreaCallingCode,
                sPhoneExtension: this.sPhoneExtension,
                sTaxId: this.sTaxId,
                sTaxEmail: this.sTaxEmail,
                sTaxLocationAddress: this.sTaxLocationAddress,
                sTaxLocationAddressDetail: this.sTaxLocationAddressDetail,
                sTaxLocationCity: this.sTaxLocationCity,
                sTaxLocationZIPCode: this.sTaxLocationZIPCode,
                sTaxLocationStateId: this.sTaxLocationState,
                sManagerCountryCallingCode: this.sManagerCountryCallingCode,
                sManagerAreaCallingCode: this.sManagerAreaCallingCode,
                sManagerPhoneExtension: this.sManagerPhoneExtension,
                sManagerPhoneNumber: this.sManagerPhoneNumber,
                sManagerJobTitle: this.sManagerJobTitle,
                sManagerName: this.sManagerName,
                sManagerLastname: this.sManagerLastname,
                sManagerEmail: this.sManagerEmail,
                bCanActivateSIM: this.bCanActivateSIM,
                bCanChangeField: this.bCanChangeField,
              };

            DB.post(`${URI}/enterprises`, payload, config)
              .then((response) => {
                this.bLoading = false;
                this.mixSuccess(response.data.message);
                this.returnClients();
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.setFieldNotRepit(error.response.data.message);
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.returnClients();
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.returnClients();
          this.$store.commit("refresher", true);
        });
    },

    setFieldNotRepit(sMessage) {
      sMessage.includes("Nombre público")
        ? (this.bPublicName = true)
        : (this.bPublicName = false);
      sMessage.includes("Nombre real")
        ? (this.bRealName = true)
        : (this.bRealName = false);
      sMessage.includes("RFC") ? (this.bRFCTax = true) : (this.bRFCTax = false);
    },
    // Reepresentante
    setCodigoPaisManager: function (e) {
      this.sManagerCountryCallingCode = e;
    },
    setCodigoAreaManager: function (e) {
      this.sManagerAreaCallingCode = e;
    },
    setNumeroTelefonoManager: function (e) {
      this.sManagerPhoneNumber = e;
    },
    setExtensionManager: function (e) {
      this.sManagerPhoneExtension = e;
    },
    // Empresa
    setCodigoPaisEmpresa: function (e) {
      this.sCountryCallingCode = e;
    },
    setCodigoAreaEmpresa: function (e) {
      this.sAreaCallingCode = e;
    },
    setNumeroTelefonoEmpresa: function (e) {
      this.sPhoneNumber = e;
    },
    setExtensionEmpresa: function (e) {
      this.sPhoneExtension = e;
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ _A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 80;
      } else {
        this.screenWidth = 100;
      }
    },
    returnClients: function () {
      this.aCountries = [];
      this.aStates = [];
      this.aTaxCountries = [];
      this.aTaxStates = [];
      // Empresa
      this.sPublicName = "";
      this.sRealName = "";
      this.sCountryCallingCode = "52";
      this.sAreaCallingCode = "";
      this.sPhoneNumber = "";
      this.sPhoneExtension = "";
      this.sEmail = "";
      this.sLocationCity = "";
      this.sLocationAddress = "";
      this.sLocationAddressDetail = "";
      this.sLocationZIPCode = "";
      this.sCountry = "";
      this.sState = "";
      // Facturacion
      this.sTaxId = "";
      this.sTaxEmail = "";
      this.sTaxLocationAddress = "";
      this.sTaxLocationAddressDetail = "";
      this.sTaxLocationCity = "";
      this.sTaxLocationZIPCode = "";
      this.sTaxLocationCountry = "";
      this.sTaxLocationState = "";
      // Representante
      this.sManagerCountryCallingCode = "52";
      this.sManagerAreaCallingCode = "";
      this.sManagerPhoneExtension = "";
      this.sManagerPhoneNumber = "";
      this.sManagerJobTitle = "";
      this.sManagerName = "";
      this.sManagerLastname = "";
      this.sManagerEmail = "";
      this.$router.push({
        name: "clients",
      });
    },
    getCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aCountries = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectStates: function () {
      this.sState = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.sCountry,
        },
      })
        .then((response) => {
          this.aStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getTaxCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aTaxCountries = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectTaxStates: function () {
      this.sTaxLocationState = "";
      this.getTaxStates();
    },
    getTaxStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.sTaxLocationCountry,
        },
      })
        .then((response) => {
          this.aTaxStates = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.aCountries !== "" &&
        this.aStates !== "" &&
        this.aTaxCountries !== "" &&
        this.aTaxStates !== "" &&
        // Empresa
        this.sPublicName !== "" &&
        this.sRealName !== "" &&
        // this.sCountryCallingCode !== "" &&
        // this.sAreaCallingCode !== "" &&
        // this.sPhoneNumber !== "" &&
        // this.sPhoneExtension !== "" &&
        // this.sEmail !== "" &&
        this.sLocationCity !== "" &&
        this.sLocationAddress !== "" &&
        // this.sLocationAddressDetail !== "" &&
        this.sLocationZIPCode !== "" &&
        this.sCountry !== "" &&
        this.sState !== "" &&
        // Facturacion
        this.sTaxId !== "" &&
        this.sTaxEmail !== "" &&
        this.sTaxLocationAddress !== "" &&
        // this.sTaxLocationAddressDetail !== "" &&
        this.sTaxLocationCity !== "" &&
        this.sTaxLocationZIPCode !== "" &&
        this.sTaxLocationCountry !== "" &&
        this.sTaxLocationState !== "" &&
        // Representante
        this.sManagerCountryCallingCode !== "" &&
        this.sManagerAreaCallingCode !== "" &&
        // this.sManagerPhoneExtension !== "" &&
        this.sManagerPhoneNumber !== "" &&
        // this.sManagerJobTitle !== "" &&
        this.sManagerName !== "" &&
        this.sManagerLastname !== "" &&
        this.sManagerEmail !== ""
      );
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-label-txt-title {
  background: #d2e4fe 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 2px 15px;
  width: fit-content;
}
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 20px;
}

.button-primary,
.button-secondary {
  width: 150px;
}

.button-primary {
  margin-left: 15px;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .button-primary {
    margin-left: 0px;
    margin-top: 15px;
  }

  .button-primary,
  .button-secondary {
    width: 100%;
  }
}
.content-switch-all {
  display: flex;
  gap: 15px;
}
.txt-label-switch {
  color: black;
  font-size: 0.9rem;
}
</style>

<template>
    <div>
        <div v-if="!bMobile" class="d-flex">
            <div>
                <v-btn v-if="getPermissionsActionsGlobal()" @click="setDeleteDialog(itemsOriginal.sAdministratorId)"
                    class="button-secondary-delete poppins mr-3 w-165 mt-5" elevation="0">
                    <span> Eliminar</span>
                </v-btn>

            </div>
            <v-spacer></v-spacer>
            <div v-if="activeButtons" class="content-buttons">
                <v-btn @click="discardChanges" class="button-secondary poppins" elevation="0">
                    Descartar cambios
                </v-btn>
                <v-btn @click="edit" :loading="bLoading" class="button-primary poppins ml-3" elevation="0">
                    Guardar cambios
                </v-btn>
            </div>
        </div>
        <div v-else class="d-block">
            <div>
                <v-col cols="12">
                    <v-btn @click="setDeleteDialog(itemsOriginal.sAdministratorId)"
                        class="button-secondary-delete poppins mr-3 mt-5 button-delete" elevation="0">
                        <span> Eliminar</span>
                    </v-btn>
                </v-col>
            </div>
            <v-spacer></v-spacer>
            <div v-if="activeButtons" class="content-buttons">
                <!-- <v-btn @click="setDeleteDialog()" class="button-secondary-delete poppins mr-3 w-165 mt-5" elevation="0">
                <span> Eliminar</span>
            </v-btn>
            <v-spacer></v-spacer> -->
                <v-col cols="12">
                    <v-btn @click="discardChanges" class="button-secondary poppins" elevation="0">
                        Descartar cambios
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="edit" :loading="bLoading" class="button-primary poppins" elevation="0">
                        Guardar cambios
                    </v-btn>
                </v-col>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        oPermissions: Object,
        itemsOriginal: Object,
        nombre: String,
        apellido: String,
        correo: String,
        puesto: String,
        codigoPais: String,
        codigoArea: String,
        numeroTelefono: String,
        extension: String,
        bModifyPermissions: Boolean
    },
    data() {
        return {
            activeButtons: false,
            bMobile: false,
            bLoading:false
        }
    },
    beforeMount() {
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize: function () {
            if (window.innerWidth < 600) {
                this.bMobile = true
            } else {
                this.bMobile = false
            }
        },
        setDeleteDialog(id) {
            let options = {
                active: true,
                id,
                api: `/administrators/${id}`,
                title: "Eliminar administrador",
                description:
                    "Eliminar un administrador es una acción irreversible </br>  ¿Desea continuar? ",
                bReturnView: true,
            };
            this.$emit("setOptions", options);
        },
        setCodigoPais: function (e) {
            this.codigoPais = e;
            this.listenChange();
        },
        setCodigoArea: function (e) {
            this.codigoArea = e;
            this.listenChange();
        },
        setNumeroTelefono: function (e) {
            this.numeroTelefono = e;
            this.listenChange();
        },
        setExtension: function (e) {
            this.extension = e;
            this.listenChange();
        },
        listenChange: function (evt) {
            this.activeButtons =
                this.nombre.trim() !== this.itemsOriginal.sName ||
                this.apellido.trim() !== this.itemsOriginal.sLastname ||
                this.correo.trim() !== this.itemsOriginal.oGeneralInfo.sEmail ||
                this.codigoPais.trim() !==
                this.itemsOriginal.oContactInfo.sCountryCallingCode ||
                this.codigoArea.trim() !==
                this.itemsOriginal.oContactInfo.sAreaCallingCode ||
                this.numeroTelefono.trim() !==
                this.itemsOriginal.oContactInfo.sPhoneNumber ||
                this.extension.trim() !==
                this.itemsOriginal.oContactInfo.sPhoneExtension ||
                this.puesto !== this.itemsOriginal.oEnterpriseInfo.sJobTitle ||
                this.bModifyPermissions;

        },
        discardChanges: function () {
            this.activeButtons = true;
            this.$store.commit("refresher", true);
        },
        edit: function () {
            this.bLoading = true;
            this.$store.dispatch("getPermissionsByUserGlobal").
                then((resp) => {
                    if (this.getPermissionsActionsGlobal()) {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.sToken}`,
                            },
                        },
                            payload = {
                                sName: this.nombre,
                                sLastname: this.apellido,
                                sJobTitle: this.puesto,
                                sPhoneNumber: this.numeroTelefono,
                                sAreaCallingCode: this.codigoArea,
                                sCountryCallingCode: this.codigoPais,
                                sPhoneExtension: this.extension,
                                aAdministratorPermissions: this.oPermissions.aPermissionsEdit.map((e) => {
                                    return {
                                        sAdministratorModuleId: e.sAdministratorModuleId,
                                        aPermissions: [
                                            {
                                                bBoolean: e.bShow,
                                                sPermissionId: e.sShowPermissionId
                                            },
                                            {
                                                bBoolean: e.bAdmin,
                                                sPermissionId: e.sAdminPermissionId
                                            }

                                        ]
                                    }
                                })
                            };
                        DB.put(
                            `${URI}/administrators/${this.itemsOriginal.sAdministratorId}`,
                            payload,
                            config
                        )
                            .then((response) => {
                                this.mixSuccess(response.data.message);
                                this.discardChanges();
                                this.$store.commit("refresher", true);
                                this.bLoading = false;
                            })
                            .catch((error) => {
                                this.bLoading = false;
                                this.mixError(error.response.data.message, error.response.status);
                            });
                    } else {
                        this.bLoading = false;
                        this.mixError(this.$store.state.sMessageErrorAccess, 0);
                        this.discardChanges();
                        this.$store.commit("refresher", true);

                    }
                }).catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.message, error.code);
                    this.discardChanges();
                    this.$store.commit("refresher", true);
                })
        },
    },
    watch: {
        nombre() {
            this.listenChange();
        },
        apellido() {
            this.listenChange();
        },
        correo() {
            this.listenChange();
        },
        puesto() {
            this.listenChange();
        },
        codigoPais() {
            this.listenChange();
        },
        codigoArea() {
            this.listenChange();
        },
        numeroTelefono() {
            this.listenChange();
        },
        extension() {
            this.listenChange();
        },
        bModifyPermissions() {
            this.listenChange();
        }
    }

}
</script>
<style scoped>
.content-buttons {
    /* margin-top: 30px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 600px) {
    .button-delete {
        width: 100%;
    }

    .content-buttons {
        display: block;
    }

    .content-buttons button:nth-child(1) {
        width: 100%;
    }

    .content-buttons button:nth-child(2) {
        width: 100%;
        margin-left: 0px !important;
        margin-top: 20px;
    }
}
</style>
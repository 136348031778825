<template>
    <div>
        <layout-add-admin-header />
        <div class="separator mt-4 mb-7" />
        <layout-add-admin-form />
    </div>
</template>
<script>

import LayoutAddAdminHeader from "@/layouts/AddAdmin/Header.vue";
import LayoutAddAdminForm from "@/layouts/AddAdmin/Form.vue";

export default {
    name: "AddAdministratorView",
    data() {
        return {

        };
    },
    methods: {},
    components: {
        LayoutAddAdminHeader,
        LayoutAddAdminForm,

    },
}
</script>
<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <div class="card-consumo">
            <p class="txt-title">Voz</p>
            <div class="mb-4">
              <p class="text-right txt-percent">
                {{ getPorcentaje(oVoice.sCurrent, oVoice.sLimit) }} %
              </p>
              <v-progress-linear :value="getPorcentaje(oVoice.sCurrent, oVoice.sLimit)" height="8px"
                :color="colorPercent" rounded></v-progress-linear>
            </div>
            <p class="txt-consumo">
              Consumo de trafico actual:
              <span class="grey-txt">{{ oVoice.sCurrent }}s</span>
            </p>
            <p class="txt-umbrat">
              Umbral: <span class="grey-txt">{{ oVoice.sLimit }}s</span>
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="card-consumo">
            <p class="txt-title">Datos</p>
            <div class="mb-4">
              <p class="text-right txt-percent">
                {{ getPorcentaje(oData.sCurrent, oData.sLimit) }} %
              </p>
              <v-progress-linear :value="getPorcentaje(oData.sCurrent, oData.sLimit)" height="8px" :color="colorPercent"
                rounded></v-progress-linear>
            </div>
            <p class="txt-consumo">
              Consumo de trafico actual:
              <span class="grey-txt">{{ oData.sCurrent }} MB</span>
            </p>
            <p class="txt-umbrat">
              Umbral: <span class="grey-txt">{{ oData.sLimit }} MB</span>
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="card-consumo">
            <p class="txt-title">SMS</p>
            <div class="mb-4">
              <p class="text-right txt-percent">
                {{ getPorcentaje(oSMS.sCurrent, oSMS.sLimit) }} %
              </p>
              <v-progress-linear :value="getPorcentaje(oSMS.sCurrent, oSMS.sLimit)" height="8px" :color="colorPercent"
                rounded></v-progress-linear>
            </div>
            <p class="txt-consumo">
              Consumo de trafico actual:
              <span class="grey-txt">{{ oSMS.sCurrent }}</span>
            </p>
            <p class="txt-umbrat">
              Umbral: <span class="grey-txt">{{ oSMS.sLimit }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-5 mb-5" />
    <!-- <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="4">
          <div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="rangoFechas"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="rangoFechas"
                  label="Rango de fechas"
                  placeholder="Seleccionar fechas"
                  persistent-placeholder
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="global-auth-inputs poppins mb-9"
                  dense
                  color="#2759A2"
                >
                  <template slot="prepend">
                    <v-icon class="calendar-icon">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="rangoFechas" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(rangoFechas)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <v-select
              label="Columnas visibles"
              placeholder="Seleccionar columnas visibles"
              persistent-placeholder
              class="global-auth-inputs poppins mb-9"
              dense
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-5 mb-5" />
    <div class="content-title-section mb-5">Consumo de tráfico de voz</div>
    <div class="content-card-dialog">
      <chart-global
        idChartDinamyc="ChartConsumoVoz"
        :apexChartOptions="optionsTraficoVoz"
        :apexChartSeries="seriesTraficoVoz"
      />
    </div>
    <div class="separator-line mt-5 mb-5" />
    <div class="content-title-section mb-5">Consumo de tráfico de datos</div>
    <div class="content-card-dialog">
      <chart-global
        idChartDinamyc="ChartConsumoDatos"
        :apexChartOptions="optionsTraficoDatos"
        :apexChartSeries="seriesTraficoDatos"
      />
    </div>
    <div class="separator-line mt-5 mb-5" />
    <div class="content-title-section mb-5">Consumo de tráfico SMS</div>
    <div class="content-card-dialog">
      <chart-global
        idChartDinamyc="ChartConsumoSMS"
        :apexChartOptions="optionsTraficoSMS"
        :apexChartSeries="seriesTraficoSMS"
      />
    </div> -->
  </div>
</template>

<script>
import ChartGlobal from "../../../components/ChartGlobal.vue";

export default {
  name: "layoutTabsControlConsumo",
  components: {
    ChartGlobal,
  },
  props: {
    tab: Number,
  },
  data() {
    return {
      colorPercent: "blue",
      rangoFechas: null,
      menu: false,
      oConsumption: {},
      oData: {},
      oSMS: {},
      oFixedInfo: {},
      oVoice: {},
      optionsTraficoVoz: {
        chart: {
          type: "bar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "30 enero",
            "04 febrero",
            "09 febrero",
            "14 febrero",
            "19 febrero",
            "24 febrero",
          ],
        },
        colors: "#79DEFC",
      },
      seriesTraficoVoz: [],

      optionsTraficoDatos: {
        chart: {
          type: "bar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "30 enero",
            "04 febrero",
            "09 febrero",
            "14 febrero",
            "19 febrero",
            "24 febrero",
          ],
        },
        colors: "#79DEFC",
      },
      seriesTraficoDatos: [],

      optionsTraficoSMS: {
        chart: {
          type: "bar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "30 enero",
            "04 febrero",
            "09 febrero",
            "14 febrero",
            "19 febrero",
            "24 febrero",
          ],
        },
        colors: "#79DEFC",
      },
      seriesTraficoSMS: [],
    };
  },
  activated() {
    if (this.tab == 3) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(
            `${URL_KITE}/${this.$route.params.id}/consupmtion`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {},
            }
          )
            .then((response) => {
              this.oConsumption = response.data.results.oConsumption;
              this.oData = this.oConsumption.oData;
              this.oSMS = this.oConsumption.oSMS;
              this.oVoice = this.oConsumption.oVoice;
              this.oFixedInfo = response.data.results.oFixedInfo;
              this.$emit("setFixedInfo", this.oFixedInfo);

              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    getTraficoVoz() {
      const newSeries = [
        {
          name: " ",
          data: [1, 3, 1, 1, 3, 1],
        },
      ];
      this.seriesTraficoVoz = newSeries;
    },
    getTraficoDatos() {
      const newSeries = [
        {
          name: " ",
          data: [5.7, 2.8, 2.8, 5.7, 3.7, 2.8],
        },
      ];
      this.seriesTraficoDatos = newSeries;
    },
    getTraficoSMS() {
      const newSeries = [
        {
          name: " ",
          data: [1, 3.5, 1, 1, 3.5, 1],
        },
      ];
      this.seriesTraficoSMS = newSeries;
    },
    getPorcentaje(sCurrent, sLimit) {
      if (sCurrent !== undefined || sLimit !== undefined) {
        let iCurrent = sCurrent;
        let iLimit = sLimit;
        let res = 0;
        // if (iCurrent > 0 && iLimit > 0) {
        res = (Number(iCurrent) / Number(iLimit)) * 100;
        // }
        return res === Infinity ? 100 : isNaN(res) ? 0 : res;
      }
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      if (this.tab == 3) {
        this.getData();
      }
    },
    refresh() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.calendar-icon {
  margin-top: -10px;
  color: #000;
}

.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.grey-txt {
  opacity: 0.25;
}

.card-consumo {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #3a79bd19;
  border-radius: 14px;
  padding: 20px;
}

.txt-title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-percent {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-consumo {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-umbrat {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}
</style>
<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      
      <div v-if="!activeCard" style="box-shadow: 0px 20px 40px #3a79bd19; border-radius: 10px">
        <v-simple-table fixed-header class="table-audit">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item_header, index_header) in headers" :key="index_header" :class="item_header.align"
                  class="table-header-text poppins">
                  {{ item_header.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="txt-item-table   text-right w-350-max-auto poppins w-space  ">
                  <div class="d-flex aling-center">
                    <v-icon class="thIcon">
                      mdi-chevron-up
                    </v-icon>
                    {{ item.sActionRegistred }}
                  </div>
                </td>
                <td class="text-left poppins w-250">
                  <div class="mb-n2">
                    <v-icon v-if="false" @click="goToUser(item.sLogId)" class="text-icon-launch">
                      mdi-launch</v-icon>
                    <span class=" txt-item-table text-left poppins">
                      {{ item.sNameAudit }}
                    </span>
                  </div>
                  <span class="txt-item-table-role">
                    {{ item.sRole }}
                  </span>
                </td>
                <td class="txt-item-table text-left  poppins global-body-action-register-audit">
                  <span class="content-message-audit">
                    {{ item.sMessage }}
                  </span>
                </td>
                <td class="txt-item-table text-left poppins w-165">
                  {{ item.tCreatedAt }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->
      <div v-else>
        <div>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                <div class="content-card-responsive position-relative">
                  <div class="d-flex">
                    <div class="d-flex justify-start align-center width-100">
                      <p class="txt-item-table-card-title poppins mb-0">
                        {{ item.sActionRegistred }}
                      </p>
                    </div>
                    <div class="d-flex justify-end align-center">
                      <v-btn @click="setExtendCard(index)" icon>
                        <v-icon class="icon-arrow-card" :id="'Icon' + index">mdi-chevron-up</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="mb-n2">
                    <v-icon v-if="false" @click="goToUser(item.sLogId)" class="text-icon-launch">
                      mdi-launch</v-icon>
                    <!-- txt-item-table-link -->
                    <span class="txt-item-table-card-item poppins">
                      {{ item.sNameAudit }}
                    </span>
                  </div>
                  <span class="txt-item-table-role">
                    {{ item.sRole }}
                  </span>
                  <!-- <p class="txt-item-table-card-item poppins mb-0">
                    {{ item.sNameAudit }}
                  </p> -->
                  <div class="content-message-audit-mobile" :id="'Card' + index">
                    <span class="txt-item-table-card-item poppins mb-0">
                      {{ item.sMessage }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- pagination -->
        <div class="content-pagination mt-4" style="background-color: transparent">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
    <!-- confirm status  -->
    <confirm-change-status-component :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus" />
  </div>
</template>

<script>
import lodash from "lodash";
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutClientsContent",
  props: {
    sSearch: String,
    sRegistredAction: String,
    sUserType: String,
    arrDates: Array,
  },
  data() {
    return {
      bLoading: false,
      items: [],
      headers: [
        {
          title: "Acción registrada",
          align: "text-left",
        },
        {
          title: "Nombre del auditor",
          align: "text-left",
        },
        {
          title: "Mensaje",
          align: "text-left",
        },
        {
          title: "Fecha de ingreso",
          align: "text-left",
        },
      ],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      activeCard: false,
      oChangeStatusOptions: {},
      emptyData: "Sin información.",
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    editItem: function (id) {
      this.$router.push({
        name: "detailClient",
        params: {
          id: id,
        },
      });
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (sEnterpriseId, bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URI}/enterprises/${sEnterpriseId}`,
        payload: { sUserType: bAction },
        bInputs: false,
        title: bAction == true ? "Desbloqueo de cliente" : "Bloqueo de cliente",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción restablecerá su acceso a la plataforma.</br>¿Desea continuar?"
            : "La siguiente acción restringirá su acceso a la plataforma.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Desbloquear" : "Bloquear",
      };
    },
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/enterprises/${id}`,
        title: "Eliminar cliente",
        description:
          "Eliminar un cliente es una acción irreversible </br>  ¿Desea continuar? ",
      };

      this.$emit("setOptions", options);
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    formatDate: function (date) {
      var _date = MOMENT(date, "DD-MM-YYYY");
      // return MOMENT(_date).format("DD/MMM/YYYY");
      return MOMENT(_date).locale("es").format("DD/MMM/YYYY").replace(".", "");
    },
    //helpers
    getFlag(sCountryCallingCodeOrigin) {
      switch (sCountryCallingCodeOrigin) {
        case "52":
          return [require("@/assets/images/mx.png")];
        case "593":
          return [require("@/assets/images/ecuador.png")];
        case "1":
          return [require("@/assets/images/us.png")];

        default:
          break;
      }
    },
    formatPhone: function (item) {
      let sCountryCallingCode = item.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 4),
              "-",
              item.sPhoneNumber.slice(4),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        case "1":
          return (
            "+" +
            item.sCountryCallingCode +
            " (" +
            item.sAreaCallingCode +
            ") " +
            [
              item.sPhoneNumber.slice(0, 3),
              "-",
              item.sPhoneNumber.slice(3),
            ].join("") +
            (item.sPhoneExtension ? " ext. " + item.sPhoneExtension + "" : "")
          );

        default:
          break;
      }
      // let result = "";

      // if (item.sExtension == "") {
      //   if (item.sAreaCallingCode !== "" && item.sAreaCallingCode !== "") {
      //     result =
      //       "+" +
      //       item.sCountryCallingCode +
      //       " " +
      //       item.sAreaCallingCode +
      //       item.sPhoneNumber;
      //   } else {
      //     result = "Sin teléfono";
      //   }
      // } else {
      //   result =
      //     "+" +
      //     item.sCountryCallingCode +
      //     " " +
      //     item.sAreaCallingCode +
      //     item.sPhoneNumber +
      //     " Ext. " +
      //     item.sExtension;
      // }
      // return result;
    },
    formatEmptyPhone(item) {
      let bln = true;
      if (item.sPhoneNumber === "" || item.sAreaCallingCode === "") {
        bln = false;
      }
      return bln;
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    getData: function () {
      this.bLoading = true;

      DB.get(`${URI}/logs`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          tStart: this.arrDates[0],
          tEnd: this.arrDates[1],
          // sUserType: this.sUserType,
          // sRegistredAction: this.sRegistredAction,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.items = response.data.results.map((e) => {
            return {
              ...e,
              sActionRegistred: e.sLogType,
              sNameAudit: e.oUser.sFullName,
              sRole: e.oUser.sUserType,
              sMessage: e.sMessage,
            }
          })
          this.numPages = response.data.iNumPages;
          this.iPageNumber = response.data.iNumPages;
          this.totalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setExtendCard(Id) {
      for (let i = 0; i < document.getElementsByClassName("content-message-audit-mobile").length; i++) {
        const element = document.getElementsByClassName("content-message-audit-mobile")[i];
        const element1 = document.getElementsByClassName("icon-arrow-card")[i];
        // setTimeout(() => {
        //   element.classList.remove("content-message-audit-mobile-extend");
        //   element1.classList.remove("icon-arrow-card-extend");
        // }, 2000);

      }

      let cardIsClose = document.getElementById("Card" + Id).classList.contains("content-message-audit-mobile-extend");

      if (cardIsClose) {
        document.getElementById(("Card" + Id)).classList.remove("content-message-audit-mobile-extend")
        document.getElementById("Icon" + Id).classList.remove("icon-arrow-card-extend")
      } else {
        document.getElementById(("Card" + Id)).classList.add("content-message-audit-mobile-extend")
        document.getElementById("Icon" + Id).classList.add("icon-arrow-card-extend")
      }

    }
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },
    sSearch: lodash.debounce(function () {
      this.getData();
    }, 500),
    sRegistredAction: function () {
      // this.iCurrentPage = 1;
      // this.getData();
    },
    sUserType: function () {
      // this.iCurrentPage = 1;
      // this.getData();
    },
    arrDates: function () {
      this.getData();
    },
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.card-inactive {
  border: 1px solid #e33f3f;
}

.card-active {
  border: 1px solid #2759a2;
}

.txt-item-table {
  font-size: 12px !important;
  align-items: center !important;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-link {
  font-size: 12px !important;
  align-items: center !important;

  letter-spacing: 0px;
  color: #0971fa;
  opacity: 1;
}

.text-icon-launch {
  color: #0971fa;
  /* margin-top: -5px; */
  font-size: 10px;
  cursor: pointer;
}

.txt-item-table-role {
  font-size: 9px;
  color: #A1ACC3
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.content-status-mobile-card {
  width: 40px;
  position: absolute;
  right: 30px;
  top: 10px;
}

.content-message-audit-mobile {
  height: auto;
  max-height: 25px;
  width: 100%;
  max-width: 50px;
  min-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.content-message-audit-mobile-extend {
  max-height: 400px !important;
  white-space: inherit !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.icon-arrow-card {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.icon-arrow-card-extend {
  transform: rotate(3.142rad);
}
</style>

<template>
  <v-dialog v-model="bDialogSendSms" :width="screenWidth" persistent>
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="closeDialog" icon>
        <v-icon color="#000" size="16px"> mdi-close </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins mb-0">Envío de sms</p>
      <p class="content-card-txt-description poppins mt-2">
        Escribe tu mensaje de prueba que será enviado a las tarjetas que
        seleccionaste.
      </p>
      <div class="mt-9">
        <v-textarea v-model="mensaje" label="Mensaje" placeholder="Escribe tu mensaje" dense color="#2759A2"
          class="global-inputs poppins" background-color="transparent" maxlength="500" persistent-placeholder rows="4">
        </v-textarea>
      </div>
      <div class="content-buttons">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-flex-start
                ">
                <v-btn @click="closeDialog" class="button-secondary poppins" elevation="0" width="100%">
                  Regresar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn @click="send" :loading="bLoading" :disabled="!validationForm" class="button-primary poppins"
                  elevation="0" width="100%">
                  Enviar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "SendSmsComponent",
  props: {
    selectedItems: Array,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      mensaje: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    send: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
        if (this.getPermissionsActionsGlobal()) {
          const config = {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
          },
            payload = {
              aICC: this.selectedItems, //this.selectedItems,
              sText: this.mensaje,
              bDeliveryReport: true,
              sOriginMsisdn: null,
            };

          DB.post(`${URL_KITE}/sms`, payload, config)
            .then((response) => {
              this.bLoading = false;

              this.mixSuccess(response.data.message);
              this.closeDialog();
              this.$store.commit("refresher", true);
            })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message, error.response.status);
            });
        } else {
          this.bLoading = false;
          this.mixError(this.$store.state.sMessageErrorAccess, 0);
          this.closeDialog();
          this.$store.commit("refresher", true);
        }

      }).catch((error) => {
        this.bLoading = false;
        this.mixError(error.message, error.code);
        this.closeDialog();
        this.$store.commit("refresher", true);
      });
    },
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.screenWidth = 420 + "px";
      } else {
        this.screenWidth = 100 + "%";
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogSendSms", false);
      this.mensaje = "";
    },
  },
  computed: {
    bDialogSendSms: function () {
      return this.$store.state.bDialogSendSms;
    },
    validationForm: function () {
      return this.mensaje !== "";
    },
  },
  watch: {
    bDialogSendSms: function () {
      if (this.bDialogSendSms) {
      }
    },
  },
};
</script>

<style scoped>
.content-inputs {
  margin-top: 50px;
}

.content-buttons {
  margin-top: 25px;
}
</style>
<template>
  <div>
    <layout-notifications-header @setDialogAdd="setDialogAdd" />
    <layout-notifications-searchbar
      @setStatusId="setStatusId"
      @setDates="setDates"
    />
    <layout-notifications-content
      @setOptions="setOptions"
      :statusId="statusId"
      :arrDates="arrDates"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutNotificationsHeader from "@/layouts/Notifications/Header.vue";
import LayoutNotificationsContent from "@/layouts/Notifications/Content.vue";
import LayoutNotificationsSearchbar from "@/layouts/Notifications/Searchbar.vue";

export default {
  name: "NotificationView",
  data() {
    return {
      bDialogAdd: false,
      oOptions: {},
      statusId: undefined,
      arrDates: [],
    };
  },
  methods: {
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setStatusId: function (val) {
      this.statusId = val;
    },
    setDates: function (val) {
      this.arrDates = val;
    },
  },
  components: {
    LayoutNotificationsHeader,
    LayoutNotificationsContent,
    LayoutNotificationsSearchbar,
  },
};
</script>
<template>
  <v-dialog v-model="bDialog" persistent :width="sScreenHeight + '%'">
    <div class="content-card-dialog">
      <v-btn @click="$emit('setToogleDialog', false)" class="content-card-button-close" icon>
        <v-icon color="#000" size="16px"> mdi-close </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins">Modificación de estado</p>
      <!-- body -->
      <div class="mt-9 mb-9">
        <v-select v-model="sStatus" :items="aStatus" item-text="sDestinationStatusName"
          item-value="sDestinationStatusId" label="Estado" placeholder="Seleccionar estado" dense color="#2759A2"
          class="global-auth-inputs poppins mb-9" persistent-placeholder no-data-text="No hay datos disponibles">
        </v-select>
        <!-- <v-textarea v-model="comentarios" label="Comentarios" placeholder="Comentarios" dense color="#2759A2"
          class="global-auth-inputs poppins" maxlength="500" persistent-placeholder rows="5"></v-textarea> -->
      </div>
      <!-- buttons -->
      <div class="content-buttons">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-flex-start
                ">
                <v-btn class="button-secondary poppins" elevation="0" width="100%"
                  @click="$emit('setToogleDialog', false)">
                  Cancelar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn class="button-primary poppins" elevation="0" width="100%" @click="send" :loading="bLoading"
                  :disabled="!validateForm">
                  Actualizar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutDetailSimsChangeStatus",
  props: {
    bDialog: Boolean,
    itemsSims: Object,
  },
  data() {
    return {
      aStatus: [],
      sScreenHeight: "",
      sStatus: "",
      requestId: "",
      comentarios: "",
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    send: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
            },
              payload = {
                sSIMRequestStatusId: this.sStatus,
                // sComments: this.comentarios,
              };

            DB.post(
              `${URI}/sim-requests/${this.$route.params.id}/status`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;

                this.mixSuccess(response.data.message);
                this.$emit("setToogleDialog", false);
                this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.bLoading = false;

            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.$emit("setToogleDialog", false);
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.$emit("setToogleDialog", false);
          this.$store.commit("refresher", true);
        })
    },
    getStatus: function () {
      this.bLoading = true;

      DB.get(`${URI}/status/${this.requestId}/adjacency`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.bLoading = false;
          this.aStatus = response.data.results.aAdjacency;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      // if (window.innerWidth < 960) {
      //   this.sScreenHeight = "100%";
      // } else {
      //   this.sScreenHeight = "40%";
      // }

      if (window.innerWidth < 599) {
        this.sScreenHeight = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.sScreenHeight = 100;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.sScreenHeight = 40;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.sScreenHeight = 30;
      } else if (window.innerWidth > 1904) {
        this.sScreenHeight = 30;
      }
    },
  },
  computed: {
    validateForm: function () {
      return this.sStatus != "" ;
    },
  },
  watch: {
    bDialog: function () {
      if (this.bDialog) {
        this.requestId =
          this.itemsSims.SIMRequestStatusRecord[0].sSIMRequestStatusId;
        this.getStatus();
      }
    },
  },
};
</script>

<style>
</style>
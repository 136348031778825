<template>
    <div>
        <v-row>
            <v-col cols="12" class="ml-0 mr-0 mt-3">
                <div class="information-title">
                    Descuentos
                </div>
            </v-col>
            <v-col cols="12" class="ml-0 mr-0">
                <v-simple-table v-if="!bMobile">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th v-for="(item, i) in aHeader" :key="i" :class="item.sClassHeader">
                                    {{ item.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in aDiscounts" :key="index">
                                <td>{{ item.sFolio }}</td>
                                <td>
                                    <div v-if="item.bEdit">
                                        <v-text-field v-model="sDescriptionEdit" color="#A1ACC3" class="rounded-lg"
                                            :hide-details="true" outlined dense placeholder="Descripción">
                                        </v-text-field>
                                    </div>
                                    <div v-else>
                                        {{ item.sDescription }}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.bEdit">
                                        <input-currency-global :flagModal="item.bEdit" labelGlobal=""
                                            classDynamic="global-inputs poppins width-100" :bOutlined="true"
                                            :bDense="true" :bImportantData="false"
                                            @changeMontoGlobal="changeImportedEdit" :modelValue="sTotalEdit"
                                            sPrefix="$" />
                                    </div>
                                    <div v-else>
                                        ${{ formatMoneyGlobal(item.sTotal) }} MXN
                                    </div>
                                </td>
                                <td>
                                    <div v-if="item.bEdit">
                                        <v-btn v-if="getPermissionsActionsGlobal()" elevation="0"
                                            @click="editaDiscounts(item)" :disabled="!validationFormEdit" icon
                                            class="button-primary btn-add-discounts">
                                            Editar
                                        </v-btn>
                                    </div>
                                    <div v-else class="d-flex justify-center">
                                        <v-icon v-if="getPermissionsActionsGlobal()" color="#000000"
                                            @click="setEditItem(item)">mdi-pencil</v-icon>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="bEmptyDiscounts">
                                <td class="text-center txt-empty-global" :colspan="iHeader">
                                    No hay información
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr v-if="bAddDiscounts">
                                <td>
                                    <div class="d-flex pt-2 pb-2">
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex pt-2 pb-2">
                                        <v-text-field v-model="sDescription" color="#A1ACC3" class="rounded-lg"
                                            :hide-details="true" outlined dense placeholder="Descripción">
                                        </v-text-field>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex pt-2 pb-2">
                                        <input-currency-global :flagModal="bAddDiscounts" labelGlobal=""
                                            classDynamic="global-inputs poppins width-100" :bOutlined="true"
                                            :bDense="true" :bImportantData="false" @changeMontoGlobal="changeImported"
                                            :modelValue="sTotal" sPrefix="$" />
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex pa-0">
                                        <v-btn v-if="getPermissionsActionsGlobal()" elevation="0" @click="addDiscounts"
                                            :disabled="!validationForm" icon class="button-primary btn-add-discounts">
                                            Añadir
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                            <tr v-else>
                                <td colspan="4">
                                    <div class="content-add-consumption">
                                        <v-btn v-if="getPermissionsActionsGlobal()" elevation="0" @click="setAddItem"
                                            icon class="button-secondary btn-add-discounts">
                                            Añadir descuento
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
                <div v-else>
                    <v-container class="pa-0" fluid>
                        <v-row>
                            <v-col v-for="(item, index) in aDiscounts" :key="index" cols="12" sm="6">
                                <div class="content-card-responsive  position-relative">
                                    <div class="height-100">
                                        <div>
                                            <div class="content-first-row-card">
                                                <div class="content-text-title">
                                                    <p class="txt-item-table-card-title poppins mb-3">
                                                        {{ item.sFolio }}
                                                    </p>
                                                </div>
                                                <div class="content-menu-actions">
                                                    <v-menu offset-y content-class="content-menu-options" attach left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div class="content-actions">

                                                                <div v-bind="attrs" v-on="on"
                                                                    class="content-menu-options-dots">
                                                                    <v-icon color="#000" size="20px">
                                                                        mdi-dots-vertical
                                                                    </v-icon>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div class="circle-content-options">
                                                            <div>
                                                                <v-list dense>
                                                                    <!-- @click="detailItem(item.sAdvanceInvoiceId)" -->
                                                                    <v-list-item link
                                                                        @click="fillItemEdit(item), setDialogAddOrEditDiscounts(false)">
                                                                        <v-list-item-icon
                                                                            v-if="getPermissionsActionsGlobal()">
                                                                            <v-icon color="#000" size="14px">
                                                                                mdi-pencil
                                                                            </v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-content
                                                                            v-if="getPermissionsActionsGlobal()"
                                                                            class="poppins">
                                                                            Editar
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </div>
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </div>
                                            <div class=" display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Descripción</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0">
                                                        {{ item.sDescription }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="flex-wrap display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Importe</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0">$ {{
                                                    formatMoneyGlobal(item.sTotal)
                                                    }} MXN</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <!--#region CONTENIDO DE BOTONES -->
                                <div class="content-btns-global">
                                    <div class="content-btn-edit">
                                        <v-btn v-if="getPermissionsActionsGlobal()" class="button-secondary"
                                            width="100%" @click="setDialogAddOrEditDiscounts(true)">
                                            Añadir descuento
                                        </v-btn>

                                    </div>
                                </div>
                                <!--#endregion CONTENIDO DE BOTONES -->
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
        <DialogAddDiscounts :bDialogAddOrEditDiscounts="bDialogAddOrEditDiscounts" :bAddOrEdit="bAddOrEdit"
            :oItem="oItem" @setDialogAddOrEditDiscounts="setDialogAddOrEditDiscounts" />
    </div>
</template>

<script>
import DialogAddDiscounts from './DialogAddOrEditDiscounts.vue';
export default {
    props: {
        aDiscounts: Array
    },
    data() {
        return {
            aHeader: [
                {
                    text: 'Folio',
                    align: 'start',
                    sortable: false,
                    value: 'sFolio',
                    sClassHeader: "txt-color-black-global"
                },
                {
                    text: "Descripción",
                    sortable: false,
                    value: "sDescription",
                    sClassHeader: "txt-color-black-global"
                },
                {
                    text: "Importe",
                    sortable: false,
                    value: "sTotal",
                    sClassHeader: "txt-color-black-global"
                },
                {
                    text: "Acciones",
                    sortable: false,
                    value: "sActions",
                    align: "center",
                    sClassHeader: "w-120 text-center txt-color-black-global"
                },
            ],
            bMobile: false,
            sDescription: "",
            sDescriptionEdit: "",
            sTotal: 0,
            sTotalEdit: 0,
            bAddDiscounts: false,
            bDialogAddOrEditDiscounts: false,
            bAddOrEdit: false,
            oItem: {}
        };
    },
    beforeMount() {

    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth > 959.99) {
                this.bMobile = false;
                this.bDialogAddOrEditDiscounts = false;
            }
            else {
                this.bMobile = true;
                this.bAddDiscounts = false;
                this.sTotal = 0;

            }
        },
        changeImported(value) {
            if (value !== "") {
                this.sTotal = parseFloat(value);
            } else {
                this.sTotal = 0;
            }
        },
        changeImportedEdit(value) {
            if (value !== "") {
                this.sTotalEdit = parseFloat(value);
            } else {
                this.sTotalEdit = 0;
            }
        },
        setEditItem(item) {
            this.bAddDiscounts = false;
            for (let i = 0; i < this.aDiscounts.length; i++) {
                this.aDiscounts[i].bEdit = false;
            }
            item.bEdit = true;
            this.sDescriptionEdit = item.sDescription;
            this.sTotalEdit = item.sTotal;
        },
        editDiscounts(item) {
            for (let i = 0; i < this.aDiscounts.length; i++) {
                const element = this.aDiscounts[i];
            }
        },
        setAddItem() {
            for (let i = 0; i < this.aDiscounts.length; i++) {
                this.aDiscounts[i].bEdit = false;
            }
            this.bAddDiscounts = true;
        },
        editaDiscounts(item) {
            this.bLoading = true;
            this.$store.dispatch("getPermissionsByUserGlobal").
                then((resp) => {
                    if (this.getPermissionsActionsGlobal()) {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.sToken}`,
                            },
                        }, payload = {
                            sDescription: this.sDescriptionEdit,
                            dAmount: this.sTotalEdit
                        };
                        DB.put(`${URI}/advance-invoices/${this.$route.params.id}/discounts/${item.sDiscountId}`, payload, config)
                            .then((response) => {
                                this.bLoading = false;
                                this.bAddDiscounts = false;
                                this.mixSuccess(response.data.message);
                                this.$store.commit("refresher", true);
                            })
                            .catch((error) => {
                                this.bLoading = false;
                                this.mixError(error.response.data.message, error.response.status);
                            });
                    } else {
                        this.bLoading = false;
                        this.bAddDiscounts = false;
                        this.mixError(this.$store.state.sMessageErrorAccess, 0);
                        this.$store.commit("refresher", true);

                    }
                }).catch((error) => {
                    this.bLoading = false;
                    this.bAddDiscounts = false;
                    this.mixError(error.message, error.code);
                    this.$store.commit("refresher", true);
                })
        },
        addDiscounts() {
            this.bLoading = true;
            this.$store.dispatch("getPermissionsByUserGlobal").
                then((resp) => {
                    if (this.getPermissionsActionsGlobal()) {
                        const config = {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.sToken}`,
                            },
                        }, payload = {
                            sDescription: this.sDescription,
                            dAmount: this.sTotal
                        };
                        DB.post(`${URI}/advance-invoices/${this.$route.params.id}/discounts`, payload, config)
                            .then((response) => {
                                this.bLoading = false;
                                this.bAddDiscounts = false;
                                this.mixSuccess(response.data.message);
                                this.$store.commit("refresher", true);
                            })
                            .catch((error) => {
                                this.bLoading = false;
                                this.mixError(error.response.data.message, error.response.status);
                            });
                    } else {
                        this.bLoading = false;
                        this.bAddDiscounts = false;
                        this.mixError(this.$store.state.sMessageErrorAccess, 0);
                        this.$store.commit("refresher", true);

                    }
                }).catch((error) => {
                    this.bLoading = false;
                    this.bAddDiscounts = false;
                    this.mixError(error.message, error.code);
                    this.$store.commit("refresher", true);
                })
        },
        fillItemEdit(item) {
            this.oItem = item;
        },
        setDialogAddOrEditDiscounts(bAddOrEdit) {
            this.bAddOrEdit = bAddOrEdit
            this.bDialogAddOrEditDiscounts = !this.bDialogAddOrEditDiscounts;
        },

    },
    computed: {
        validationForm: function () {
            return (
                this.sDescription !== "" &&
                this.sTotal > 0
            );
        },
        validationFormEdit: function () {
            return (this.sDescriptionEdit !== "" &&
                this.sTotalEdit > 0);
        },
        bEmptyDiscounts() {
            return this.aDiscounts ? this.aDiscounts.length === 0 : 0;
        },
        iHeader() {
            return this.aHeader ? this.aHeader.length : 0
        }
    },
    components: { DialogAddDiscounts }
}
</script>

<style scoped>
.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 200px;
}

.content-add-consumption {
    padding: 10px 0px 10px 0px;
}

.btn-add-discounts {
    width: 150px;
}

/* styles cards */
.txt-item-table-card-title {
    font-size: 16px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 600;
    overflow-wrap: break-word !important;

}

.txt-item-table-card-item {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.txt-item-table-card-item-empty {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #818181;
    opacity: 1;
    font-style: italic;
}

.content-text-title {
    width: 90%;
    max-width: 90%;
    min-width: auto;
}

.content-actions {
    display: flex;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    margin-left: 10px;
}

.content-first-row-card {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}



.content-status-mobile-card {
    width: 70px;
    display: flex;
    /* position: absolute; */
    right: 30px;
    top: 10px;
}


.px-card-test-select-all {
    width: fit-content;
}

.px-card-text-mb {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
}

.flex-wrap {
    flex-wrap: wrap;
}

.px-txt-card-title {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
}

.px-txt-card-item {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 5px;
}

.content-left {
    width: 90px;
    display: flex;
    height: 100%;
    align-self: start
}
</style>
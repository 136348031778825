<template>
  <div>
    <div v-if="items.length > 0" class="mt-n7">
      <!-- table content -->
      <div class="content-notifications-all">
        <div v-for="(item, i) in items" :key="i">
          <div class="content-notifications-actions">
            <div @click="setNotificationReadGlobal(item)" class="content-notifications">
              <p class="txt-title-notification" :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">{{ item.sCustomTitle
              }}</p>
              <p class="txt-description-notification" :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">{{
                  item.sCustomMessage
              }}</p>
              <p class="txt-date-notification" :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">{{ item.tCreatedAt }}
              </p>
            </div>
            <div class="content-actions">
              <div @click="setNotificationReadGlobal(item)" v-if="!bMobile" class="content-view">
                <div class="point-view" v-if="!item.oRead.bRead"></div>
              </div>
              <div class="content-delete">
                <v-menu offset-y content-class="content-menu-options" attach left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="!bMobile ? deleteItem(item.sNotificationId) : ''" icon v-bind="bMobile ? attrs : ''"
                      v-on="bMobile ? on : ''">
                      <v-icon>
                        {{ bMobile ? 'mdi-dots-vertical' : 'mdi-close' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <div class="circle-content-options">
                    <div>
                      <v-list dense>
                        <v-list-item link @click="deleteItem(item.sNotificationId)">
                          <v-list-item-icon>
                            <v-icon size="14px">
                              mdi-close
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="text-delete-card poppins">
                            Eliminar
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-menu>

              </div>
              <div @click="setNotificationReadGlobal(item)" v-if="bMobile" class="content-view">
                <div class="point-view" v-if="!item.oRead.bRead"></div>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <!-- pagination -->
        <div class="content-pagination">
          <div class="display-flex align-items-center justify-content-flex-end">
            <p class="txt-rows-table mb-0 mr-2">Filas por página:</p>
            <v-menu offset-y content-class="txt-number-pages-table">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
                  <div class="txt-pages-table poppins">
                    {{ iItemsPerPage }}
                  </div>
                  <v-icon color="#a3a3a3" size="16px"> mdi-menu-down </v-icon>
                </div>
              </template>
              <div>
                <div>
                  <v-list dense>
                    <v-list-item v-for="(item_pages, index_pages) in pages" :key="index_pages"
                      @click="changeNumPages(item_pages)" link>
                      <v-list-item-content class="poppins">
                        {{ item_pages }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-menu>
            <p class="txt-current-page-table poppins ml-2 mb-0">
              {{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}
            </p>
            <v-btn @click="prev" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn @click="next" icon>
              <v-icon color="#a3a3a3" size="18px"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- card content -->

    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "LayoutNotificationsContent",
  props: {
    arrDates: Array,
    statusId: Number,
  },
  data() {
    return {
      bLoading: false,
      items: [],
      //pagination items
      pages: ["30", "60", "90", "120"],
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      tableHeight: 0,
      bMobile: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    deleteItem: function (id) {
      let options = {
        active: true,
        id,
        api: `/notifications/${id}`,
        title: "Eliminar notificación",
        description:
          "¿Estás seguro de eliminar la notificación? <br/> Al realizar esta <br/> acción no podrá revertirse",
      };
      this.$emit("setOptions", options);
    },
    viewItem: function (item) {
      this.$router.push({
        name: "detailSims",
        params: {
          id: item.sSIMRequestId,
        },
      });
    },
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    changeNumPages: function (val) {
      this.iItemsPerPage = val;
      this.iCurrentPage = 1;
      this.getData();
    },
    formatDate: function (date) {
      return MOMENT(date).locale("es").format("DD/MMM/YYYY");
    },
    setNotificationReadGlobal(item) {
      this.$store
        .dispatch("setNotificationReadGlobal", item)
        .then((resp) => {
          // this.$router.push({
          //   name: item.sCode,
          //   params: {
          //     id: item.sCustomId,
          //   },
          // }).catch(() => { });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prev: function () {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    next: function () {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    getData: function () {
      this.bLoading = true;

      DB.get(`${URI}/notifications`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          // sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          bRead: this.statusId,
          // sPlanId: this.sPlanId,
          tStart: this.arrDates[0],
          tEnd: this.arrDates[1],
          // sSIMRequestStatusId: this.statusId,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.items = response.data.results;

          this.numPages = response.data.iNumPages;
          this.iPageNumber = response.data.iNumPages;
          this.totalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getColorStatus(sStatus) {
      let oColors = {};
      switch (sStatus.lastStatus) {
        case "7f595ba0-3275-435f-a48e-4dfaa811702a": //Solicitud enviada
          oColors.sColorText = "#2C9DCE"
          oColors.sColorBackground = "#C0E2F0"
          return oColors;
        case "cd495340-fa3b-4234-89cd-b56c59db318d": //Evaluando
          oColors.sColorText = "#E7C127"
          oColors.sColorBackground = "#F8EDBE"

          return oColors;
        case "5834ff8c-0c17-42ee-afd1-5a84163363e8": //Confirmado
          oColors.sColorText = "#8E4DFF"
          oColors.sColorBackground = "#DDCAFD"
          return oColors;
        case "2a94cfb4-b4f1-420d-a513-c3a368946a48": //Enviado
          oColors.sColorText = "#68BBDF"
          oColors.sColorBackground = "#D2EBF6"

          return oColors;
        case "8ee1565e-ee98-462d-a445-b1d7248dbaed": //Entregado
          oColors.sColorText = "#1EA966"
          oColors.sColorBackground = "#BCE5D1"

          return oColors;
        case "875c3c08-cf5a-424b-988b-e724d33e4e8b": //Cancelado
          oColors.sColorText = "#D77580"
          oColors.sColorBackground = "#F3D6D9"

          return oColors;
        case "e6ea266d-ed35-40f4-9f06-98cffb846c9b": //Reposición
          oColors.sColorText = "#424657"
          oColors.sColorBackground = "#C6C8CD"

          return oColors;

        default:
          break;
      }
    }
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
    iCurrentPage: function () {
      this.getData();
    },

    statusId: function () {
      this.iCurrentPage = 1;
      this.getData();
    },
    arrDates: function () {
      this.getData();
    },
  },
};
</script>

<style scoped>
.content-notifications-all {
  background-color: #ffffff;
  box-shadow: 0px 20px 40px #3a79bd19;
  border-radius: 10px
}

.content-notifications-actions {
  display: flex;
  padding: 25px 15px 15px 25px;
}

.content-notifications-actions:hover {
  display: flex;
  padding: 25px 15px 15px 25px;
  background-color: #e5e5e545;
  cursor: pointer;
}

.content-notifications {
  width: 100%;
}

.txt-title-notification {
  font-size: 14px;
  margin-bottom: 2px;
  color: #2759A2;
}

.txt-description-notification {
  font-size: 13px;
  margin-bottom: 2px;
  color: #283C4D;
  overflow-wrap: break-word;

}

.txt-date-notification {
  font-size: 13px;
  margin-bottom: 2px;
  color: #A1ACC3;
}


.content-actions {
  display: flex;
}

.content-view {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.point-view {
  height: 10px;
  width: 10px;
  background-color: #2759A2;
  border-radius: 100%;
}

.txt-f-b {
  font-weight: 600;
}

.content-delete {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-actions {
    display: block;
    margin-left: 10px;
  }

  .content-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .point-view {
    height: 10px;
    width: 10px;
    background-color: #2759A2;
    border-radius: 100%;
  }

  .content-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-actions {
    display: block;
    margin-left: 10px;
  }

  .content-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .point-view {
    height: 10px;
    width: 10px;
    background-color: #2759A2;
    border-radius: 100%;
  }

  .content-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
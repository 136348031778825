import Vue from 'vue';
import store from '@/store'
import VueRouter from 'vue-router';
import Login from "@/views/Login.vue";
import ForgotPassword from '@/views/ForgotPassword.vue';
import OutsideContent from "@/views/OutsideContent.vue";
import InsideContent from '@/views/InsideContent.vue';
import Dashboard from '@/views/Dashboard.vue';
import Sims from '@/views/Sims.vue';
import DetailSims from '@/views/DetailSims.vue';
import Administrators from '@/views/Administrators.vue';
import AddAdministrators from '@/views/AddAdministrators.vue';
import DetailAdministrators from '@/views/DetailAdministrators.vue';
import Clients from '@/views/Clients.vue';
import AddClients from '@/views/AddClients.vue';
import DetailClient from '@/views/DetailClient.vue';
import Profile from '@/views/Profile.vue';
import SimCards from '@/views/SimCards.vue';
import DetailSimCards from '@/views/DetailSimCards.vue';
import Promotions from '@/views/Promotions.vue';
import DetailPromotions from '@/views/DetailPromotions.vue';
import AddPromotions from '@/views/AddPromotions.vue';
import Notifications from '@/views/Notifications.vue';
import Audit from '@/views/Audit.vue';
import Prebilling from '@/views/Prebillings.vue';
import DetailPrebilling from '@/views/DetailPrebillings.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      authentication: false
    },
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          authentication: false
        },
        // route level code-splitting
        component: Login
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        meta: {
          authentication: false
        },
        // route level code-splitting
        component: ForgotPassword
      },
    ],
    component: OutsideContent,
    redirect: '/login'
  },
  {
    path: '*',
    meta: {
      authentication: false
    },
    // route level code-splitting
    redirect: '/login'
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      authentication: true
    },
    // route level code-splitting
    component: InsideContent,
    redirect: '/admin/dashboard',
    // routes that are accessible to admins.
    children: [
      {
        path: '/admin/inicio',
        name: 'inicio',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Dashboard
      },
      {
        path: '/admin/sims',
        name: 'sims',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Sims
      },
      {
        path: '/admin/sims/:id',
        name: 'detailSims',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailSims
      },
      {
        path: '/admin/administrators',
        name: 'administrators',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Administrators
      },
      {
        path: '/admin/administrators/add',
        name: 'addAdministrators',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: AddAdministrators
      },
      {
        path: '/admin/administrators/:id',
        name: 'detailAdministrators',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailAdministrators
      },
      {
        path: '/admin/clients',
        name: 'clients',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Clients
      },
      {
        path: '/admin/clients/add',
        name: 'addClients',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: AddClients
      },
      {
        path: '/admin/clients/:id',
        name: 'detailClient',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailClient
      },
      {
        path: '/admin/profile',
        name: 'profile',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Profile
      },
      {
        path: '/admin/sim-cards',
        name: 'sim-cards',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: SimCards
      },
      {
        path: '/admin/sim-cards/:id',
        name: 'detailSimCards',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailSimCards
      },
      {
        path: '/admin/promotions/add',
        name: 'addPromotions',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: AddPromotions
      },
      {
        path: '/admin/promotions',
        name: 'promotions',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Promotions
      },
      {
        path: '/admin/promotions/:id',
        name: 'detailPromotions',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailPromotions
      },
      {
        path: '/admin/notifications',
        name: 'notifications',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Notifications
      },
      {
        path: '/admin/audit',
        name: 'audit',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Audit
      },
      {
        path: '/admin/prebilling',
        name: 'prebilling',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: Prebilling
      },
      {
        path: '/admin/prebilling/:id',
        name: 'detailPrebilling',
        meta: {
          authentication: true
        },
        // route level code-splitting
        component: DetailPrebilling
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// control router authentication
router.beforeEach((to, from, next) => {
  if (to.name !== "sim-cards" && to.name !== "detailSimCards") {
    store.commit("setFilterTypeGlobal", null);
    store.commit("setSearchGlobal", "");
    store.commit("setCurrentPageGlobal", 1);
  }
  // for test
  // next()
  // if token is not null session is started
  var bAuthenticated = (store.state.sToken !== '');
  // check it auth is needed
  if (to.meta.authentication) {
    if (bAuthenticated) {
      store
        .dispatch("getNotificationGlobal")
        .then((resp) => {
        })
        .catch((err) => {
          console.log(err);
        });
      next()
    } else {
      next('/login')
    }
  } else {
    // store
    //   .dispatch("getNotificationGlobal")
    //   .then((resp) => {
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    if ((to.name == "login" || to.name == 'forgot-password') && bAuthenticated) {
      next('/admin/inicio');
    } else {
      next()
    }
  }
})

export default router

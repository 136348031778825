<template>
  <div class="content-search mt-9 mb-13">

    <div class="position-relative" @click="menu = true">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="rangoFechas"
        transition="scale-transition" offset-y min-width="auto" attach left>
        <template v-slot:activator="{ on, attrs }">
          <div class="content-input-date-range">
            <v-combobox v-model="rangoFechas" v-bind="attrs" v-on="on" label="Rango de fechas"
              placeholder="Seleccionar fechas" persistent-placeholder readonly class="global-auth-inputs" dense
              color="#2759A2" append-icon="mdi-calendar" chips small-chips multiple clearable></v-combobox>
          </div>
        </template>
        <v-date-picker v-model="rangoFechas" :max="nowDate" no-title scrollable multiple range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false; $refs.menu.save((dates = [])); rangoFechas = [];">
            Cancelar
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(rangoFechas); saveDates();"
            :disabled="rangoFechas.length < 2">
            Aceptar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>

    <div class="content-input-search">
      <v-select v-model="iStatus" :items="aStatus" item-text="sText" item-value="sValue" label="Buscar por"
        placeholder="Seleccionar estado" dense color="#2759A2" class="global-auth-inputs poppins" persistent-placeholder
        no-data-text="No hay datos disponibles"></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutNotificationsSearchbar",
  data() {
    return {
      sSearch: "",
      resultPlanDatos: undefined,
      resultStatus: undefined,
      rangoFechas: [],
      menu: false,
      widthMenu: "",
      nowDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: new Date(),
      picker: new Date().toISOString().substr(0, 10),
      landscape: false,
      reactive: false,
      bMobile: false,
      aStatus: [{ sText: "Todas", sValue: null }, { sText: "No leídas", sValue: 0 }, { sText: "Leídas", sValue: 1 }],
      iStatus: null
    };
  },
  beforeMount() {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 600) {
        this.widthMenu = "380px";
      } else {
        this.widthMenu = "480px";
      }

      if (window.innerWidth > 959.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    saveDates: function () {
      this.$emit("setDates", this.rangoFechas);
    },
  },
  computed: {
    getEndDate() {
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        10
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  watch: {
    rangoFechas: function () {
      if (this.rangoFechas.length == 2) {
        if (this.rangoFechas[0] > this.rangoFechas[1]) {
          let fecha_inicial = this.rangoFechas[1];
          this.rangoFechas = [];
          this.rangoFechas.push(fecha_inicial);
          this.$emit("setDates", this.rangoFechas);
        }
      }

      if (this.rangoFechas.length == 0) {
        this.$emit("setDates", []);
      }
    },
    iStatus: function () {
      this.$emit("setStatusId", this.iStatus);
    },
  },
};
</script>

<style scoped>
.content-search {
  display: flex;
  width: 100%;
}

.content-input-search {
  width: 260px;
  max-width: 260px;
  min-width: 260px;
  margin-left: 25px;

}

.content-input-date-range {
  width: 280px;
  max-width: 280px;
  min-width: 280px;
}

.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search {
    display: block;
    width: 100%;
  }

  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;

  }

  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-search {
    display: block;
    width: 100%;
  }

  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;

  }

  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;

  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
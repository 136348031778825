<template>
  <v-app>
      <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style>

html {
  overflow: auto !important;
}

.v-application {
  font-family: "Poppins", sans-serif !important;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.separator {
  border-bottom: 1px solid #a1acc3;
}

.width-100 {
  width: 100%;
}

.w-auto {
  width: 100%;
}

.w-350-max-auto {
  width: 350px;
  max-width: auto;
  min-width: 300px;
}

.w-400 {
  width: 400px;
  max-width: 400px;
  min-width: 400px;
}

.w-260 {
  width: 260px;
  max-width: 260px;
  min-width: 260px;
}

.w-250 {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}

.w-200 {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.w-165 {
  width: 165px;
  max-width: 165px;
  min-width: 165px;
}

.w-150 {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.w-120 {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}

.w-80 {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}

.w-30 {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}

.min-w-200 {
  min-width: 200px;
}

.w-space {
  white-space: nowrap !important;
}


.height-100 {
  height: 100%;
}

.txt-color-black-global {
  color: #000000 !important;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: center;
}

.align-items-flex-end {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: auto;
}

.txt-bold {
  font-weight: bold;
}

/* container global style  */

/* inputs global style */
.global-inputs .v-text-field__details {
  display: none;
}

.global-inputs .v-input__slot {
  /* background: #f2f6fe 0% 0% no-repeat padding-box !important; */
  /* box-shadow: 0px 3px 6px #00000029 !important; */
  border-radius: 9px !important;
  font-size: 12px;
}

/* .global-inputs fieldset { */
/* border: 1px solid #e5e5e5 !important; */
/* } */

.global-inputs input {
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.global-inputs .v-select__slot .v-label,
.global-inputs .v-text-field__slot .v-label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.global-auth-inputs .v-text-field__details {
  display: none;
}

.global-auth-inputs .v-input__slot {
  /* background: #f2f6fe 0% 0% no-repeat padding-box !important; */
  /* box-shadow: 0px 3px 6px #00000029 !important; */
  border-radius: 9px !important;
  font-size: 12px;
}

.global-auth-inputs input {
  color: #000000 !important;
  letter-spacing: 0px !important;
}

.global-auth-inputs .v-select__slot .v-label,
.global-auth-inputs .v-text-field__slot .v-label {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: grey !important;
  /* font-style: italic !important; */
}

/* global buttons */
.button-primary {
  background: transparent linear-gradient(265deg, #79defc 0%, #2759a2 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014 !important;
  border-radius: 21px !important;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: initial !important;
  min-width: 50px !important;
}

.button-secondary {
  background: transparent !important;
  box-shadow: 0px 3px 6px #00000014 !important;
  border-radius: 21px !important;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #2759a2 !important;
  text-transform: initial !important;
  border: 1px solid #2759a2;
  font-weight: 400 !important;
}

.button-secondary-delete {
  background: transparent !important;
  box-shadow: 0px 3px 6px #00000014 !important;
  border-radius: 21px !important;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #a31e1e !important;
  text-transform: initial !important;
  border: 1px solid #a31e1e;
  font-weight: 400 !important;
}

button:disabled {
  opacity: 0.7;
  cursor: no-drop !important;
  pointer-events: all !important;
}

/* texts links */
.txt-link {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0049b7;
  opacity: 1;
}

.txt-link:hover {
  text-decoration: underline;
  cursor: pointer;
}


/*#region scroll components */

/*#region scroll table */
.v-data-table__wrapper {
  overflow: auto !important;
}

.v-data-table__wrapper::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

.v-data-table__wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6e6e6 !important;
  border-radius: 10px !important;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #a1acc3 !important;

  border-radius: 10px !important;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: #a1acc3 !important;
}

/*#endregion scroll table */

/*#region scroll content right */
.content-right {
  overflow: auto !important;
}

.content-right::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6e6e6 !important;
  border-radius: 10px !important;
}

.content-right::-webkit-scrollbar-thumb {
  background: #a1acc3 !important;

  border-radius: 10px !important;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: #a1acc3 !important;
}

/*#endregion scroll content right */

/*#endregion scroll components */

/*#region table global */
.v-data-table-header tr th {
  color: #000000 !important;
}

.global-col-header-accion {
  background-color: white !important;
  position: sticky !important;
  right: 0;
  top: auto !important;
  z-index: 3 !important;
  border-top-right-radius: 15px;
}

.global-col-body-accion {
  background-color: white !important;
  position: sticky !important;
  right: 0;
  top: auto !important;
  z-index: 3 !important;
  /* border-top-right-radius: 15px; */
}

/*#endregion table global */
/* global pagination  */

/* global pagination  */
.content-pagination {
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.v-data-table,
.v-data-table__wrapper,
.v-data-table>.v-data-table__wrapper>table {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.v-data-table,
.v-data-table__wrapper,
.v-data-table>.v-data-table__wrapper>table {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.txt-rows-table,
.txt-pages-table {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a3a3a3;
  opacity: 1;
}

.table-header-text {
  font-weight: bold;
  color: #000000 !important;
  font-size: 14px;
}

.txt-number-pages-table .v-list-item__content {
  font-size: 14px;
  color: #a3a3a3;
  letter-spacing: 0px;
}

.txt-current-page-table {
  font-size: 14px;
  color: #a3a3a3;
  letter-spacing: 0px;
}

/* global styles dialogs */
.content-card-dialog {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 20px 40px #3a79bd19;
  padding: 20px;
  position: relative;
}

.content-card-txt-title {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.content-card-button-close {
  border: 2px solid #000 !important;
  border-radius: 5px !important;
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.content-card-txt-description {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-card-responsive {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 20px 40px #3a79bd19;
  padding: 15px;
}

/* emtpy content styles  */
.empty-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-content p {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

/* global colors */
.txt-green {
  color: #1ea31e;
  /* color: #03c9a9; */
}

.txt-red {
  color: #a31e1e;
  /* color: #ec644b; */
}

/* Pagination Styles */
.pagination-styles .theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff !important;
  font-size: 12px !important;
}

.pagination-styles .v-pagination__item,
.pagination-styles .v-pagination__navigation {
  height: 30px;
  width: 30px;
  font-size: 14px !important;
}

.pagination-styles {
  width: 310px;
}

/* input currency styles  */
.input-currency {
  background: transparent;
  border-radius: 0;
  font-size: 12px;
  width: 100%;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  letter-spacing: 0px;
  text-align: right;
}

.input-currency:focus-visible {
  outline: none;
}

/* texts dropdown menu  */
.txt-title-down-menu {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.txt-description-down-menu {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-down-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #3a79bd19;
  border-radius: 14px;
  padding: 10px;
}

.content-down-menu .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0;
}

.content-menu-options .v-list-item__content {
  font-size: 14px;
}

.content-menu-options .v-list-item__icon {
  margin-right: 0px !important;
}

.content-menu-options-dots-global {
  position: absolute;
  top: 10px;
  right: 10px;
}

.position-relative {
  position: relative;
}

.content-title-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: fit-content;
  padding: 0px 10px;
}

.content-overlay-global .v-overlay__content {
  display: inline-grid;
  place-items: center;
}

@media (max-width: 500px) {

  .txt-rows-table,
  .txt-pages-table,
  .txt-number-pages-table .v-list-item__content,
  .txt-current-page-table {
    font-size: 12px;
  }

  .content-pagination {
    padding: 15px 0px;
  }

  .content-card-txt-title {
    font-size: 20px;
  }
}

.mgl-map-wrapper {
  height: 500px !important;
  position: relative;
  width: 100%;
}

.content-detail-client {
  position: relative !important;
}

.content-bottom-action {
  position: fixed !important;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: #f2f6fe !important;
  display: flex;
}

.content-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 0px 15px;
  width: fit-content;
}

.txt-label-global {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #2759a2;
  margin-bottom: 0px !important;
}

.txt-value-global {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #3c3c3c;
  margin-bottom: 0px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.txt-value-description-global {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #3c3c3c;
  margin-bottom: 0px !important;
  overflow-wrap: break-word;
}

.txt-empty-global {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #777777;
  margin-bottom: 0px !important;
  font-style: italic;
}
.txt-empty-global-table {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #777777;
  margin-bottom: 0px !important;
  font-style: italic;
}

.v-data-table__empty-wrapper {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #777777 !important;
  margin-bottom: 0px !important;
  font-style: italic;
}

.v-application--wrap {
  min-height: auto !important;
}

/*#region GLOBAL CONTENT MENU GLOBAL */
.icons-menu-global {
  color: white !important;
}

/*#endregion GLOBAL CONTENT MENU GLOBAL */

/*#region GLOBAL TABLE AUDIT */
.thIcon {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
  transition: .5s !important;

}

.table-audit {
  transition: .5s !important;
}

.table-audit .v-data-table__wrapper table tbody tr td {
  min-height: 10px !important;
  max-height: 10px !important;
  overflow: hidden;
  -webkit-transition: height 5s ease;
  -moz-transition: height 5s ease;
  -o-transition: height 5s ease;
  transition: height 5s ease;

}

.table-audit .v-data-table__wrapper table tbody tr:hover td {
  height: auto !important;
  min-height: auto !important;
  max-height: auto !important;
  white-space: inherit !important;

}


.table-audit .v-data-table__wrapper table tbody tr:hover .thIcon {
  transform: rotate(-0.50turn) !important;
  transition: .5s !important;

}

.global-body-action-register-audit {
  width: auto;
  max-width: 300px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

/*#endregion GLOBAL TABLE AUDIT */

/*#region COMPONENT DATEPICKERTIME GLOBAL */
.mx-input {
  color: #000000;
  border: 1px solid #9ba0aa !important;
  height: 39px !important;
  border-radius: 8px !important;

}

.mx-input:hover,
.mx-input:focus {
  border-color: #000000 !important;
}

/*#endregion COMPONENT DATEPICKERTIME GLOBAL */
</style>